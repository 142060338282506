$checkbox--size: 18px;
$checkbox--color: $cobalt;
$checkbox--color-hover: $navy-blue;
$checkbox--color-checked: $cobalt;
$checkbox--color-partly: gray;
$checkbox--bg-color: white;

.b-checkbox {
	width: $checkbox--size;
	height: $checkbox--size;

	&__input {
		position: absolute;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);
	}

	&__label {
		position: relative;
		display: block;
		width: $checkbox--size;
		height: $checkbox--size;
		background-color: transparent;
		border: 2px solid $checkbox--color;
		transition: border-color 0.2s ease;
		@include webfont-icon($webfont-icon--checkmark);
		overflow: hidden;
		cursor: pointer;
		border-radius: 2px;

		&:before {
			display: none;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: color 0.2s ease;
			font-size: 11px;
		}

		&:hover,
		&:focus {
			border-color: $checkbox--color-hover;
		}
	}

	&__input:checked + &__label,
	&__input.is-checked + &__label {
		position: relative;
		border-color: $checkbox--color-checked;
		background-color: $checkbox--color-checked;

		&:before {
			display: block;
			color: $color-white;
		}

		&:hover,
		&:focus {
			border-color: $checkbox--color-checked;
		}
	}

	// состояние для состояния неполного выделения,
	// т.к. когда чекбокс родительский для группы и не все элементы из группы отмечены
	&.is-partly-selected &__label {
		border-color: $checkbox--color-partly;

		&:before {
			display: block;
			color: $checkbox--color-partly;
		}

		&:hover,
		&:focus {
			border-color: $checkbox--color-hover;
		}
	}
}

.feature-no-checked .b-checkbox {
	.b-checkbox__label {
		display: none;
	}

	.b-checkbox__input {
		display: inline;
	}
}
