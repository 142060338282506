.inset {
	position: relative;
	color: $quote-text;
	font-size: 14px;
	font-weight: 400;
	// border-left: 2px solid currentColor;
	@include rem(padding-left, 15px);

	&.color-green {
		color: $mantis;

		p {
			color: $mantis;
		}
	}

	&.color-yellow {
		color: $my-sin;

		p {
			color: $my-sin;
		}
	}

	&.color-blue {
		color: $cornflower;

		p {
			color: $cornflower;
		}
	}

	&.color-green,
	&.color-yellow,
	&.color-blue {
		b {
			font-size: 30px;
		}
	}

	&--blue {
		background-color: $hawkes-blue;

	}

	&--orange {
		background-color: #FFF0DB;

		.blue-block {
			background-color: #FFF0DB;
		}
	}

	&--green {
		background-color: #E8F2E0;

		.blue-block {
			background-color: #E8F2E0;
		}
	}

	&--border {

	}
}

.inset--in-text {
	padding-left: 0;
	font-style: italic;
	font-size: 100%;

	figcaption {
		@include font-size(18px);
	}
}

.inset--with-title {
	@include rem(padding, 25px 25px 25px);
	color: $color-davys-grey;
	position: relative;

	&.color-green {
		&::after {
			border-color: $mantis;
		}
	}

	&.color-yellow {
		&::after {
			border-color: $my-sin;
		}
	}

	&::after {
		content: '';
		position: absolute;
		top: 25px;
		left: -30px;
		border: 10px solid $navy-blue;
		width: calc(100% + 30px);
		height: calc(100% - 25px);
	}

	figcaption {
		font-size: 18px;
		@include font(700, 'Roboto');
		color: $navy-blue;
		position: absolute;
		top: 0;
		@include rem(left, 15px);
	}

	p {
		color: $navy-blue;
		padding-top: 40px;

		& + p {
			padding-top: 0;
		}
	}

	& > *:last-child {
		margin-bottom: 0;
	}

	.inset-icon-block {
		padding-top: 30px;
		margin-bottom: 20px;

		font-style: italic;
		color: $storm-dust;

		[data-svg] {
			margin-right: 20px;
		}

		[data-svg],
		span {
			display: inline-block;
			vertical-align: middle;
		}

		& + p {
			padding-top: 0;
		}
	}
}

.inset-img {
	position: relative;

	&__photo {
		width: 100%;
		display: block !important;
		object-fit: cover;
	}

	&__content {
		position: absolute;
		background-color: $color-white;
		padding: 16px 40px 20px 30px;
		top: 14%;
		right: 0;
		// max-width: 40%;
		// width: 100%;
	}

	&__title {
		line-height: normal;
		@include font-size(30px);
	}

	&__subtitle {
		color: $navy-blue;
		margin-bottom: 0;
		@include font-size(16px);
		font-weight: normal;
	}

	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 15px;
		background-color: $my-sin;
	}

	@include breakpoint(xs) {
		&__content {
			position: static;
		}
	}
}

.inset-box {
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 15px;
		background-color: $my-sin;
	}

	&__content {
		background-color: $navy-blue;
		padding: 12px 20px;
		color: $color-white;

		p {
			color: $color-white;
			font-weight: 400;
			margin-bottom: 0;
		}

		width: 65%;
	}

	&__subtitle {
		color: $navy-blue;
	}

	@include breakpoint(xs) {
		&__content {
			width: 100%;
		}
	}
}
