$header-bg: $color-white;
$body-bg: $alice-blue;
$footer-bg: $alice-blue;

body {
	background-color: $body-bg;
	overflow-x: hidden;
	@include font(400, 'Roboto');
	color: $color-black;
}

.l-layout-wrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	min-height: 100vh;
	width: $site-width;
	margin: 0 auto;
	background-color: $body-bg;
	overflow-x: hidden;


	&__header {
		flex: none;
		padding: 0;
		max-width: 400px;
		width: 21%;
		background: linear-gradient(204.29deg, #133A60 15.35%, #2891BC 100%);
		box-shadow: 0px 5.5px 5px rgba(0, 0, 0, 0.24), 0px 9px 18px rgba(0, 0, 0, 0.18);
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 2;

		&:before {
			// content: '';
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: $header-bg;
			z-index: -1;
		}
	}

	&__content {
		width: 79%;
		margin-left: auto;
		position: relative;
		// scroll off
		// height: 100%;
		.browser-ie & {
			min-height: 100vh;
		}
	}

	&__footer {
		background-color: $footer-bg;
		flex: none;
	}
}

.l-buttons-area__item {
	display: flex;
	align-items: center;
}

.l-page {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 0;
	min-height: 1px;

	&__sidebar {
		flex-grow: 0;
		flex-shrink: 0;
	}

	&__sidebar--left {
		flex-basis: $left-col-width;
		width: $left-col-width;
		min-width: $left-col-width;
	}

	&__sidebar--right {
		flex-basis: $right-col-width;
		width: $right-col-width;
		min-width: $right-col-width;
	}

	&__sidebar-section {
		@include rem(margin-bottom, 10px);
	}

	&__sidebar-section--adaptive-menu {
		display: none;
	}

	&__content {
		padding-bottom: 3em;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		flex-shrink: 1;
		flex-grow: 1;
		position: relative;
		width: 100%;
	}

	&__content--with-left {
		padding-left: 0;
		// padding-right: 64px;
		max-width: 1040px;//$left-col-width + $center-col-width;
		width: 100%;
	}

	&__content--with-right {
		padding-right: 0;
		width: $right-col-width + $center-col-width;
	}

	&__content--wide {
		padding-right: 0;
		padding-left: 0;
		width: 100%;
	}

	&__nav {
		display: flex;
		align-items: center;
		@include rem(margin-bottom, 37px);
	}

	&__nav-breadcrumbs {
		flex-grow: 1;
	}

	&__nav-controls {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

// .l-layout-wrapper__header,
.l-layout-wrapper__footer {
	padding-left: $grid-gutter-width;
	padding-right: $grid-gutter-width;
}

.l-layout-wrapper__page {
	padding-right: 72px;
}

.content-area {
	min-height: 1px;
}

.l-content--with-left {
	margin-left: -($left-col-width + 15px);
}

.l-content--with-right {
	margin-right: -($right-col-width + 16px);
	// rcw+64
}

.aside {
	position: absolute;
	margin-top: 20px;
}

.aside--right {
	width: $right-col-width;
	// right: -400px;//-$right-col-width;
	right: -344px;
	padding-left: 0;//$grid-gutter-width;
}

.aside--right-bottom {
	margin-top: -30px;
}

.aside--left {
	width: $left-col-width;
	left: -$left-col-width;
	padding-right: $grid-gutter-width;
}

@if ($responsive) {
	.l-layout-wrapper {
		width: 100%;
		max-width: $site-width;
		min-width: $site-min-width;
	}

	@media (min-width: 1400px) {
		.l-page__content--with-left {
			padding-right: 64px;
		}

	}

	// @include breakpoint(md) {
	@media (max-width: 1399px) {
		.l-layout-wrapper__header {
			// max-width: 79px;
			// width: 8%;
			transition: max-width .3s ease, width .3s ease, transform .3s ease;
			left: 0;
			// transform: none;
			// max-width: 300px;
			// width: 30%;
			width: 300px;
			transform: translateX(calc(-100% + 24%));
			// 	padding-left: $grid-gutter-width;
			// 	padding-right: $grid-gutter-width;

			.browser-ie & {
				transform: translateX(-100%);
				left: 70px;
			}

			.os-scrollbar {
				opacity: 0;
			}

			&.is-open {
				// max-width: 300px;
				// width: 30%;
				transform: translateX(0);
				// ie
				left: 0;

				.l-header-top-nav__menu {
					opacity: 1;
					transform: translate(0);
					pointer-events: auto;
				}

				.l-header-top-nav__burger {
					opacity: 0;
				}

				.b-logo {
					width: 138px;
				}

				.os-scrollbar {
					opacity: 1;
				}
			}
		}

		.l-header-top-nav__logo {
			height: 57px;
			transition: height .3s ease;

			.is-open & {
				height: 110px;
			}
		}

		.l-layout-wrapper__content {
			width: calc(94% + 12px);
			position: relative;
			z-index: 1;
		}

		.l-layout-wrapper__page {
			padding-right: 72px;
			padding-left: 0;
			// padding-right: $grid-gutter-width;
		}

		.l-layout-wrapper__footer {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		// .l-page__content {
		// 	width: auto;
		// 	//overflow: hidden;
		// }

		.aside--right {
			right: calc((#{$right-col-width} + 16px) * -1);
		}

		.aside--right-bottom {
			margin-top: 0;
		}

	}

	@include breakpoint(md) {
		.l-layout-wrapper__content {
			width: calc(92% + 12px);
			position: relative;
			z-index: 1;
		}

		.l-page__content {
			// width: auto;
			//overflow: hidden;
		}
	}

	@include breakpoint(sm) {
		.l-page {
			height: 100%;
			&__content {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.l-header-top-nav__logo {
			height: auto;
		}

		.l-page__sidebar-section--menu {
			display: none;
		}

		.l-page__sidebar-section--adaptive-menu {
			display: block;
		}

		.aside {
			position: static;
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}

		.l-layout-wrapper__content {
			width: 100%;
		}
	}
}


@media (min-width: 1921px) {
	.l-layout-wrapper__header {
		left: 50%;
		transform: translateX(-240%);
	}
}
