$tooltips--bg-color: $color-white;
$tooltips--color: $navy-blue;


.b-tooltip {
	display: inline;
	transition: border-bottom 0.2s ease, color 0.2s ease;
	position: relative;
	padding-right: 14px;
	margin-right: 5px;

	span {
		border-bottom-width: 2px;
		border-bottom-style: dashed;
	}

	&::after {
		content: '';
		width: 17px;
		height: 17px;
		@include svg(100%, 100%, "/images/icons/tooltip.svg");
		display: inline-block;
		position: absolute;
		top: 2px;
		right: -3px;
	}

	&:hover,
	&.is-hover {
		// color: $link-hover-color;
		// border-bottom-color: transparent;
		cursor: help;
		&::after {
			@include svg(100%, 100%, "/images/icons/tooltip-hover.svg");
		}

		span {
			border-bottom-color: $navy-blue;
		}
	}

	.b-tooltip__text {
		display: none;
	}
}

.b-tooltip--long {
	padding-right: 0;
	margin-right: 0;

	&:after {
		position: static;
		margin-bottom: -4px;
	}
}

.b-tooltips-footnotes,
.b-footnotes,
.b-tooltip-number {
	display: none;
}

.tooltipster-sidetip.tooltipster-tooltips {
	.tooltipster-box {
		border: none;
		background: $tooltips--bg-color;
		color: $tooltips--color;
		@include font-size(16px);
		@include font(400);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12);
		border-radius: 2px;
		a {
			font-size: 16px;
			color: $tooltips--color;
		}
	}

	.tooltipster-content {
		color: $tooltips--color;
		padding: 15px 20px;
		line-height: 22px;
	}

	.tooltipster-arrow {
		height: 18px;
		margin-left: -11px;
		width: 22px;
		top: -10px;
	}

	&.tooltipster-left .tooltipster-arrow,
	&.tooltipster-right .tooltipster-arrow {
		height: 22px;
		margin-left: 0;
		margin-top: -11px;
		width: 11px;
	}

	.tooltipster-arrow-background {
		border: 11px solid transparent;
	}

	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: $tooltips--bg-color;
		top: -4px;
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: $tooltips--bg-color;
		left: -4px;
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: $tooltips--bg-color;
		left: 4px;
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: $tooltips--bg-color;
		top: -4px;
	}

	.tooltipster-arrow-border {
		border: none;
	}

	&.tooltipster-bottom .tooltipster-arrow-uncropped {
		top: 0;
	}

	&.tooltipster-right .tooltipster-arrow-uncropped {
		left: -11px;
	}
}
