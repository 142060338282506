.b-sitemap {
	padding-left: 0;
	max-width: 1015px;
	margin-left: 0;

	&__list,
	&__item,
	&__link {
		margin-top: 0;
	}

	li.b-sitemap__item {
		padding: 0;

		&::before {
			display: none;
		}
	}

	&__item {
		margin-bottom: 20px;

		&--lvl1 {

		}
	}

	ul.b-sitemap__list--lvl2 {
		margin: 20px 0 5px;
		padding-left: 27px;
		max-width: 310px;
	}

	ul.b-sitemap__list--lvl3 {
		margin-left: 16px;
		padding-left: 15px;
		max-width: 215px;
		margin-top: 8px;

		.b-sitemap__item {
			margin-bottom: 9px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__list {
		&--lvl3 {
			border-left: 2px solid $sitemap-border;
		}
	}

	&__link {
		display: inline;
		color: $sitemap-link;
		text-decoration: none;
		margin-bottom: 10px;
		@include font(300,'Roboto');

		// &:visited
		&:hover {
			color: $sitemap-link-hover;
			text-decoration: underline;
		}

		&:active {
			color: $sitemap-link-active;
			text-decoration: underline;
		}
	}

	&__link--lvl1 {
		font-size: 18px;
		@include font(700,'Roboto');
	}

	&__link--lvl2 {
		font-size: 18px;
	}

	&__link--lvl3 {
		font-size: 14px;
		line-height: 18px;
	}

	&__link--lvl4 {
		@include font-size(12px);
	}

	&__list--lvl1 {
		& > .b-sitemap__item {
			@include rem(margin-bottom, 25px);
		}
	}

	&__list--lvl2 {

	}

	&__list--lvl3 {

	}

	&__list--lvl4 {

	}
}
