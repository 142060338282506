$download-center--row-border-bottom-color: #fff;
$download-center--bg-accordion-article: #cdcdcd;
$download-center--download-btn-bg: #656565;
$download-center--download-btn-disabled-bg: #738d97;
$download-center--download-btn-color: #fff;
$download-center--section-title-border-color: #7d7d7d;
$download-center--section-title-color: #343434;
$download-center--section-col-border-color: #7d7d7d;

.b-download-center {
	width: 100%;
	max-width: 972px;
	
	&__head {}
	
	&__body {
		margin-bottom: 10px;
	}

	&__btn {
		background-color: $navy-blue;
		border-radius: 2px;
		letter-spacing: 0.0357143em;
		padding: 13px 12px;

		&.is-disabled {
			background: $jordy-blue;
			opacity: 1;

			&:hover,
			&:active,
			&:visited {
				background: $jordy-blue;
			}
		}
	}
	
	&__btn + &__btn {
		margin-left: 6px;
	}
	
	.b-download-center-section {
		& + .b-download-center-section .b-download-center-section__title {
			border-top: 1px dotted $download-center--section-title-border-color;
		}
		
		&:first-child .b-download-center-section__title {
			border-top: 0;
		}
	}
}

.b-download-center-section {
	margin-top: 0;
	
	&__title {
		@include font-size(18px);
		border-bottom: 1px dotted $download-center--section-title-border-color;
		@include rem(padding, 18px 15px);
		color: $download-center--section-title-color;
	}
	
	&__cols {
		@include rem(padding, 0 15px);
	}
	
	&__col {
		@include rem(padding, 18px 15px);
		border-right: 1px dotted $download-center--section-col-border-color;
	}
	
	
	&__col:last-child {
		border-right: 0;
	}
	
	&__col-title {
		@include font-size(1rem);
		@include rem(margin-bottom, 15px);
	}
}

.b-download-center-line--accordion:first-child {
	border-top: 1px solid $cobalt;
}

.b-download-center-line {
	line-height: 1.3;
	margin-top: 0;
	padding: 7px 9px;
	@include font-size(18px);
	border-bottom: 1px solid $download-center-border-bottom;
	display: flex;
	align-items: center;
	// justify-content: space-between;

	&--accordion {
		border: 0;
		padding: 0;
	}

	&--external {
		margin-bottom: 28px;
		padding-top: 0;
		padding-bottom: 10px;

		a {
			margin-left: 31px;
			&::before {
				display: none;
			}
		}
	}

	&__action {
		
	}
	
	&__content {
		a {
			font-size: 18px;
			line-height: 25px;
			text-decoration: underline;

			.b-download-center-line--sub & {
				@include font(300, 'Roboto');
			}

			&:visited {
				color: #00589C;
				&:hover {
					color: #0080C8;
				}

				&:active {
					color: #7AB9E5;
				}
			}
		}
	}
	
	&__action + &__content {
		padding-left: 12px;
	}
	
	&__content + &__action {
		padding-left: 12px;
	}
	
	&__meta {
		@include font(300, 'Roboto');
		font-size: 16px;
		line-height: 23px;
		color: $download-center-text;
	}
	
	&--sub {
		@include font-size(1rem);
		@include rem(padding, 5px 0);
		background: transparent;
		border-bottom: none;

		.b-accordion__article--download & {
			margin-bottom: 5px;
		}
	}

	&--last {
		justify-content: flex-end;
	}
	
	&--accordion {
		margin: -1px 0 0;
		
		& > .b-download-center-line__action {
			width: 100%;
		}
		
		.b-accordion {
			text-align: left;
		}
		
		
		.b-accordion__button {
			position: relative;
			border: 0;
		}
		
		.b-accordion__button.is-expand,
		.b-accordion__button:active {
			// @include rem(padding-left, 50px);
		}
		
		
		.b-accordion__button:before,
		.b-accordion__button:after {
			display: none;
		}

		.b-accordion__section {
			margin: 0;
		}
		
		.b-accordion__header {
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			
			.b-checkbox {
				left: 8px;
    			margin-top: -11px;
				position: absolute;
				top: 50%;
				z-index: 1;
			}
		}
		
		.b-accordion__article {
			padding: 24px 39px;
			border-bottom: 1px solid #00589C;
		}
		
		.b-accordion__article--sections {
			padding: 0;
		}
		
		.article-close {
			display: none;
		}
	}
}


@if ($responsive) {
	@include breakpoint(xs) {
		.b-download-center {
			&__footer {
				padding: 0;
			}
		}
		
		.b-download-center-line {
			@include font-size(14px);

			&--external {
				a {
					margin-left: 0;
				}
			}
			
			&__content {
				@include rem(padding-right, 10px);

				a {
					font-size: 14px;
				}
			}
		}
		
		.b-download-center-line--accordion {
			.b-accordion__button {
				@include font-size(14px);
			}
		}
		
		.b-download-center-section {
			&__col {
				border-right: none;
			}
			
			&__col-title {
				@include rem(margin-bottom, 10px);
			}
			
			&__title {
				@include font-size(14px);
			}
		}
	}
}


.b-download-center-line {
	& + &--accordion {
		margin-top: 0;
		transform: translateY(0px);
	}

	&--accordion + &--accordion {
		// margin-top: -1px;
		transform: translateY(0);
	}
}