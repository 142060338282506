.float-content--right {
	float: right;
	margin-left: 50px;
	width: 280px;

	@include breakpoint(sm) {
		width: 100%;
		margin: 0;
		padding-left: 25px;
	}
}

[lang="ru"] {
	.body--analysis .title--page {
		max-width: 190px;
	}
}
