$bod_bod-gallery--name-color: $color-navy-blue;
$bod_bod-gallery--bod-name-color-hover: $color-white;
$bod_bod-gallery--border-color: $color-prussian-blue;
$bod_bod-gallery--close-icon-color: $color-ironside-gray;
$bod_bod-gallery--close-icon-color-hover: $color-taupe-gray;
$bod_bod-gallery--pagination-button-color: $color-ironside-gray;
$bod_bod-gallery--pagination-button-color-hover: $color-taupe-gray;
$bod_bod-gallery--pagination-button-color-active: $color-gray;

@if ($contrast) {
	$bod_bod-gallery--name-color: $color-white;
	$bod_bod-gallery--bod-name-color-hover: $color-white;
	$bod_bod-gallery--border-color: $color-white;
	$bod_bod-gallery--close-icon-color: $color-white;
	$bod_bod-gallery--close-icon-color-hover: $color-dark-jungle;
	$bod_bod-gallery--pagination-button-color: $color-white;
	$bod_bod-gallery--pagination-button-color-hover: $color-mountain-mist;
	$bod_bod-gallery--pagination-button-color-active: $color-gray;
}

.b-bod--gallery {
	%bod__name {
		@include font-size(17px);
		color: $bod_bod-gallery--name-color;
	}

	%bod__post {
		@include font-size(14px);
		text-transform: none;
		margin: 0;
	}

	.b-bod-main {
		@include rem(padding-top, 20px);
		@include rem(min-width, 320px);

		&__inner {
			padding-top: $grid-gutter-width;
			padding-bottom: $grid-gutter-width;
		}

		&__item {
			width: 100%;
			overflow: hidden;
			position: relative;
			text-align: center;

			&:hover {
				cursor: pointer;

				.b-bod-main__sign {
					color: $bod_bod-gallery--bod-name-color-hover;
					background-color: #1D496F;
					bottom: 5px;
					padding: 10px 0;
					padding-bottom: 3px
				}
			}
		}

		&__photo {
			width: 100%;
			// @include rem(width, 152px);
			// @include rem(height, 152px);
			// max-height: 128px;
			// max-width: 128px;
			margin: auto;

			img {
				width: 100%;
			}
		}

		&__sign {
			width: 100%;
			bottom: -100%;
			transition: bottom 0.5s ease;
			position: absolute;

			.b-bod-main__name {
				color: $color-white;
			}
		}

		&__name {
			@extend %bod__name;
			@include font-size(14px);
			line-height: 1.1;
			font-weight: 500;
			margin: 0;
		}

		&__post {
			@extend %bod__post;
		}

		&__text {
			display: none;
		}
	}

	.b-bod-expandable-content {
		@include rem(min-width, 320px);

		&__photo {
			width: 100%;
		}

		&__inner {
			position: relative;
			display: none;
			@include rem(border-width, 1px);
			border-style: solid;
			border-color: $bod_bod-gallery--border-color;
			@include rem(padding, 20px);
		}

		&__close-button {
			cursor: pointer;
			position: absolute;
			@include rem(right, 5px);
			@include rem(top, 5px);
			background-color: transparent;
			@include webfont-icon($webfont-icon--close_2);
			color: $bod_bod-gallery--close-icon-color;
			@include font-size(30px);

			&:hover,
			&:focus {
				color: $bod_bod-gallery--close-icon-color-hover;
			}
		}

		&__head {
			margin-top: 0;
		}

		&__name {
			@extend %bod__name;
			@include font-size(28px);
		}

		&__post {
			@include font-size(18px);
			text-transform: none;
			margin-top: 0;
		}

		&__pagination-btn {
			position: absolute;
			right: 0;
			bottom: 0;
			cursor: pointer;
			background-color: transparent;

			&:hover,
			&:focus {
				background-color: transparent;
				color: $bod_bod-gallery--pagination-button-color-hover;
			}

			&:active {
				background-color: transparent;
				color: $bod_bod-gallery--pagination-button-color-active;
			}

			&:before {
				@include font-size(18px);
			}
		}

		&__pagination-btn[disabled] {
			background-color: transparent;
			cursor: default;

			&:hover,
			&:active,
			&:focus {
				background-color: transparent;
			}
		}

		&__pagination-btn--prev {
			@include rem(right, 40px);
			@include webfont-icon($webfont-icon--arrow-left);
		}

		&__pagination-btn--next {
			@include rem(right, 5px);
			@include webfont-icon($webfont-icon--arrow-right);
		}
	}
}

.b-bod {
	.education-font {
		@include font-size(18px);
		margin-bottom: 10px;
		color: #8ABEE8;
	}

	&__post {
		margin-top: 10px;
	}

	&__biography,
	&__education {
		margin-top: 15px;
		strong {
			color: #8ABEE8;
		}

	}

	&__education,
	&__experience {
		strong + p {
			margin-top: 20px;
		}
	}
	// &__education {
	// 	margin-top: 15px;
	// 	strong {
	// 		color: #8ABEE8;
	// 	}
	// }
}

.b-bod-main__post  {
	color: $color-white;
}