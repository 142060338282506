$buttons-panel--trigger-icon-size: 22px;
$buttons-panel--trigger-text-font-size: 15px;
$buttons-panel--trigger-color: $color-ironside-gray;
$buttons-panel--trigger-color-hover: $color-black;
$buttons-panel--trigger-color-active: orange;

$buttons-panel--list-bg-color: $color-ironside-gray;

$buttons-panel--link-icon-font-size: 18px;
$buttons-panel--link-font-size: 13px;
$buttons-panel--link-color: $base-text-color;
$buttons-panel--link-color-hover: $color-white;
$buttons-panel--link-color-active: orange;
$buttons-panel--link-bg-color: $color-eallery;
$buttons-panel--link-bg-color-hover: #444444;
$buttons-panel--link-bg-color-active: #444444;

$buttons-panel--counter-width: 12px;
$buttons-panel--counter-height: 12px;
$buttons-panel--counter-color: $buttons-panel--link-color;
$buttons-panel--counter-color-hover: $buttons-panel--link-color-hover;
$buttons-panel--counter-color-active: $buttons-panel--link-color-active;
$buttons-panel--counter-bg-color: $buttons-panel--link-bg-color;
$buttons-panel--counter-bg-color-hover: $buttons-panel--link-bg-color-hover;
$buttons-panel--counter-bg-color-active: $buttons-panel--link-bg-color-active;
$buttons-panel--counter-border-color: $buttons-panel--counter-color;
$buttons-panel--counter-border-color-hover: $buttons-panel--counter-color-hover;
$buttons-panel--counter-border-color-active: $buttons-panel--counter-color-active;

$buttons-panel--sublist-bg-color: $color-ironside-gray;
$buttons-panel--sublist-icon-font-size: 13px;
$buttons-panel--sublist-link-width: 30px;
$buttons-panel--sublist-link-height: 30px;
$buttons-panel--sublist-link-icon-color: $color-white;
$buttons-panel--sublist-link-icon-color-hover: $color-gray;
$buttons-panel--sublist-link-icon-color-active: orange;
$buttons-panel--sublist-icon-bg-color: transparent;
$buttons-panel--sublist-icon-bg-color-hover: transparent;
$buttons-panel--sublist-icon-bg-color-active: transparent;

$buttons-panel--compare-link-font-size: 14px;

$buttons-panel--tooltips-color: $color-white;
$buttons-panel--tooltips-bg-color: $color-ironside-gray;

$buttons-panel--title-font-size: 17px;
$buttons-panel--title-color: $base-text-color;

%counter {
	position: absolute;
	display: block;
	@include font-size(16px);
	@include font(500);
	text-align: center;
	@include rem(width, $buttons-panel--counter-width);
	@include rem(height, $buttons-panel--counter-height);
	color: $tools-text;
	top: 27px;
	// left: 50%;
	right: 50%;
    margin-right: -12px;
	@extend .user-select--no;
}

.b-buttons-panel__link {
	position: relative;
	display: block;
	text-decoration: none;
	line-height: 1.2;
	background-color: $buttons-panel--link-bg-color;
	@include font-size($buttons-panel--link-font-size);
	color: $buttons-panel--link-color;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include font-size($buttons-panel--link-icon-font-size);
	}

	&:visited {
		color: $buttons-panel--link-color;
	}

	&:hover,
	&:focus,
	&:visited:hover,
	&:visited:focus {
		background-color: $buttons-panel--link-bg-color-hover;
		color: $buttons-panel--link-color-hover;
	}

	&:active,
	&.is-expand,
	&:visited:active {
		background-color: $buttons-panel--link-bg-color-active;
		color: $buttons-panel--link-color-active;
	}

	&.is-visible {
		display: block;
	}

	//Мой отчет
	&--myreport {
		&::before {
			width: 41px;
			@include svg(100%, 100%, "/images/icons/add.svg");
		}

		.b-buttons-panel__counter {
			@extend %counter;
			height: auto !important;
			margin-right: -12px;
			width: 12px !important;

			@include breakpoint(sm) {
				margin-right: -13px;
			}
		}

		&:hover,
		&:focus {
			.b-buttons-panel__counter {
				color: $tools-text-hover;
			}

			&::before {
				@include svg(100%, 100%, "/images/icons/add-hover.svg");
			}
		}

		&:active {
			.b-buttons-panel__counter {
				color: $tools-text-active;
			}

			&::before {
				@include svg(100%, 100%, "/images/icons/add-active.svg");
			}
		}
	}

	&--add,
	&--del {
		display: none;

		&:after {
			@extend %counter;
		}

		&:hover,
		&:focus {
			&:after {
				color: $tools-text-hover;
			}

			&::before {
				@include svg(100%, 100%, "/images/icons/add-hover.svg");
			}
		}

		&:active {
			&:after {
				color: $tools-text-active;
			}

			&::before {
				@include svg(100%, 100%, "/images/icons/add-active.svg");
			}
		}
	}

	//Добавить в мой отчет
	&--add {
		&::before {
			width: 41px;
			@include svg(100%, 100%, "/images/icons/add.svg");
		}

		&:after {
			content: '+';
		}
	}

	//Удалить из моего отчета
	&--del {
		&::before {
			width: 41px;
			@include svg(100%, 100%, "/images/icons/add.svg");
		}

		&:after {
			content: '-';
		}
	}

	//Печать страницы
	&--print {
		&::before {
			width: 41px;
			@include svg(100%, 100%, "/images/icons/print.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/print-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/print-active.svg");
			}
		}
	}

	//Скачать PDF
	&--pdf {
		&::before {
			width: 34px;
			@include svg(100%, 100%, "/images/icons/download-report.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/download-report-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/download-report-active.svg");
			}
		}
	}

	//Поделится в соц. сетях или по e-mail
	&--share {
		&::before {
			width: 48px;
			@include svg(100%, 100%, "/images/icons/share.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/share-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/share-active.svg");
			}
		}
	}

	//История просмотренных страниц
	&--history {
		&::before {
			width: 30px;
			// width: 48px;
			@include svg(100%, 100%, "/images/icons/history.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/history-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/history-active.svg");
			}
		}

		.browser-ie & {
			&::before {
				height: 44px !important;
				margin-top: 4px;
			}
		}
	}

	//Обратня связь
	&--feedback {
		&::before {
			width: 46px;
			@include svg(100%, 100%, "/images/icons/feedback.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/feedback-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/feedback-active.svg");
			}
		}
	}

	//Центр загрузки
	&--download {
		&::before {
			width: 46px;
			@include svg(100%, 100%, "/images/icons/download-center.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/download-center-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/download-center-active.svg");
			}
		}
	}

	//Интерактивный анализ
	&--analysis {
		&::before {
			width: 31px;
			@include svg(100%, 100%, "/images/icons/analysis.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/analysis-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/analysis-active.svg");
			}
		}
	}

	//Сравнить с прошлым отчетом
	//Смотреть предыдущие отчеты
	//предыдущие отчеты
	&--previous-reports,
	&--compare,
	&--see {
		&::before {
			width: 37px;
			@include svg(100%, 100%, "/images/icons/prev-report.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/prev-report-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/prev-report-active.svg");
			}
		}
	}

	&--map {
		&::before {
			width: 44px;
			@include svg(100%, 100%, "/images/icons/map.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/map-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/map-active.svg");
			}
		}
	}

	//Карта сайта
	&--site-map {
		&::before {
			width: 50px;
			@include svg(100%, 100%, "/images/icons/sitemap.svg");
		}
		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/sitemap-hover.svg");
			}
		}
		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/sitemap-active.svg");
			}
		}

		.browser-ie & {
			&::before {
				height: 44px !important;
				margin-top: 4px;
			}
		}
	}

	//глосарий
	&--glossary {
		@include webfont-icon($webfont-icon--library);
	}

	//архив отчетов
	&--archive-reports {
		@include webfont-icon($webfont-icon--box-add);
	}

	//disclaimer
	&--disclaimer {
		@include webfont-icon($webfont-icon--file-text);
	}
}

//Стили для sublist (share, social, compare, etc.)
.b-buttons-panel__sublist {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0 !important;
}

.b-buttons-panel__sublist-inner {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	background-color: $color-white;
	padding: 8px 10px;
}

.b-buttons-panel__sublist-link {
	position: relative;
	display: block;
	text-decoration: none;
	color: $tools-text;
	width: 27px;
	height: 22px;

	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		@include font-size($buttons-panel--sublist-icon-font-size);
	}

	&:hover,
	&:focus,
	&:visited:focus,
	&:visited:hover {
		color: $tools-text-hover;
	}

	&:active,
	&:visited:active {
		color: $tools-text-active;
	}

	&--vkontakte {
		@include webfont-icon($webfont-icon--vk);

		&:before {
			@include font-size(10px);
		}
	}

	&--linkedin {
		@include webfont-icon($webfont-icon--linkedin);
	}

	&--twitter {
		@include webfont-icon($webfont-icon--twitter);
	}

	&--facebook {
		@include webfont-icon($webfont-icon--facebook);

		&:before {
			@include font-size(15px);
		}
	}

	&--youtube {
		@include webfont-icon($webfont-icon--youtube);
	}

	&--googleplus {
		@include webfont-icon($webfont-icon--google-plus);

		&:before {
			@include font-size(13px);
		}
	}

	&--email {
		@include webfont-icon($webfont-icon--mail);

		&:before {
			@include font-size(11px);
		}
	}
}

.b-buttons-panel__sublist-link--compare {
	white-space: nowrap;
	float: none;
	width: auto;
	height: auto;
	@include rem(padding, 5px 10px);
	@include font-size($buttons-panel--compare-link-font-size);
}

.tooltipster-sidetip.tooltipster-buttons-panel {
	.tooltipster-box {
		border: none;
		background: $buttons-panel--tooltips-bg-color;
		color: $buttons-panel--tooltips-color;
		@include font-size(15px);
		@include font(400);
		@include rem(border-radius, 5px);
	}

	.tooltipster-content {
		color: $buttons-panel--tooltips-color;
	}


	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: $buttons-panel--tooltips-bg-color;
	}

	.tooltipster-arrow-border {
		border: none;
	}

	&.tooltipster-bottom .tooltipster-arrow-uncropped {
		top: -11px;
	}

	&.tooltipster-right .tooltipster-arrow-uncropped {
		left: -11px;
	}
}
