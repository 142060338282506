$noty-bg: $color-white;
$noty-color: $nero;
$noty-close-icon-color: $color-mine-shaft;
$noty-close-icon-color-hover: $color-black;
$noty-close-icon-color-active: $color-carrot-orange;

#noty_layout__default {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99999;
}

#noty_layout__default.noty_layout--static {
	position: relative;
}

.noty_bar {
	width: 100%;
}

.b-noty {
	margin: 0 auto;
	width: 100%;
	position: relative;

	&__body {
		padding-top: 20px;
		padding-right: 12px;
		padding-bottom: 20px;
		padding-left: 12px;
		margin: auto;
	}

	&__body--narrow {
		max-width: 1200px;
		border: 1px solid #004077;
		background-color: $noty-bg;
		border-top: 0;
		position: relative;
		z-index: 1;
		box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);

		&:first-child {
			border-bottom: 0;
			border-top: 1px solid #004077;

			&::after {
				display: none;
			}
		}
	}

	&--old-browser {
		background-color: $hawkes-blue;
		z-index: 2;
	}

	&--cookie-law {
		padding: 13px 28px;
	}

	&__title {
		display: block;
		font-size: 18px;
		line-height: 25px;
		color: $regal-blue;
		margin-bottom: 26px;
		@include font(700, 'Roboto');
	}

	&__message,
	&__text {
		color: $noty-color;
		font-size: 18px;
		line-height: 25px;
		@include font(300, 'Roboto');
		margin-bottom: 30px;
    	display: block;
	}

	&__close {
		display: none;
		position: absolute;
		top: 10px;
		right: 10px;
		padding: 0;
		background-color: transparent;
		color: $noty-close-icon-color;
		@include webfont-icon($webfont-icon--close_2);
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			@include font-size(20px);
		}

		&:hover,
		&:focus {
			color: $noty-close-icon-color-hover;
		}

		&:active {
			color: $noty-close-icon-color-active;
		}
	}
}

.b-noty--centered-content {
	.b-noty__message {
		text-align: center;
	}

	.b-noty__buttons {
		position: absolute;
		right: 20px;
		top: 50%;
		margin-top: -20px / 2;
	}
}

