.content-area,
#colorbox,
.tooltipster-base {
	li {
		position: relative;
		text-align: left;
		list-style-type: none;
		position: relative;
		@include font(300, 'Roboto');
		font-size: 18px;
		color: $nero;

		&:first-child {
			margin-top: 0;
		}

		// & + li {
		// 	margin-top: 0.5em;
		// }
	}

	ul {
		margin-top: 10px;
		margin-bottom: 30px;

		ol {
			li {
				padding-left: 1.5em;
			}
		}

		& > li {
			padding-left: 0.8em;

			&:before {
				content: '';
				position: absolute;
				background-color: $list-dot;
				top: 9px;
				border-radius: 100%;
				left: -1px;
				width: 6px;
				height: 6px;
			}

			ul {
				margin-top: 5px;
				li {
					padding-left: 20px;
					&:before {
						width: 4px;
						height: 4px;
						left: 5px;
					}

					ul {
						li {
							&::before {
								width: 2px;
								height: 2px;
							}
						}
					}
				}
			}
		}

		ol {
			counter-reset: list-numbers;

			li {
				&:before {
					background-color: transparent;
				}
			}
		}
	}

	ul.current-color {
		& > li:before {
			background-color: currentColor;
			border-color: currentColor;
		}
	}

	ol.current-color {
		& > li:before {
			color: currentColor;
		}
	}

	//Список без маркеров
	.no-markers {
		li {
			padding-left: 1.5em;

			&:before {
				display: none;
			}
		}
	}

	//Нумерованный список
	ol {
		counter-reset: list-numbers;
		margin-bottom: 57px;

		& > li {
			padding-left: 1em;

			&:before {
				content: counters(list-numbers, ".");
				counter-increment: list-numbers;
				display: block;
				width: 20px;
				position: absolute;
				top: 0;
				left: 0;
				color: $color-ironside-gray;
				@include font(300, 'Roboto');
			}

			ol {
				margin-top: 5px;
				li {

					// ol {
					// 	li {
					// 		&::before {
					// 			width: 2px;
					// 			height: 2px;
					// 		}
					// 	}
					// }
				}
			}
		}

		ol {
			padding-top: 0;
			margin-top: 10px;
		}
	}

	ol {
		ol li ol li {
			padding-left: 1.7em;

			&:before {
				left: -0.75em;
			}
		}

		& > li {
			ol > li {
				padding-left: 1.8em;
			}
		}
	}

	ul ol,
	ol ul,
	ul ul,
	ol ol {
		margin: 0.7em 0;
	}
}

.b-table {
	ul {
		& > li {
			&:before {
				top: calc(0.3em * 1.2);
			}
		}

		ul li {
			&:before {
				top: calc(0.35em * 1.2);
			}

			ul li {
				&:before {
					top: calc(0.375em * 1.2);
				}
			}
		}
	}
}

.blue-block {
	background-color: $hawkes-blue;
	margin-left: -72px;
	padding: 27px 10px 31px 72px;
	margin-bottom: 41px;
	p {
		@include font(300, 'Roboto');
		font-size: 18px;
		color: $nero;
		margin-bottom: 7px;
	}

	ul {
		margin: 0;
	}

	li {
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
@mixin reset-counter($counterName) {
	ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".");
				counter-increment: $counterName;
			}
		}
	}

	ul li ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".");
				counter-increment: $counterName;
			}
		}
	}

	ol li ul li {
		&:before {
			text-indent: -9999px;
		}
	}
}

.b-accordion {
	@include reset-counter(list-accordion);
}

.b-table {
	@include reset-counter(list-table);
}

.b-tabs {
	@include reset-counter(list-tabs);
}
