.b-logo {
	width: 138px;
	margin: 0 auto;

	&__image--mobile {
		display: none;
	}

	@media (max-width: 1399px) {
	// @include breakpoint(md) {
		width: 40px;
		margin: 0 17px 0 auto;

		.is-open & {
			margin: 0 auto;
		}

		&__image {
			display: none;
			&--mobile {
				margin: 0 auto;
				display: block;
			}
		}
	}
}

.b-logo--expandable {
	overflow: hidden;
	width: 35px; //Ширина до наведения
	height: 40px;

	.b-logo__image {
		width: 130px; //Ширина после наведения, также нужно задать в config.yaml
	}
}
