$bod_bod-animated-blocks--content-bg: $color-eallery;
$bod_bod-animated-blocks--content-arrow: $color-white;
$bod_bod-animated-blocks--name-color: $color-ironside-gray;

@if ($contrast) {
	$bod_bod-animated-blocks--content-bg: $color-white;
	$bod_bod-animated-blocks--content-arrow: $color-ironside-gray;
	$bod_bod-animated-blocks--name-color: $color-white;
}

.b-bod--animated-blocks {
	p {
		margin-top: 0;
	}

	%content-arrow,
	.content-arrow {
		content: '';
		width: 0;
		height: 0;
		border-left-color: transparent;
		border-right-color: transparent;
		border-top-color: $color-navy-blue;
		border-left-style: solid;
		border-right-style: solid;
		border-top-style: solid;
		@include rem(border-left-width, 20px);
		@include rem(border-right-width, 20px);
		@include rem(border-top-width, 15px);
		position: absolute;
		top: 0;
	}

	.b-bod-main {
		position: relative;
		text-align: center;
		cursor: pointer;

		&__photo {
			@include rem(width, 172px);
			@include rem(height, 172px);
			position: relative;
			right: 0;
			left: 0;
			margin: auto;

			img {
				@include rem(width, 152px);
				@include rem(height, 152px);
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}

		&.is-arrowed {
			&:before {
				@extend .content-arrow;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: 100%;
				z-index: 1;
			}
		}

		&.is-static {
			cursor: default;
		}

		&:hover,
		&:focus,
		&.is-expand,
		&.is-static {
			.b-bod-main__photo {
				img {
					@include rem(width, 172px);
					@include rem(height, 172px);
					transition: all 0.5s;
				}
			}
		}

		&__name {
			@include font-size(16px);
			color: $bod_bod-animated-blocks--name-color;
		}

		&__post {
			@include font-size(13px);
			margin-top: 0;
		}
	}

	.b-bod-content {
		width: 100%;
		border: 1px solid $color-navy-blue;
		position: relative;
		@include rem(padding, 25px);
		display: none;

		&.is-static {
			display: block;
		}
	}
}
