.b-form {
	display: block;
	width: 100%;

	input::-ms-clear {
		display: none;
	}

	&__title {
		font-size: 20px;
        line-height: 34px;
		text-transform: uppercase;
		margin: 0 0 40px;
	}

	&__fields {}

	&__field {}

	&__field:last-child {
		margin-bottom: 0;
	}

	&__field-title {
		margin-bottom: 0.4em;
	}

	&__field-placeholder--smart,
	&__field-placeholder--smart-top {
		cursor: text;
		position: absolute;
		padding: $input-padding;
		transition: all 0.2s ease;
		background-color: transparent;
		white-space: nowrap;
		text-overflow: ellipsis;
		left: 10px;
		font-size: 16px;
		line-height: 24px;
		color: $form-label;

		span {
			color: $form-important;
		}
	}

	&__field-placeholder--smart {
		top: 5px;
	}

	&__field-placeholder--smart-top {
		top: 17px;
	}

	&__field-input {
		position: relative;
		display: inline-block;
		width: 100%;
		margin-bottom: 15px;
		@include rem(padding-bottom, 10px);

		//Текст ошибки для невалидного поля
		.parsley-errors-list {
			& > li {
				position: absolute;
				display: block;
				color: $red-orange;
				font-size: 12px;
				line-height: 16px;
				position: relative;
				margin-top: 5px;

				&::after {
					@include svg(100%, 100%, "/images/icons/error.svg");
					position: absolute;
					right: 0;
					left: auto;
					content: '';
					width: 18px;
					height: 18px;
					border: 0;
					top: 0;
				}
			}
		}

		&--smart-placeholder {
			padding-right: 0;
		}

		&--padding {
			padding-right: 33px;
		}
	}

	&__field-input input,
	&__field-input textarea {
		width: 100%;
		transform: translateZ(0);
		background-color: transparent;
		border: 2px solid $form-border;
		font-size: 16px;
		line-height: 24px;
		color: rgba(0, 0, 0, 0.87);
		padding-left: 16px;

		&:focus {
			border-color: $forn-border-focus;
		}
	}

	&__field-input input.is-error,
	&__field-input textarea.is-error {
		border-color: #DB4437;
	}

	&__field-input input {
		border-top: 0;
		border-left: 0;
		border-right: 0;
	}

	&__field-input textarea {
		display: block;
		resize: none;
	}

	&__field-input--smart-placeholder {
		//Меняем положение span с текстовой меткой при состоянии focus или not-empty родительского label
		&.is-focused .b-form__field-placeholder--smart,
		&.is-focused .b-form__field-placeholder--smart-top {
			opacity: 1;
			top: 9px;
			transform: translateY(-150%);
			color: $color-white;
			background-color: $color-ironside-gray;
			cursor: default;
			@include rem(padding, 5px 7px);
			@include font-size(14px);

			&:after {
				content: '';
				display: block;
				width: 12px;
				height: 12px;
				border-style: solid;
				border-width: 6px;
				border-color: $color-ironside-gray transparent transparent transparent;
				position: absolute;
				bottom: -11px;
				left: 10px;
				cursor: default;
			}
		}

		&.is-fill .b-form__field-placeholder--smart,
		&.is-fill .b-form__field-placeholder--smart-top {
			opacity: 0;
		}
	}

	&__footnote {
		color: $form-footnote;
		font-size: 14px;
		line-height: 18px;
		@include font(300, 'Roboto');

		span {
			color: $form-important;
		}
	}

	.b-captcha {
		padding-bottom: 16px;
	}

	.btn {
		letter-spacing: 0.0357143em;
	}
}
