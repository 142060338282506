$useful-links--btn-bg: $color-ironside-gray;
$useful-links--btn-color: $color-white;
$useful-links--bg: $color-eallery;
$useful-links--link-color: $color-black;
$useful-links--link-color-hover: $color-tapa-gray;

.b-useful-links {
	@include rem(margin-top, $grid-gutter-width);
	@include rem(padding, $grid-gutter-width);

	&__link {
		@extend %disable-visited;
		display: block;
		position: relative;
		@include font-size(13px);
		padding: 7px 0 1px;
		text-decoration: none;
		color: $useful-links--link-color;

		&:hover {
			color: $useful-links--link-color-hover;
			text-decoration: underline;
		}
	}
}
