$bc-link-color: $jordy-blue;
$bc-link-color-hover: $navy-blue;
$bc-link-color-active: $cobalt;
$bc-link-color-visited: $jordy-blue;

$bc-home-icon-color: #444444;
$bc-home-icon-color-hover: $link-hover-color;
$bc-home-icon-color-active: $link-active-color;

$bc-item-color: black;
$bc-dash-color: $color-ironside-gray;

$bc-sub-bg: #cdcdcd;
$bc-sub-link-color: $link-default-color;
$bc-sub-link-color-hover: $link-hover-color;

.breadcrumbs {
	@extend .no-markers;
	margin-top: 0;
	@include font-size(14px);

	&--short {
		&::after {
			display: none;
		}
	}

	ul.breadcrumbs-sub {
		display: none;
		position: absolute;
		white-space: nowrap;
		@include rem(padding, 2px 5px);
		color: $bc-sub-link-color;
		background-color: $bc-sub-bg;
		margin-top: 0;
		z-index: 11;

		li.breadcrumbs-sub__item {
			margin-top: 0;
			padding-left: 0;

			& + .breadcrumbs-sub__item {
				@include rem(margin-top, 4px);
			}
		}

		&__link {
			color: $bc-sub-link-color;

			&:visited {
				color: $bc-sub-link-color;

				&:hover {
					color: $bc-sub-link-color-hover;
				}
			}

			&:hover {
				color: $bc-sub-link-color-hover;
				text-decoration: none;
			}
		}
	}

	ul.breadcrumbs__list {
		margin-top: 0;
	}

	&__item-inner {
		font-size: 14px;
		color: $bc-link-color;
	}

	&__item {
		position: relative;
		display: inline-block;
		color: $bc-item-color;
		vertical-align: middle;
		position: relative;

		&:first-child {
			padding-right: 33px;
			@include webfont-icon($webfont-icon--arrows, after);
			margin-right: 18px;
			&:after {
				position: absolute;
				right: 0;
				font-size: 11px;
				top: 4px;
			}
		}

		& + .breadcrumbs__item {
			padding-right: 22px;
			margin-right: 20px;
			@include webfont-icon($webfont-icon--arrow-new, after);
			&:after {
				position: absolute;
				top: 4px;
				right: 0;
				font-size: 11px;
			}
		}

		&:last-child {
			margin: 0;
			padding: 0;
			&::after {
				display: none;
			}
		}

		&:hover {
			.breadcrumbs-sub {
				display: block;
			}
		}
	}

	&__link {
		color: $bc-link-color;
		font-size: 14px;
		text-decoration: none;

		&:visited {
			color: $bc-link-color-visited;
		}

		&:hover,
		&:focus {
			color: $bc-link-color-hover;
			text-decoration: none;
		}

		&:active {
			color: $bc-link-color-active;
		}
	}

	&__link--home {
		position: relative;
		display: block;
		color: $bc-home-icon-color;
		font-size: 0;
		margin-bottom: 2px;
		text-decoration: none;

		&:before {
			content: '';
			width: 16px;
			height: 16px;
			@include svg(100%, 100%, "/images/icons/home.svg");
			display: block;
		}

		&:hover,
		&:focus {
			color: $bc-home-icon-color-hover;
			&:before {
				@include svg(100%, 100%, "/images/icons/home-hover.svg");
			}
		}

		&:active {
			color: $bc-home-icon-color-active;

			&:before {
				@include svg(100%, 100%, "/images/icons/home-active.svg");
			}
		}
	}

	@include breakpoint(sm) {
		display: none;
	}
}
