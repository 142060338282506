$b-social-link-size: 29px;
$b-social-icon-size: 15px;
$b-social-icon: $color-tundora;
$b-social-icon-hover: $color-white;

.b-social {
	.b-social__list {
		font-size: 0;
		@extend .user-select--no;
	}

	.b-social__item {
		display: inline-block;
		vertical-align: top;
	}

	.b-social__link,
	.b-social__button {
		display: block;
		@include rem(width, $b-social-link-size);
		@include rem(height, $b-social-link-size);
		overflow: hidden;
		position: relative;
		transition: background-color 0.2s ease;

		&:hover {
			color: $b-social-icon-hover;
		}

		&.is-expand,
		&:active {

		}

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include font-size(18px);
			color: $b-social-icon;
		}
	}

	.b-social__list--lvl2 {
		position: absolute;
		white-space: nowrap;
		width: auto;
		display: none;
		bottom: 100%
	}

	.has-sublist {
		position: relative;
	}

	.b-social__item--lvl2 {
		display: block;
	}

	.b-social__link--lvl2 {
		background-color: red;
	}

	.b-social__link--twitter {
		@include webfont-icon($webfont-icon--twitter);
		&::before {
			content: '';
			width: 18px;
			height: 17px;
			@include svg(100%, 100%, "/images/icons/twitter.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/twitter-hover.svg");
			}
		}
		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/twitter-hover.svg");
			}
		}
	}

	.b-social__link--facebook {
		&::before {
			width: 10px;
			height: 22px;
			content: '';
			@include svg(100%, 100%, "/images/icons/facebook.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/facebook-hover.svg");
			}
		}
		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/facebook-hover.svg");
			}
		}
	}

	

	.b-social__link--vkontakte {
		&::before {
			width: 21px;
			height: 13px;
			content: '';
			@include svg(100%, 100%, "/images/icons/vk.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/vk-hover.svg");
			}
		}
		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/vk-hover.svg");
			}
		}
	}

	.b-social__link--youtube {
		&::before {
			width: 19px;
    		height: 17px;
			content: '';
			@include svg(100%, 100%, "/images/icons/youtube.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/youtube-hover.svg");
			}
		}
		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/youtube-hover.svg");
			}
		}
	}

	.b-social__link--instagram {
		&::before {
			width: 14px;
			height: 14px;
			content: '';
			@include svg(100%, 100%, "/images/icons/instagram.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/instagram-hover.svg");
			}
		}
		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/instagram-hover.svg");
			}
		}
	}

	@include breakpoint(md) {
		.b-social__link,
		.b-social__button {
			width: 27px;
		}
	}
	@include breakpoint(sm) {
		margin-left: -7px;
		margin-right: 9px;
	}
}