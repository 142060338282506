// Цвет фона фиксированной шапки
$table--fixed-head-bg: $alice-blue;
// Шапка
$table--header-color: $table-head;
$table--header-bg: $table-bg-head;
// Подзаголовок
$table--subhead-color: $color-navy-blue;
// Выделенная ячейка
$table--strong-cell-color: $base-text-color;
$table--strong-cell-bg: $color-celeste;
$table--strong-cell-border: $color-white;
// Строка итогов
$table--summary-color: $base-text-color;
$table--summary-hover: $table-bg-hover;
$table--summary-active-js: $table--summary-hover;
// Фон строки по наведению
$table-row-bg-hover: $table-bg-hover;
$table-row-bg-strong-hover: $table-bg-hover;
// Выделенная строка
$table--strong-row-color: $table-bold-text;
$table--strong-row-bg: $table--strong-cell-bg;
$table--strong-row-bg-hover: $color-mountain-mist;
$table--strong-row-bg-js: $table--strong-row-bg-hover;
// Выделенная строка (при клике)
$table--selected-row-bg: $table-bg-active;
$table--selected-row-bg-strong: $table-bg-active;


table {
	border-collapse: collapse;
	border-spacing: 0;

	td,
	th {
		padding: 0;
	}

	.a_l {
		text-align: left !important;
	}

	.a_c {
		text-align: center !important;
	}

	.a_r {
		text-align: right !important;
	}

	.a_t {
		vertical-align: top !important;
	}

	.a_m {
		vertical-align: middle !important;
	}

	.a_b {
		vertical-align: bottom !important;
	}
}

// Стили для финансовой таблицы (стандартной)
.b-table {
	position: relative;
	margin-bottom: 70px;

	&::after {
		content: ".";
		visibility: hidden;
		display: block;
		height: 0;
		clear: both;
	}
	figcaption {
		@include font-size(20px);
		@include font(500, 'Roboto');
		color: $table-figcaption;
		margin-bottom: 32px;

		.caption__units,
		.b-caption__units {
			@include font(400, 'Roboto');
			font-style: italic;
			color: $graphic-caption;
		}
	}

	table {
		min-width: 100%;
		margin-bottom: 25px;

		thead {
			position: relative;
			&::after {
				position: absolute;
				left: 0;
				width: 100%;
				content: '';
				border-bottom: 3px solid #0080C8;
			}

			//Ячейки в заголовках
			td,
			th {
				color: $table--header-color;
				background-color: $table--header-bg;
				text-align: right;
				@include font(500, 'Roboto');
				border-bottom: 0;
				border-right: 1px solid $table-border;
				font-size: 20px;
				vertical-align: bottom;
				padding-top: 28px;
				padding-bottom: 9px;

				&:first-child {
					// border-right: 0;
				}

				&[colspan] {
					text-align: center;
				}
			}

			th.b-cell--strong {
				color: $color-white;
				background-color: $navy-blue;
			}

			th.border-bottom {
				border-bottom: 1px solid $table-border;
			}

			th.border-left {
				border-left: 1px solid $table-border;
			}

			tr:first-child {
				th:first-child,
				td:first-child {
					text-align: left;
				}
			}
		}

		tbody {
			tr {
				&:hover {
					background-color: $table-row-bg-hover;
				}
			}
		}

		//Обычные ячейки (не в заголовках)
		td,
		th {
			position: relative;
			text-align: right;
			border-bottom-style: solid;
			@include rem(border-bottom-width, 1px);
			padding: 14px 15px 12px;
			@include rem(line-height, 16px);
			transition: background-color 0.2s linear;
			background-clip: padding-box;
			font-size: 18px;
			border-bottom-color: $table-border;
			border-right: 1px solid $table-border;
			@include font(300, 'Roboto');
			color: $color-black;
			line-height: normal;

			&:first-child {
				text-align: left;
				// border-right: 0;
			}
		}

		.three {
			border-left: 1px solid $table-border;
			border-bottom: 1px solid $table-border;
			background-color: rgba(208, 208, 208, 0.8);
		}

		.right {
			text-align: right;
			border-right: 1px solid $table-border;
			border-left: 1px solid $table-border;

			&:first-child {
				text-align: right;
				border-right: 1px solid $table-border;
			}
		}

		.last {
			border-right: 0;
		}
	}

	//Подзаголовок
	.b-subhead {
		font-weight: bold;
		text-transform: uppercase;
		color: $table--subhead-color;
	}

	.b-subhead--level-1 td:first-child {
		padding-left: 0;
	}

	.b-subhead--level-2 td:first-child {
		padding-left: 32px;
	}

	.b-subhead--level-3 td:first-child {
		@include rem(padding-left, 50px);
	}

	//Выделеная ячейка
	.b-cell--strong {
		color: $navy-blue;
		@include font(700);
		// background-color: $table--strong-cell-bg;
	}

	.b-cell--strong + .b-cell--strong {
		&:before {
			@include pseudo(1px, 100%, '');
			background-color: $table--strong-cell-border;
			top: 0;
			left: 0;
		}
	}

	.b-cell--level-1 {
		padding-left: 0;
	}

	.b-cell--level-2 {
		@include rem(padding-left, 25px);
	}

	.b-cell--level-3 {
		@include rem(padding-left, 50px);
	}

	.b-cell--regular {
		font-weight: 400 !important;
	}

	.b-cell--bold {
		font-weight: 700 !important;
	}

	//Выделеная строка
	.b-row--strong {
		color: $table--strong-row-color;

		td {
			color: $table--strong-row-color;
			@include font(700);
			border-right: 0;
			border-bottom-color: $color-black;
		}

		td.b-cell--strong {
			background-color: transparent;
		}

		// .is-active,
		// .is-hover {
		// 	background-color: $table--strong-row-bg-js;
		// }

		// &:hover {
		// 	background-color: $table--strong-row-bg-hover;
		// }
	}

	//Выбранная строка(по клику)
	.b-selected-row {
		td {
			background-color: $table--selected-row-bg !important;
			color: $color-white;
		}

		.b-cell--strong {
			background-color: $table--selected-row-bg-strong !important;
			color: $color-white;
		}
	}

	//Итоговая строка
	.b-summary {

		td {
			@include font(500, 'Roboto');
			font-size: 18px;
			color: $color-black;
			border-bottom: 3px solid $table-border-head;

			&:first-child {
				text-transform: uppercase;
			}
		}

		&:hover {
			background-color: $table--summary-hover;
			td {
				color: $color-white;
			}
		}

		.is-active,
		.is-hover {
			background-color: $table--summary-active-js;
		}

		&.b-selected-row {

			td {
				color: $color-white;
			}
		}
	}

	.b-summary--level-1 td:first-child {
		padding-left: 0;
	}

	.b-summary--level-2 td:first-child {
		@include rem(padding-left, 25px);
	}

	.b-summary--level-3 td:first-child {
		@include rem(padding-left, 50px);
	}

	.b-fixed-head {
		overflow: hidden;
		z-index: 10;
		visibility: hidden;
		position: fixed;
		top: 0;
		left: -99999px;
		margin-top: 0;
		background-color: $table--fixed-head-bg;
	}

	// Цвет всего столбца, по наведению на ячейку
	.is-hover {
		background-color: $color-eallery;
	}

	// Цвет всего столбца, по клику на заголовок столбца
	.is-active {
		background-color: $color-eallery;
	}

	.b-subtotals {
		td {
			border-bottom: 0;
			position: relative;
			color: $table--strong-row-color;
			@include font(700);
			border-bottom: 2px solid $mischka;

			&::after {
				// content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: $mischka;
			}
		}
	}

	.b-empty-row {
		td {
			height: 3px;
			padding: 0;
			border-bottom: 2px solid $mischka;
		}
	}

	.b-total {
		margin-top: -23px;
		tr:first-child {
			td {
				border-top: 2px solid $mischka;
			}
		}
	}
}

.b-table--simple {
	table {
		thead {
			th,
			td {
				text-align: left;

				&:first-child {
					border-right: 1px solid $table-border;
				}
			}
		}

		td,
		th {
			text-align: left;

			&:first-child {
				border-right: 1px solid $table-border;
			}
		}
	}
}

// Классы масштабирования
@for $i from 1 through 9 {
	.b-table--scale-#{$i},
	.b-table--auto-scale-#{$i} {
		table {
			td,
			th {
				font-size: 1em - $i / 10 !important;
				padding-left: 1em - $i / 10 !important;
				padding-right: 1em - $i / 10 !important;
			}
		}
	}
}

// Кнопка, скрывающая/раскрывающая таблицу
.b-table__toggle-visible-rows-trigger {
	background: $color-ironside-gray;
	display: inline-block;
	color: $color-white;
	@include rem(padding, 3px 10px);
	text-decoration: none;
}

// Стили для таблицы с горизонтальным скроллом
.b-table {
	&__content {
		overflow: auto;
	}

	&__content-wrapper {
		position: relative;
	}
}

.b-table__content,
.b-table {
	::-webkit-scrollbar {
		height: 14px;
	}

	::-webkit-scrollbar-track {
		background: $color-white;
	}

	::-webkit-scrollbar-thumb {
		background: #919CAB;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		@include bg-rgba(145, 156, 171, 0.7);
	}
}

.b-fixed-scrollbar {
	display: none;
	overflow-x: scroll;
	position: fixed;
	width: 100%;
	margin-top: 0;
	z-index: 3;

	div {
		background: rgba(255, 255, 255, 0.01);
	}
}

.b-table--fixed-scrollbar {
	position: relative;
	overflow: hidden;
	@extend .b-table--scale-1;

	table {
		// margin-top: -2px;
		// margin-bottom: 2px;

		.browser-safari & {
			margin-bottom: 0;
		}
	}

	.b-scroll-arrow {
		position: absolute;
		height: calc(100% - 14px);
		width: 30px;
		top: 0;
		margin-top: 0;
		z-index: 1;
		transition: opacity 0.3s ease, transform 0.3s ease;
		cursor: pointer;
		opacity: 1;
		font-size: 16px;

		&:not(.disabled):hover {
			opacity: 0.7;
		}

		&.disabled {
			opacity: 0.4;
		}

		&.loaded {
			transform: none;
		}

		#colorbox & {
			height: calc(100% - 1.125em);
		}
	}

	.b-scroll-arrow__icon {
		position: absolute;
		transform: translateY(-50%);
		color: $color-white;
	}

	.b-scroll-arrow--left {
		background: linear-gradient(90deg, rgba(97, 110, 121, 1), rgba(255, 255, 255, 0));
		left: 0;
		transform: translateX(-100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(253, 201, 10, 1), rgba(255, 255, 255, 0));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--prev);
			margin-left: 5px;
		}
	}

	.b-scroll-arrow--right {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(97, 110, 121, 1));
		right: 0;
		transform: translateX(100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(253, 201, 10, 1));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--next);
			margin-left: 12px;
		}
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.b-table--no-xls .button--excel {
			display: none;
		}
	}

	@include breakpoint(sm) {
		.b-table {
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			table {
				position: absolute;
				opacity: 0;
				visibility: hidden;
				@include font-size(20px);
			}
		}

		.figure-buttons .button--zoom,
		.b-table__toggle-visible-rows-trigger {
			display: none;
		}
	}
}

#cboxLoadedContent table {
	margin-top: 28px;
	margin-bottom: 35px;
}
