.svg-b {
	transform: matrix(0.98, 0.19, -0.19, 0.98, 611.45, 32.02) !important;
}

.nn {
    margin: 20px auto;
	max-width: 1100px;
	position: relative;
    z-index: 1;
}
@keyframes hide {
	0% {
		opacity: 1;
		height: 30px;
	}

	80% {
		opacity: 0;
		height: 30px;
	}

	99% {
		height: 30px;
		opacity: 0;
	}

	100% {
		height: 0;
	}
}

@media all {
	.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
		background: #7AB9E5;
	}

	.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
		color: #1c94c4;
	}

	.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
		background: #7AB9E5;
	}

	.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
		background: #5A99C5;
	}

	.ui-widget :active {
		outline: none;
	}

	.ui-slider {
		position: relative;
		text-align: left;
	}

	.ui-slider .ui-slider-handle {
		position: absolute;
		width: 36px;
		height: 36px;
		border-radius: 50%;
		cursor: default;
		transition: left .2s;
		z-index: 5;
		outline: none;
		-webkit-appearance: none;
	}

	.ui-slider .ui-slider-handle:before {
		content: '';
		position: absolute;
		top: -12px;
		right: -5px;
		left: -5px;
		bottom: -4px;
		background: url('/images/custom/geo/arrow.svg') center center no-repeat;
	}

	.ui-slider .ui-slider-handle:after {
		content: '';
		position: absolute;
		width: 32px;
		height: 30px;
		top: 100%;
		margin: 15px 0 0 -16px;
		left: 50%;
		background: url('/images/custom/geo/hand.svg') center center no-repeat;
	}

	.ui-slider-handle._moved:after {
		animation: hide .4s linear 0s forwards;
	}

	.ui-slider .ui-slider-range {
		position: absolute;
		z-index: 1;
		font-size: .7em;
		display: block;
	}

	.ui-slider-horizontal {
		height: 36px;
		position: relative;
	}

	.ui-slider-horizontal:before {
		content: '';
		position: absolute;
		top: 50%;
		height: 2px;
		margin-top: -1px;
		right: 0;
		left: 0;
		background: #b4b7bb;
	}

	.ui-slider-horizontal .ui-slider-handle {
		top: 0;
		margin-left: -18px;
	}

	.ui-slider-horizontal .ui-slider-range {
		top: 0;
		height: 100%;
	}

	.ui-slider-horizontal .ui-slider-range-min {
		left: 0;
	}

	.ui-slider-horizontal .ui-slider-range-max {
		right: 0;
	}

	.ui-slider-range-min {
		position: relative;
		transition: width .2s;
		z-index: 1;
	}

	.ui-slider-dots {
		position: absolute;
		height: 4px;
		top: 50%;
		left: 0;
		right: 0;
		margin: -2px -2px 0 -2px;
		display: flex;
		justify-content: space-between;
		z-index: 2;
	}

	.ui-slider-dots i {
		display: block;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		position: relative;
	}

	.ui-slider-dots i:before {
		content: '';
		position: absolute;
		top: -10px;
		right: -10px;
		bottom: -10px;
		left: -10px;
		cursor: pointer;
		border-radius: 50%;
	}

	.ui-slider-dots i:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background: #133a60;
		margin: -2px 0 0 -2px;
		transition: transform .2s;
		cursor: pointer;
	}

	.ui-slider-dots i:hover:after {
		transform: scale(3);
	}

	.ui-slider-range-min:before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		height: 2px;
		background: #7AB9E5;
		margin-top: -1px;
	}
}

.nn__slider {
	margin-bottom: 50px;
}

.nn__map {
	position: relative;
	margin-bottom: 50px;
}

.nn__map:before {
	content: '';
	float: left;
	width: 0;
	padding-top: 115.956%;
}

.nn__map:after {
	content: '';
	clear: both;
	display: block;
}

.nn__image {

	.geography & {
		position: static;
	}
}

.nn__func {
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	// bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

[data-map*="nnMap_"] {
	display: none;
}

.nn__func a [fill="#00589C"],
.nn__func a [style*="#00589C"] {
	transition: fill .2s;
}

.nn__func a:hover [fill="#00589C"],
.nn__func a:hover [style*="#00589C"] {
	fill: #fbb040 !important;
}

[data-tooltip] {
	cursor: help;
}

.nn__tooltip {
	position: absolute;
	background: #ffffff;
	display: none;
	padding: 20px;
	max-width: 300px;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.24)) drop-shadow(0 0 4px rgba(0, 0, 0, 0.12));
	border-radius: 2px;
	margin-top: 15px;
	z-index: 1;
}

.nn__tooltip[data-size*="big"] {
	max-width: 500px;
}

.nn__tooltip[data-pos*="left"] {
	margin-left: 80px;
}

.nn__tooltip[data-pos*="right"] {
	margin-right: -133px;
}

.nn__tooltip[data-size*="big"][data-pos*="left"] {
	// margin-left: 0px;
}

.nn__tooltip[data-size*="big"][data-pos*="right"] {
	margin-right: -423px;
}

.nn__tooltip:before {
	content: '';
	position: absolute;
	top: -10px;
	border: 10px solid transparent;
	border-top-width: 0;
	border-bottom-color: #fff;
}

.nn__tooltip[data-pos*="left"]:before {
	left: 20px;
}

.nn__tooltip[data-pos*="right"]:before {
	right: 20px;
	left: auto;
}

.nn__tooltip[data-size*="big"][data-pos*="left"]:before {
	left: 110px;
}

.nn__tooltip[data-size*="big"][data-pos*="right"]:before {
	// right: 110px;
	right: 166px;
    left: auto;
}

.nn__tooltarget {
	border-radius: 50%;
	cursor: help;
	position: absolute;
	width: 1.7%;
	z-index: 1;
	transition: background-color .2s;
}

.nn__tooltarget:hover {
	background: rgba(0, 128, 200, .4);
}

.nn__tooltarget:before {
	content: '';
	float: left;
	padding-top: 100%;
}

.nn__tooltarget._usa {
	// top: 49.3%;
	// left: 16%;
	top: 50.5%;
    left: 15%;
    width: 50px;
    height: 17px;
	transform: rotate(64deg);
	border-radius: 0;
}

.nn__tooltarget._eur {
	// top: 51.5%;
	// left: 13.9%;
	// top: 31.8%;
	// left: 12%;
	// width: 50px;
	// height: 17px;
	top: 33.8%;
    left: 9%;
    width: 70px;
    height: 17px;
	transform: rotate(95deg);
	border-radius: 0;
}

.nn__tooltarget._sng {
	// top: 26.2%;
	// left: 21.2%;
	// top: 16.2%;
	// left: 18.8%;
	top: 17.2%;
    left: 15.5%;
    width: 70px;
    height: 17px;
	transform: rotate(120deg);
	border-radius: 0;
}

.nn__tooltarget._chi {
	// top: 38.1%;
	// left: 94.9%;
	// top: 23.6%;
	// left: 84.5%;
	top: 21.6%;
    left: 80.5%;
    width: 70px;
    height: 17px;
	transform: rotate(-117deg);
	border-radius: 0;
}

.nn__tooltarget._asi {
	// top: 83.8%;
	// left: 91.5%;
	// top: 51.5%;
	// left: 81.5%;
	top: 49.5%;
    left: 80%;
	transform: rotate(119deg);
	width: 70px;
	height: 17px;
	border-radius: 0;
}

.nn__tooltarget._gre {
	// top: calc(16% + 2px);
	// left: 51.2%;
	top: calc(10% + 0px);
    left: 45.5%;
}

.nn__tooltarget._ora {
	// top: 20.9%;
	// left: 71.2%;
	top: 12.9%;
    left: 63.2%;
}

.t-title {
	font-size: 16px;
	line-height: 23px;
	color: #133a60;
	margin-bottom: 15px;
}

.t-par {
	color: #0080c8;
}

.t-big {
	font-size: 34px;
	line-height: 48px;
	color: #bcdcf2;
	font-weight: bold;
	text-shadow: 1px 1px 0 #133A60, -1px -1px 0 #133A60, 1px -1px 0 #133A60, -1px 1px 0 #133A60;
	margin-top: 5px;
}

.t-name {
	color: #ffffff;
	font-size: 20px;
	line-height: 24px;
	font-weight: bold;
	background: #133A60;
	padding: 17px 20px;
	margin-bottom: 32px;
}

.t-main {
	position: relative;
	min-height: 50px;
	display: flex;
	align-items: center;
	padding-left: 70px;
	color: #133A60;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 20px;
}

.t-main:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin-top: -25px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid #133A60;
}

.t-main._green:before {
	background: #78BA54;
}

.t-main._orange:before {
	background: #F9B234;
}

.t-text {
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	color: #212121;
	margin-bottom: 20px;
}

.t-map {
	width: 100%;
	display: block;
}

@media (max-width: 1440px) {
	.nn__tooltip[data-size*="big"] {
		max-width: 700px;
		width: 100%;

		.t-name,
		.t-main._green {
			margin-bottom: 15px;
		}
	}
	.nn__tooltip[data-size*="big"][data-pos*="left"] {

		&::before {
			left: 241px;
		}
	}

	.nn__tooltip[data-size*="big"][data-pos*="right"] {
		&::before {
			right: 208px;
		}
	}
}

@include breakpoint(md) {
	.nn {
		&__slider {
			display: none;
		}

		&__func {
			width: 100%;
			max-width:100%;
		}
	}
}