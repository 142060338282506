$preloader--size-width: 80px;
$preloader--size-height: 80px;
$preloader--bg-color: $color-white;
$preloader--bg-opacity: 1;
$preloader--color: $color-gray;

.preloader {
	min-height: $preloader--size-height * 2;
	min-width: $preloader--size-width * 2;
	z-index: 999999999;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($preloader--bg-color, $preloader--bg-opacity);
	}

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 50%;
		top: 50%;
		width: 105px;
		height: 105px;
		margin: 0 auto;
		border: 12px solid #00589C;
		border-radius: 50%;
		border-left-color: transparent;
		border-right-color: transparent;
		animation: cssload-spin 575ms infinite linear;
			-o-animation: cssload-spin 575ms infinite linear;
			-ms-animation: cssload-spin 575ms infinite linear;
			-webkit-animation: cssload-spin 575ms infinite linear;
			-moz-animation: cssload-spin 575ms infinite linear;
		margin: -50px 0 0 -50px;
	}
}

.preloader--is-button {
	min-height: inherit;
	min-width: inherit;

	&:after {
		@include svg(auto, 80%, '/images/components/preloader/.variant/base/preloader.svg', $preloader--color, 'stroke');
	}
}

.preloader:not(.preloader--is-flow-item) {
	position: relative;
}

.feature-no-svg .preloader:after {
	background-image: url('/images/components/preloader/.variant/base/preloader.gif') !important;
}


@keyframes cssload-spin {
	100%{ transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes cssload-spin {
	100%{ -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes cssload-spin {
	100%{ -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes cssload-spin {
	100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes cssload-spin {
	100%{ -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
