.b-useful-links--static {
	background-color: $color-white;
	margin: 0;
	padding: 22px 21px 36px;
	position: relative;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	border-radius: 3px;

	&::after {
		content: '';
		position: absolute;
		right: 15px;
		bottom: 20px;
		@include svg(100%, 100%, "/images/icons/icon-link.svg");
		width: 22px;
    	height: 7px;
	}

	.b-useful-links {
		&__title {
			@include font-size(24px);
			color: $useful-title;
			@include rem(padding-bottom, 10px);
			border: 0;
			margin: 0;
			@include font(400);
		}

		&__list {
			@include rem(padding-top, 10px);
		}

		&__item {
			padding-left: 0;
			&::before {
				display: none;
			}
		}

		&__link {
			font-size: 16px;
			line-height: 23px;
			color: $useful-link;

			&:hover {
				color: $useful-link-hover;
				text-decoration: underline;
			}

			&:active,
			&:visited {
				color: $useful-link-active;
				text-decoration: underline;
			}
		}
	}
}

.b-useful-links--date {
	padding-bottom: 39px;
	margin-bottom: 64px;

	&::after {
		width: 22px;
		height: 20px;
		@include svg(100%, 100%, "/images/icons/datepicker.svg");
		right: 16px;
		bottom: 17px;
	}

	p {
		color: $navy-blue;
		font-size: 16px;
		line-height: 23px;
		margin-bottom: 6px;
	}

	.b-useful-links {
		&__info {
			font-size: 14px;
			line-height: 20px;
			@include font(400, 'Roboto');
			font-style: italic;
			margin-bottom: 8px;
			color: $useful-info;
			margin-top: 6px;

			&--big {
				font-size: 16px;
				line-height: 23px;
				color: $useful-info-big;
				margin-bottom: 14px;

				a {
					font-size: 16px;
					margin-left: 5px;
				}
			}
		}
	}
}

.b-useful-links--arrow {
	padding-top: 29px;

	&::after {
		@include svg(100%, 100%, "/images/icons/arrow-link.svg");
		width: 13px;
		height: 14px;
		right: 19px;
		bottom: 19px;
	}
}

.b-useful-links--noicon {
	&::after {
		display: none;
	}
}