/* Links */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
	color: $link-default-color;
	background-color: transparent;
	transition: color 0.2s ease;
	text-decoration: underline;
	font-size: 18px;

	&:visited {
		color: $link-visited-color;
	}

	&:hover,
	&:focus {
		color: $link-hover-color;
	}

	&:active {
		color: $link-active-color;
	}

	&:active,
	&:hover {
		outline: 0;
	}


	&.btn {
		&:visited {
			color: $btn--color;
		}

		&:hover,
		&:focus,
		&:visited:hover,
		&:visited:focus {
			color: $btn--color-hover;
		}

		&:active,
		&:visited:active {
			color: $btn--color-active;
		}
	}
}

a.color-navy-blue {
	color: $color-navy-blue;
}

a.color-green {
	color: $mantis;
}

a.color-yellow {
	color: $my-sin;
}

[data-anchor-link] {
	cursor: pointer;
}

.link {
	&--external {
		@include webfont-icon($webfont-icon--arrow-link);

		&:before {
			display: inline-block;
			@include font-size(14px);
			margin-right: 0.2em;
			vertical-align: middle;
		}
	}

	&--footer {
		font-size: 12px;
		color: $regal-blue;
		margin-left: 9px;

		&:before {
			margin-top: 2px;
			font-size: 9px;
			margin-right: 4px;
		}

		&:focus,
		&:visited {
			color: $regal-blue;
		}

		&:hover,
		&:focus,
		&:visited:hover {
			color: $navy-blue;
		}

		&:active,
		&:visited:active {
			color: $jordy-blue;
		}

		@include breakpoint(md) {
			font-size: 12px;
			margin-left: 6px;
		}

		@include breakpoint(sm) {
			font-size: 12px;
		}
	}
}

.link--phone {
	white-space: nowrap;
}

.b-link-icon {
	display: flex;
	//flex: 0 1 auto;
	align-items: center;

	&__icon {
		margin-right: 5px;
		flex-basis: 100%;
		max-width: 25px;
		min-width: 20px;
	}

	&__text {
		flex-shrink: 1;
	}
}

//иконка слева
.b-link-icon--left {
	.b-link-icon__icon {
		img {
			display: block;
		}
	}
}

//иконка справа
.b-link-icon--right {
	.b-link-icon__icon {
		margin-left: 5px;
		margin-right: auto;

		img {
			display: block;
		}
	}
}

//размеры иконок, при необходимости - добавлять модификаторы - xs, xm, xl

//по умолчанию без модификации
.b-link-icon--size-s {
}

//средний размер
.b-link-icon--size-m {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 30px;
	}
}

//большой размер
.b-link-icon--size-l {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 40px;
	}
}

.b-link-icon--size-free {
	.b-link-icon__icon {
		flex-basis: auto;
		max-width: initial;
	}
}

// svg
.graphic--scheme svg a {
	text-decoration: none;
	@include font-size(16px);
	//
	&:visited {
		fill: $link-visited-color;
	}

	&:hover,
	&:focus {
		fill: $link-hover-color;
	}

	&:active {
		fill: $link-active-color;
	}
}
