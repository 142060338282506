// Длительность анимаций
$buttons-animation-duration: 0.35s;
// Ширина обводки
$btn--border-width: 0.267em;
// Внутренний отступ (щель, просвет) для типа trim, outline
$btn--border-width-inner: 0.133em;
// Ширина линии для типов reveal, underline, overline
$btn--line-width: 0.267em;
// Цвет обводки
$btn--border: orange;
// Цвет внутреннего отступа (щели, просвета) для типа trim
$btn--border-inner: $body-bg;
// Цвет теней
$btn--shadow-color: $color-black;

@if ($contrast) {
	$btn--border: $color-black;
	$btn--border-inner: $body-bg;
	$btn--shadow-color: $color-black;
}

%btn--fill-sides {
	position: relative;
	transition: color $buttons-animation-duration, background $buttons-animation-duration;
	transform: perspective(1px) translateZ(0);

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transform-origin: 50%;
		transition: transform $buttons-animation-duration ease-out, background $buttons-animation-duration;

		.browser-ie-9 & {
			z-index: 0;
		}
	}
}

%btn--fill-out-colors {
	&:before {
		background: $btn--bg-hover;
	}

	&:hover,
	&:focus {
		background: $btn--bg;
	}

	&:active {
		background: $btn--bg;

		&:before {
			background: $btn--bg-active;
		}
	}
}

%btn--fill-in-colors {
	background: $btn--bg-hover;

	&:before {
		background: $btn--bg;
	}
}

.btn--fade {
	transition: color $buttons-animation-duration, background $buttons-animation-duration;
}

.btn--sides-out-vertical {
	@extend %btn--fill-sides;
	@extend %btn--fill-out-colors;

	&:before {
		transform: scaleY(0);
	}

	&:hover,
	&:focus {
		&:before {
			transform: scaleY(1);
		}
	}
}

.btn--sides-in-vertical {
	@extend %btn--fill-sides;
	@extend %btn--fill-in-colors;

	&:before {
		transform: scaleY(1);
	}

	&:hover,
	&:focus {
		&:before {
			transform: scaleY(0);
		}
	}
}

.btn--sides-out-horizontal {
	@extend %btn--fill-sides;
	@extend %btn--fill-out-colors;

	&:before {
		transform: scaleX(0);
	}

	&:hover,
	&:focus {
		&:before {
			transform: scaleX(1);
		}
	}
}

.btn--sides-in-horizontal {
	@extend %btn--fill-sides;
	@extend %btn--fill-in-colors;

	&:before {
		transform: scaleX(1);
	}

	&:hover,
	&:focus {
		&:before {
			transform: scaleX(0);
		}
	}
}

.btn--fill-to-bottom {
	@extend %btn--fill-sides;
	@extend %btn--fill-out-colors;

	&:before {
		transform: scaleY(0);
		transform-origin: 50% 0;
	}

	&:hover,
	&:focus {
		&:before {
			transform: scaleY(1);
		}
	}
}

.btn--fill-to-top {
	@extend %btn--fill-sides;
	@extend %btn--fill-out-colors;

	&:before {
		transform: scaleY(0);
		transform-origin: 50% 100%;
	}

	&:hover,
	&:focus {
		&:before {
			transform: scaleY(1);
		}
	}
}

.btn--fill-to-left {
	@extend %btn--fill-sides;
	@extend %btn--fill-out-colors;

	&:before {
		transform: scaleX(0);
		transform-origin: 100% 50%;
	}

	&:hover,
	&:focus {
		&:before {
			transform: scaleX(1);
		}
	}
}

.btn--fill-to-right {
	@extend %btn--fill-sides;
	@extend %btn--fill-out-colors;

	&:before {
		transform: scaleX(0);
		transform-origin: 0 50%;
	}

	&:hover,
	&:focus {
		&:before {
			transform: scaleX(1);
		}
	}
}

.btn--bounce-to-bottom {
	@extend .btn--fill-to-bottom;

	&:hover,
	&:focus {
		&:before {
			transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
		}
	}
}

.btn--bounce-to-top {
	@extend .btn--fill-to-top;

	&:hover,
	&:focus {
		&:before {
			transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
		}
	}
}

.btn--bounce-to-left {
	@extend .btn--fill-to-left;

	&:hover,
	&:focus {
		&:before {
			transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
		}
	}
}

.btn--bounce-to-right {
	@extend .btn--fill-to-right;

	&:hover,
	&:focus {
		&:before {
			transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
		}
	}
}

.btn--bounce-to-right {
	@extend .btn--fill-to-right;

	&:hover,
	&:focus {
		&:before {
			transition-timing-function: cubic-bezier(.52,1.64,.37,.66);
		}
	}
}

.btn--float-shadow {
	position: relative;
	transition: background $buttons-animation-duration, color $buttons-animation-duration, transform $buttons-animation-duration;

	&:before {
		content: '';
		position: absolute;
		z-index: -1;
		top: 100%;
		left: 5%;
		height: 10px;
		width: 90%;
		opacity: 0;
		background: radial-gradient(ellipse at center, rgba(0,0,0,.35) 0%,rgba(0,0,0,0) 80%);
		transition-duration: $buttons-animation-duration;
		transition-property: transform, opacity;

	}

	&:hover,
	&:focus,
	&:active {
		transform: translateY(-5px);

		.browser-ie-9 & {
			transform: translateY(0);
		}

		&:before {
			opacity: 1;
			transform: translateY(5px);
		}
	}
}

.btn--glow {
	transition: background $buttons-animation-duration, color $buttons-animation-duration, box-shadow $buttons-animation-duration;

	&:hover,
	&:focus,
	&:active {
		box-shadow: 0 0 8px $btn--shadow-color;
	}
}

.btn--radial-shadow {
	position: relative;
	transition: background $buttons-animation-duration, color $buttons-animation-duration;

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 0;
		width: 100%;
		box-sizing: border-box;
		background-repeat: no-repeat;
		height: 5px;
		opacity: 0;
		transition: opacity $buttons-animation-duration;
	}

	&:before {
		bottom: 100%;
		background: radial-gradient(ellipse at 50% 150%, $btn--shadow-color 0%, rgba(0, 0, 0, 0) 80%);
	}

	&:after {
		top: 100%;
		background: radial-gradient(ellipse at 50% -50%, $btn--shadow-color 0%, rgba(0, 0, 0, 0) 80%);
	}

	&:hover:before,
	&:focus:before,
	&:active:before,
	&:hover:after,
	&:focus:after,
	&:active:after {
		opacity: 1;
	}
}

%btn--icon {
	position: relative;
	transition: background $buttons-animation-duration;
	overflow: hidden;

	.btn__icon {
		position: absolute;
		height: 100%;
		width: 100%;
		color: $btn--bg;
		transition: top $buttons-animation-duration, left $buttons-animation-duration, color $buttons-animation-duration;

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.btn__text {
		display: inline-block;
		transition: transform $buttons-animation-duration;
	}

	&:hover,
	&:focus {
		color: $btn--bg-hover;

		.btn__icon {
			color: $btn--color-hover;
		}
	}

	&:active {
		color: $btn--bg-active;

		.btn__icon {
			color: $btn--color-active;
		}
	}
}

.btn--icon-to-bottom {
	@extend %btn--icon;

	.btn__icon {
		left: 0;
		top: -100%;
	}

	&:hover,
	&:focus {
		.btn__icon {
			top: 0;
		}

		.btn__text {
			transform: translateY(300%);
		}
	}
}

.btn--icon-to-top {
	@extend %btn--icon;

	.btn__icon {
		left: 0;
		top: 100%;
	}

	&:hover,
	&:focus {
		.btn__icon {
			top: 0;
		}

		.btn__text {
			transform: translateY(-300%);
		}
	}
}

.btn--icon-to-left {
	@extend %btn--icon;

	.btn__icon {
		left: 100%;
		top: 0;
	}

	&:hover,
	&:focus {
		.btn__icon {
			left: 0;
		}

		.btn__text {
			transform: translateX(-300%);
		}
	}
}

.btn--icon-to-right {
	@extend %btn--icon;

	.btn__icon {
		left: -100%;
		top: 0;
	}

	&:hover,
	&:focus {
		.btn__icon {
			left: 0;
		}

		.btn__text {
			transform: translateX(300%);
		}
	}
}

.btn--border-fade {
	transition: background $buttons-animation-duration, border-color $buttons-animation-duration;
	border-width: $btn--border-width;
	border-style: solid;
	border-color: $btn--bg;

	&:hover,
	&:focus,
	&:active {
		border-color: $btn--border;
	}
}

.btn--border-hollow {
	transition: background $buttons-animation-duration, border-color $buttons-animation-duration;
	border-width: $btn--border-width;
	border-style: solid;
	border-color: $btn--bg;

	&:hover,
	&:focus {
		background: none;
		color: $btn--color;
		border-color: $btn--bg-hover;
	}

	&:active {
		color: $btn--color;
		border-color: $btn--bg-active;
	}
}

.btn--border-trim {
	position: relative;
	transition: background $buttons-animation-duration, color $buttons-animation-duration, border-color $buttons-animation-duration;
	border-width: $btn--border-width;
	border-style: solid;
	border-color: $btn--bg;
	padding: $btn--padding-vertical + $btn--border-width $btn--padding-horizontal + $btn--border-width;

	&:before {
		content: '';
		position: absolute;
		border-width: $btn--border-width-inner;
		border-style: solid;
		border-color: $btn--border-inner;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity $buttons-animation-duration;
	}

	&:hover,
	&:focus {
		border-color: $btn--bg-hover;

		&:before {
			opacity: 1;
		}
	}

	&:active {
		border-color: $btn--bg-active;
	}
}

%btn--ripple {
	position: relative;
	transition: background $buttons-animation-duration, color $buttons-animation-duration;

	&:before {
		content: '';
		position: absolute;
		border-width: $btn--border-width;
		border-style: solid;
		border-color: $btn--bg;
		transition: border-color $buttons-animation-duration;
		animation-duration: 1s;
	}

	&:hover:before,
	&:focus:before {
		border-color: $btn--bg-hover;
	}

	&:active:before {
		border-color: $btn--bg-active;
	}
}

@keyframes btn-border-ripple-out {
	100% {
		top: -($btn--border-width * 2);
		right: -($btn--border-width * 2);
		bottom: -($btn--border-width * 2);
		left: -($btn--border-width * 2);
		opacity: 0;
	}
}

.btn--border-ripple-out {
	@extend %btn--ripple;

	&:before {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&:hover:before,
	&:focus:before {
		animation-name: btn-border-ripple-out;
	}
}

@keyframes btn-border-ripple-in {
	100% {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 1;
	}
}

.btn--border-ripple-in {
	@extend %btn--ripple;

	&:before {
		top: -($btn--border-width * 2);
		right: -($btn--border-width * 2);
		bottom: -($btn--border-width * 2);
		left: -($btn--border-width * 2);
		opacity: 0;
	}

	&:hover:before,
	&:focus:before {
		animation-name: btn-border-ripple-in;
	}
}

%btn--outline {
	position: relative;
	transition: background $buttons-animation-duration, color $buttons-animation-duration;

	&:before {
		content: '';
		position: absolute;
		border-width: $btn--border-width;
		border-style: solid;
		border-color: $btn--bg;
		transition-duration: $buttons-animation-duration;
		transition-property: top, right, bottom, left, border-color;
	}

	&:hover:before,
	&:focus:before {
		border-color: $btn--bg-hover;
	}

	&:active:before {
		border-color: $btn--bg-active;
	}
}

.btn--border-outline-out {
	@extend %btn--outline;

	&:before {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&:hover:before,
	&:focus:before {
		border-color: $btn--bg-hover;
		top: -($btn--border-width + $btn--border-width-inner);
		right: -($btn--border-width + $btn--border-width-inner);
		bottom: -($btn--border-width + $btn--border-width-inner);
		left: -($btn--border-width + $btn--border-width-inner);
	}
}

.btn--border-outline-in {
	@extend %btn--outline;

	&:before {
		top: -($btn--border-width + $btn--border-width-inner) * 2;
		right: -($btn--border-width + $btn--border-width-inner) * 2;
		bottom: -($btn--border-width + $btn--border-width-inner) * 2;
		left: -($btn--border-width + $btn--border-width-inner) * 2;
		opacity: 0;
	}

	&:hover:before,
	&:focus:before {
		border-color: $btn--bg-hover;
		top: -($btn--border-width + $btn--border-width-inner);
		right: -($btn--border-width + $btn--border-width-inner);
		bottom: -($btn--border-width + $btn--border-width-inner);
		left: -($btn--border-width + $btn--border-width-inner);
		opacity: 1;
	}
}

%btn--line {
	position: relative;
	transition: background $buttons-animation-duration, color $buttons-animation-duration;

	&:before {
		content: '';
		position: absolute;
		background: $btn--border;
		height: $btn--line-width;
		transition-property: left, right, top, bottom;
		transition-duration: $buttons-animation-duration;
		transition-timing-function: ease-out;
	}
}

.btn--underline-from-center {
	@extend %btn--line;

	&:before {
		left: 50%;
		right: 50%;
		bottom: 0;
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			left: 0;
			right: 0;
		}
	}
}

.btn--underline-from-left {
	@extend %btn--line;

	&:before {
		left: 0;
		right: 100%;
		bottom: 0;
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			right: 0;
		}
	}
}

.btn--underline-from-right {
	@extend %btn--line;

	&:before {
		left: 100%;
		right: 0;
		bottom: 0;
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			left: 0;
		}
	}
}

.btn--overline-from-center {
	@extend %btn--line;

	&:before {
		left: 50%;
		right: 50%;
		top: 0;
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			left: 0;
			right: 0;
		}
	}
}

.btn--overline-from-left {
	@extend %btn--line;

	&:before {
		left: 0;
		right: 100%;
		top: 0;
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			right: 0;
		}
	}
}

.btn--overline-from-right {
	@extend %btn--line;

	&:before {
		left: 100%;
		right: 0;
		top: 0;
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			left: 0;
		}
	}
}

%btn--reveal {
	position: relative;
	transition: background $buttons-animation-duration, color $buttons-animation-duration;
	padding: $btn--padding-vertical + $btn--border-width $btn--padding-horizontal + $btn--line-width;

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		border-width: 0;
		border-style: solid;
		border-color: $btn--border;
		transition-property: border;
		transition-duration: $buttons-animation-duration;
		transition-timing-function: ease-out;
	}
}

.btn--reveal {
	@extend %btn--reveal;

	&:hover,
	&:focus,
	&:active {
		&:before {
			border-width: $btn--line-width;
		}
	}
}

.btn--reveal-top {
	@extend %btn--reveal;

	&:hover,
	&:focus,
	&:active {
		&:before {
			border-top-width: $btn--line-width;
		}
	}
}

.btn--reveal-bottom {
	@extend %btn--reveal;

	&:hover,
	&:focus,
	&:active {
		&:before {
			border-bottom-width: $btn--line-width;
		}
	}
}

.btn--grow {
	transition: background $buttons-animation-duration, color $buttons-animation-duration, transform $buttons-animation-duration;

	&:hover,
	&:focus,
	&:active {
		transform: scale(1.1);
	}
}

.btn--shrink {
	transition: background $buttons-animation-duration, color $buttons-animation-duration, transform $buttons-animation-duration;

	&:hover,
	&:focus,
	&:active {
		transform: scale(.9);
	}
}

