.l-layout-wrapper--1 {
	.browser-ie & {
		// height: 100%;
	}

	.l-layout-wrapper__header.is-fixed {
		position: static;
		top: 0;
		z-index: 10;
		width: $site-width;
	}

	.l-header-top-line {
		display: flex;
		align-items: center;
		@include rem(padding, 10px 0);
		padding: 24px 0;
		margin: 0 72px 29px;
		border-bottom: 1px solid rgba(19, 58, 96, 0.2);

		&__logo {
			display: none;
			flex-shrink: 0;
			margin-right: 12px;
		}

		&__search {
			width: calc(100%/3);
		}

		&__title {
			flex-shrink: 0;
			flex-grow: 0;
			// flex-basis: 300px;
			width: calc(100%/3);//300px;
			text-align: center;
			padding-right: 84px;
		}

		&__tools {
			display: flex;
			justify-content: flex-end;
			// flex-grow: 1;
			width: calc(100%/3);
		}
	}

	.l-header-top-nav {
		// display: flex;
		position: relative;

		&__logo {
			flex-shrink: 0;
			padding: 45px 0 55px;
			background-color: rgba(84, 164, 197, 0.15);
		}

		&__menu {
			flex-grow: 1;
			flex-shrink: 1;
			height: calc(100% - 179px);
			background: linear-gradient(204.29deg, #133A60 15.35%, #2891BC 100%);
		}

		&__burger {
			position: absolute;
			top: 50%;
			right: 15px;
			margin-top: -27px;
			transition: opacity .3s ease;
			display: none;
		}

		&__burger-link {
			font-size: 16px;
			color: $color-white;
			line-height: 17px;
			position: relative;
			text-decoration: none;
			display: block;

			&:hover,
			&:active,
			&:visited {
				color: $color-white;
			}
		}

		&__burger-icon {
			position: relative;
			border-bottom: 1px solid $color-white;
			width: 40px;
			height: 20px;
			display: block;
			margin: 0 auto 19px;

			&::before,
			&::after {
				content: '';
				position: absolute;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: $color-white;
			}

			&::before {
				top: 0;
			}

			&::after {
				top: 10px;
			}
		}
	}

	.l-page {
		padding-left: 69px;
		height: calc(100% - 108px - 77px);
		padding-bottom: 39px;
		&__nav-breadcrumbs {
			width: 100%;
			max-width: calc(100% - 90px); // минус ширина page-nav'a
		}
	}

	.l-header {

		&__inner {
			height: 100%;
		}

		.l-header-top-nav {
			height: 100%;
		}
	}

	.l-buttons-area {
		display: flex;
		align-items: center;

		// &__item + .l-buttons-area__item {
		// 	@include rem(margin-left, 16px);
		// }

		&__search {
			.search {
				width: auto;
				display: block;
			}
		}

		&__mobile-nav-panel {
			display: none;
		}

		&__buttons-panel {
			margin-left: 30px;
		}

		&__lang {
			margin-left: 29px;
		}
	}

	.l-footer {
		display: flex;
		@include rem(padding-top, 10px);
		@include rem(padding-bottom, 10px);
		border-top: 1px solid rgba(19, 58, 96, 0.2);
		padding: 22px 72px 25px 69px;

		&__social {
			display: flex;
			align-items: center;
		}

		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}

		&__nav {
			text-align: right;
		}
	}
}

@if ($responsive) {
	.l-layout-wrapper--1 {
		.l-layout-wrapper__header.is-fixed {
			width: 100%;
			max-width: $site-width;
			min-width: $site-min-width;
		}
	}

	// @include breakpoint(md) {
	@media (max-width: 1399px) {
		.l-layout-wrapper--1 {
			.l-header-top-line {
				margin: 0 24px 29px 27px;
				padding: 14px 0;

				&__logo {
					display: block;
				}

				&__title {
					padding: 0;
				}
			}

			.l-header-top-nav {
				&__logo {
					padding: 15px 0;
				}

				&__menu {
					height: calc(100% - 57px);
					pointer-events: none;
					opacity: 0;
					transform: translate(-100%, -20px);
					transition: opacity .3s ease, transform .3s ease;
					margin-right: 1px;
				}

				&__burger {
					display: block;
				}
			}

			.l-page {
				padding-left: 24px;
				padding-right: 24px;
				height: calc(100% - 88px - 59px);
				padding-bottom: 39px;
			}

			.l-footer {
				padding: 14px 24px 15px 24px;
			}

			// .l-header-top-nav__logo {
			// 	display: none;
			// }
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
			flex-direction: column;
			justify-content: space-between;
			.l-footer__copyright {
				@include rem(margin-bottom, 10px);
			}

			.l-layout-wrapper {
				flex-direction: column;
				&__header {
					position: static;
					max-width: 100%;
					width: 100%;
				}

				&__content {
					height: calc(100% - 57px);
					flex: 1 1 auto;
					display: flex;
					flex-direction: column;
				}
			}

			.l-header-top-nav {
				&__menu {}
			}

			.l-page {
				height: 100%;
				flex-direction: column;
				padding-bottom: 39px;
			}

			.l-header {
				display: flex;
				padding-right: 18px;
				margin-bottom: 30px;
				background: #133A60;
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12);
				transform: none;

				&__inner {
					display: flex;
				}

				.l-header-top-line {
					display: flex;
				}
			}

			.l-page__sidebar {
				flex-basis: auto;
				width: auto;
			}

			.l-page__nav-breadcrumbs {
				width: 100%;
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-header .l-buttons-area {
				&__search {
					display: none;
				}
			}

			.l-header-top-line {
				padding: 0;
				margin: 0;
				border: 0;
				width: calc(100% - 72px);
				justify-content: space-between;
				display: none;

				&__title {
					flex: 1 1 auto;
					padding-right: 22px;
				}

				&__tools {
					width: auto;
				}
			}

			// .l-header-top-line__title {
			// 	display: none;
			// }

			.l-header-top-nav {

				&__logo {
					width: 72px;
					padding: 15px 0 14px;
				}
				&__menu,
				&__burger {
					display: none;
				}
			}

			.l-buttons-area__mobile-nav-panel {
				display: block;
			}

			.l-buttons-area {
				&__lang {
					margin-left: auto;
				}

				&__buttons-panel {
					margin-left: 16px;
				}
			}

			.l-footer {
				padding: 28px 27px 15px 27px;

				&__inner {
					flex-direction: column;
				}

				&__nav {
					display: none;
				}

				&__social {
					margin-bottom: 11px;
				}

				&__copyright {
					margin-bottom: 20px;
				}
			}

			.l-page__sidebar {
				width: 100%;
				flex-basis: 100%;
				min-width: 100%;
			}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-page__nav {
				flex-direction: column;
				align-items: flex-start;
			}

			.l-page__nav-breadcrumbs {
				max-width: 100%;
				@include rem(margin-bottom, 10px);
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}
		}
	}
}


.body--search,
.body--history,
.body--report,
.body--sitemap,
.body--analysis,
.body--downloads {
	.l-page__title {
		display: none;
	}
}
