$search-form--height: 30px;

.b-search {
	&.b-search--compact {
		position: relative;
		@include rem(height, $search-form--height);

		.b-search__container {
			display: flex;
		}

		.b-search__label {
			position: absolute;
			color: $search--label-color;
			top: 0;
			@include rem(line-height, $search-form--height);
			@include rem(left, 5px);
			cursor: text;
			z-index: 1;
			font-size: 16px;
		}

		.b-search__label--outside {
			position: relative;
			height: 100%;
			left: 0;
			@include rem(width, 50px);
		}

		.b-search__wrapper-input {
			position: relative;
			// @include rem(width, 150px);
			transition: width 0.3s ease;
		}


		.b-search__input-container {
			@include rem(margin-right, 1px);
		}

		.b-search__input {
			width: 100%;
			background-color: transparent;
			border: 0;
			font-size: 16px;
			color: $regal-blue;
			height: 30px;

			&::-ms-clear {
				display: none;
			}
		}

		.b-search__input:hover + .b-search__label {
			color: $search--label-color-hover;
		}

		.b-search__button {
			height: 26px;
			width: 26px;
			padding: 0;
			margin: 2px 15px 0 -3px;
			color: $search--btn-icon;
			font-size: 0;
			background-color: transparent;
			@include svg(100%, 100%, "/images/icons/search.svg");


			&:hover,
			&:focus,
			&.is-expand {
				color: $search--btn-color-hover;
				@include svg(100%, 100%, "/images/icons/search-hover.svg");
			}

			&:active {
				color: $search--btn-color-active;
			}
		}

		.b-search__clear {
			width: 32px;
			height: 32px;
			position: relative;
			display: none;

			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 15px;
				content: ' ';
				height: 32px;
				width: 1px;
				background-color: $regal-blue;
			}
			&::before {
				transform: rotate(45deg);
			}
			&::after {
				transform: rotate(-45deg);
			}

			&:hover {
				&::before,
				&::after {
					background-color: $navy-blue;
				}
			}

			&:active {
				&::before,
				&::after {
					background-color: $jordy-blue;
				}
			}
		}

		&.is-expand {
			.b-search__wrapper-input {
				// width: 400px;
			}
		}
	}

	&.searching {
		.b-search__button {
			display: none;
		}

		.b-search__clear {
			display: block;
		}
	}
}
