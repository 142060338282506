$my-report_base--item-height: $input-height;
$my-report_base--item-bg: $color-celeste;
$my-report_base--item-move-bg: $color-gray;
$my-report_base--checkbox-icon: $color-celeste;
$my-report_base--checkbox-bg: $color-light-gray;
$my-report_base--checkbox-bg-checked: $color-white;
$my-report_base--checkbox-bg-hover: $color-dark-gray;
$my-report_base--link-color: $link-default-color;
$my-report_base--select-all: $base-text-color;


.l-my-report {
	&__mockcup {}

	&__content {
		.b-my-report {
			display: none;
		}
	}
}

.b-my-report-mockup {
	width: 100%;

	&__inner {
		display: inline-flex;
		max-width: 450px;
		align-items: center;
	}

	&__message {
		color: $history-text;
		@include font(300, 'Roboto');
		font-size: 18px;
		line-height: 26px;
		margin-bottom: 9px;
	}
	&__link {
		color: $history-link;
		font-size: 18px;
		line-height: 26px;
		text-decoration-line: underline;
	}
}

.b-my-report {
	&__inner {
		max-width: 972px;
	}

	&__rows {
		margin-bottom: 24px;
	}

	&__row {
		line-height: 1.3;
		text-align: left;
		padding: 0 0 11px 12px;
		margin-bottom: 9px;
		@include rem(font-size, 18px);
		display: flex;
		align-items: center;
		border-bottom: 1px solid $my-report-border-item;

		&:first-child {
			border-bottom: 2px solid $my-report-border-title;
			padding-top: 5px;
			padding-bottom: 7px;
			margin-bottom: 9px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__row-action + &__row-content {
		padding-left: 12px;
	}

	&__row-content {
		font-size: 18px;
		line-height: 25px;
		color: $my-report-text;
	}

	&__row-content + &__row-action {
		@include rem(padding-left, 15px);
	}

	&__btn-group {
		@include rem(margin-top, 15px);
		display: inline-flex;
		flex-wrap: wrap;
	}

	&__btn-wrapper {
		margin-right: 8px;
	}

	&__btn {
		width: 9em;
		background-color: $my-report-button;
		transition: opacity 0s;
		padding: 12px 0;
		text-align: center;
	}
}

.sortable-ghost {
	background-color: $my-report_base--item-move-bg;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}


@if ($responsive) {
	@include breakpoint(sm) {
		.b-my-report {
			&__btn-group {
				width: 100%;
			}

			&__btn-wrapper {
				//flex-basis: 50%;
				//margin-right: 0;
				@include rem(margin-bottom, 15px);
			}
		}
	}

	@include breakpoint(xs) {
		.b-my-report {
			&__btn-wrapper {
				//flex-basis: 100%;
			}
		}
	}
}
