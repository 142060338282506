.note {
	display: block;
	font-size: 14px;
	color: $key-indicator-text;
	line-height: 20px;
	@include font(400, 'Roboto');
	font-style: italic;
	position: relative;
	padding-left: 21px;

	div {
		position: relative;
	}

	&__marker {
		@include font(700, 'Roboto');
		font-style: normal;
		font-size: 11px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		color: $color-white;
		background-color: $navy-blue;
		width: 12px;
		height: 12px;
		position: absolute;
		top: 4px;
    	left: -18px;
		line-height: 11px;
	}
}
