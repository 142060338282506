.b-note-frame {
	display: inline;
	@include rem(border-bottom-width, 1px);
	border-bottom-style: dashed;
	transition: border-bottom 0.2s ease, color 0.2s ease;

	&:hover,
	&.is-hover {
		color: $link-hover-color;
		border-bottom-color: transparent;
		cursor: help;
	}
}

.b-note-frame-content {
	position: relative;

	&__inner {
		display: table;
		table-layout: fixed;
		width: 100%;
	}

	&__image {
		display: table-cell;
		vertical-align: top;
		width: 40%;
		padding-right: 20px;
	}

	&__content {
		display: table-cell;
		vertical-align: top;
	}
}

.b-note-frame-content--extra-content {
	.b-note-frame-content__inner {
		display: block;
		max-height: 380px;
		overflow: hidden;
		padding-bottom: 20px;
	}

	.b-note-frame-content__border {
		position: absolute;
		width: 100%;
		bottom: 0;
		height: 40px;

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+43 */
		background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 43%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 43%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 43%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

		.b-link-icon {
			position: absolute;
			bottom: 0;
			right: 0;
			justify-content: flex-end;
			width: 50%;
		}
	}
}

.tooltipster-sidetip.tooltipster-note-frames {
	.tooltipster-box {
		border: none;
		border-radius: 5px;
		background: white;
		box-shadow: 0 0 10px 6px rgba(0,0,0,0.2);
	}

	&.tooltipster-bottom .tooltipster-box {
		margin-top: 6px;
	}

	&.tooltipster-left .tooltipster-box {
		margin-right: 6px;
	}

	&.tooltipster-right .tooltipster-box {
		margin-left: 6px;
	}

	&.tooltipster-top .tooltipster-box {
		margin-bottom: 6px;
	}

	&.tooltipster-left .tooltipster-arrow,
	&.tooltipster-right .tooltipster-arrow {
		height: 12px;
		margin-left: 0;
		margin-top: -6px;
		width: 6px;
	}

	& .tooltipster-arrow-background {
		display: none;
	}

	& .tooltipster-arrow-border {
		border: 6px solid transparent;
	}

	&.tooltipster-bottom .tooltipster-arrow-border {
		border-bottom-color: white;
	}

	&.tooltipster-left .tooltipster-arrow-border {
		border-left-color: white;
	}

	&.tooltipster-right .tooltipster-arrow-border {
		border-right-color: white;
	}

	&.tooltipster-top .tooltipster-arrow-border {
		border-top-color: white;
	}

	&.tooltipster-bottom .tooltipster-arrow-uncropped {
		top: -6px;
	}

	&.tooltipster-right .tooltipster-arrow-uncropped {
		left: -6px;
	}

	& .tooltipster-content {
		color: #8d8d8d;
		padding: 15px 15px;


		img {
			max-width: 100%;
		}

		.b-table {
			margin-bottom: 0;
		}
	}


	& .tooltipster-arrow {
		height: 6px;
		margin-left: -6px;
		width: 12px;
	}
}
