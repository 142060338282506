.b-appeal--variant-1 {
	display: flex;

	.b-appeal {
		&__heading {
			display: block;
		}

		&__photo {
			@include rem(margin-bottom, 20px);
			text-align: center;

			img {
				max-width: 100%;
			}
		}

		&__signature {
			min-height: 0;
		}

		&__info {
			width: 30%;
		}

		&__content {
			width: 70%;
			padding-left: 15px;
		}

		&__post {
			@include rem(padding-bottom, 20px);
			display: block;
		}

		&__name {
			display: inline-block;
			width: 60%;
			@include rem(margin-bottom, 7px);
		}
	}
}

@if ($responsive) {
	@include breakpoint(xs) {
		.b-appeal--variant-1 {
			flex-direction: column;

			.b-appeal__info {
				width: 100%;
			}

			.b-appeal__content {
				width: 100%;
				@include rem(padding-top, 15px);
				padding-left: 0;
			}
		}
	}
}
