$appeal--content-bg: $color-light-gray;
$appeal--text-color: $color-ironside-gray;
$appeal--h1-color: $color-ironside-gray;
$appeal--quote-color: $color-ironside-gray;
$appeal--border-color: $color-black;

@if ($contrast) {
	$appeal--content-bg: $color-dark-jungle;
	$appeal--text-color: $color-white;
	$appeal--h1-color: $color-light-gray;
	$appeal--quote-color: $color-light-gray;
	$appeal--border-color: $color-white;
}

.b-appeal {
	&__heading {
		@include font-size(16px);
		font-weight: bold;
		@include rem(padding-bottom, 10px);
	}

	&__post {
		@include font-size(12px);
	}

	&__personal-attribute {
		display: flex;
		flex-wrap: wrap;
		@include font-size(14px);
		font-weight: bold;
	}
}
