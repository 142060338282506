.b-glossary--static {
	.b-glossary {
		&__term {
			font-weight: 600;
			display: inline;

			&:after {
				content: ' - ';
			}
		}

		&__value {
			display: inline;
		}

		&__item + &__item {
			@include rem(padding-top, 10px);
		}
	}
}
