.b-noty--old-browser {
	.b-noty__body {
		padding: 30px 40px 11px;
	}
}

// костыль
.b-noty__close {
	display: block;
}
