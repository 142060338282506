.b-download-center--page {
	
}

@include breakpoint(xs) {
	.b-download-center--page {
		.b-download-center__btn {
			display: flex;
			width: 190px;
			margin-left: 0;
			margin-right: 0;
		}
		
		.b-download-center__btn + .b-download-center__btn {
			margin-top: 10px;
		}
	}
}
