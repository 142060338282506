.body--main-page {
	.content-area {
		height: 100%;
	}

	.l-layout-wrapper--1 {
		.l-layout-wrapper__content {
			display: block;
			margin: 0;
			width: 100%;
			height: auto;
		}

		.l-page,
		.l-page__content {
			height: 100%;
			padding: 0;
		}

		.l-header-top-line__logo {
			display: none;
		}

		@include breakpoint(sm) {
			.l-layout-wrapper__content {
				position: relative;
			}

			.l-header-top-line__burger {
				display: none;
			}

			.l-page {
				position: absolute;
				width: 100%;
			}

			.l-header {
				margin-bottom: 20px;
			}

			.mp__title {
				font-size: 5vw;
				margin-bottom: 0.4em;
			}
		}

		@include breakpoint(xs) {
			#tooltip {
				width: 30px;
				height: 30px;

				.image {
					width: 12px;
					height: 12px;
				}

				.tip {
					width: 280px;
					top: 15px;
					left: 15px;
					padding: 20px 15px 15px 15px;

					&__title {
						font-size: 18px;
					}

					&__text {
						font-size: 15px;
					}
				}
			}
		}

		@media (min-width: 799px) {
			overflow-x: visible;

			.b-report-title {
				color: #133A60;
			}

			.l-header-top-nav__burger {
				display: none;
			}

			.l-header-top-line__burger {
				margin-right: 40px;
			}

			.l-layout-wrapper__page {
				width: 100%;
				height: calc(100% - 89px);

				.browser-ie & {
					height: calc(100vh - 89px);
				}
			}

			.l-header-top-line {
				justify-content: flex-start;
				margin: 0 auto 30px auto;
				max-width: 1872px;
				padding: 0 16px;
				height: 60px;
				border-bottom: none;
			}

			.l-header-top-line__logo {
				svg {
					display: block;
				}
			}

			.b-logo {
				margin: 0 auto;
				width: 138px;
			}

			.l-header-top-line__main {
				display: flex;
				align-items: center;
				margin-right: auto;
				width: calc(100% / 3);
			}

			.l-header-top-line__tools {
				margin-left: auto;
			}

			.l-header-top-line__title {
				width: auto;
			}

			.l-header-top-nav__logo {
				height: 180px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.b-logo__image {
				display: block !important;
			}

			.b-logo__image--mobile {
				display: none !important;
			}

			.l-header-top-nav__burger-icon {
				margin: 0;
				border-color: #133A60;
				transition: border-color 0.2s ease;

				&:before,
				&:after {
					transition: background-color 0.2s ease;
					background-color: #133A60;
				}

				&:hover {
					border-color: #0080C8;

					&:before,
					&:after {
						background-color: #0080C8;
					}
				}
			}

			.l-header-top-line {
				margin: 0 24px 29px 27px;
				padding: 14px 0;

				&__logo {
					display: block;
				}

				&__title {
					padding: 0;
				}
			}

			.l-header-top-nav {
				&__menu {
					height: calc(100% - 57px);
					pointer-events: none;
					opacity: 0;
					transform: translate(-100%, -20px);
					transition: opacity .3s ease, transform .3s ease;
					margin-right: 1px;
				}
			}

			.l-layout-wrapper__header {
				transition: max-width .3s ease, width .3s ease, transform .3s ease;
				left: 0;
				transform: none;
				width: 400px;
				transform: translateX(-100%);

				.os-scrollbar {
					opacity: 0;
				}

				&.is-open {
					transform: translateX(0);

					.l-header-top-nav__menu {
						opacity: 1;
						transform: translate(0);
						pointer-events: auto;
					}

					.l-header-top-nav__burger {
						opacity: 0;
					}

					.b-logo {
						width: 138px;
					}

					.os-scrollbar {
						opacity: 1;
					}
				}
			}
		}
	}
}

.mp {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__title {
		color: #133A60;
		font-size: 40px;
		@include font(700);
		line-height: 1.2;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 0.75em;
	}

	&__pano {
		position: relative;
		flex-grow: 1;
		width: 100vw;
		left: 50%;
		transform: translateX(-50%);
	}
}

.controls {
	position: absolute;
	display: flex;
	bottom: 0;
	right: 0;

	&__full,
	&__auto {
		width: 30px;
		height: 30px;
		background: rgba(0, 0, 0, 0.5);
	}
}

#pano {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	canvas {
		display: block;
		max-height: 100%;

		& + div {
			top: 0;
		}
	}
}

// .fullscreen-enabled {
// 	.l-header,
// 	.l-header-top-line,
// 	.mp__title {
// 		display: none !important;
// 	}

// 	.l-page {
// 		height: 100% !important;
// 	}
// }

#tooltip {
	width: 50px;
	height: 50px;
	margin-left: -25px;
	margin-top: -25px;
	transition: opacity 0.2s;
	perspective: 500px;
}

#tooltip img {
	width: 100%;
	height: 100%;
}

#tooltip:hover {
	z-index: 3;
}

#tooltip .tip {
	position: absolute;
	left: 25px;
	top: 25px;
	width: 340px;
	background-color: white;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	padding: 25px 20px 25px 20px;
	opacity: 0;
	pointer-events: none;
	transform: rotateZ(5deg) rotateY(40deg);
	transform-origin: left center;
	transition: all 0.25s;
}

#tooltip:hover .tip {
	opacity: 1;
	pointer-events: all;
	transform: none;
	font-size: 0;
}

.tip {
	&__title {
		font-size: 20px;
		line-height: 1.15;
		color: #00589C;
	}

	&__text {
		margin-top: 0.5em;
		font-size: 16px;
		line-height: 1.2;
		color: #626261;
		margin-bottom: 0;
	}
}

#tooltip .tip img {
	font-size: 16px;
	padding: .5em;
}

#tooltip .out {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	cursor: pointer;
	transition: border .3s ease-in-out;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3), 0px 8px 8px rgba(0, 0, 0, 0.45);
		opacity: 0.65;
		transition: transform 0.2s ease, opacity 0.2s ease;
	}
}

#tooltip .in {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #FFBC45;
	padding: 10px;
	border-radius: 50%;
	line-height: 50px;
	font-size: 2em;
	font-family: cursive;
	transition: background-color .3s ease-in-out;
}

#tooltip .in .image {
	width: 16px;
	height: 16px;
	background-image: url('/images/icons/right_2.svg');
	background-size: contain;
	background-repeat: no-repeat;
}

#tooltip .out:hover {
	&:before {
		transform: scale(0.98);
		opacity: 1;
	}
}

#tooltip .out:hover .in .image {
	background-position: 0 0px;
}
