.figure-buttons {
	text-align: right;
	display: inline-flex;
	float: right;
	position: relative;

	.button + .button {
		margin-left: 17px;
	}

	.button {
		padding: 0;
		text-decoration: none;

		&--zoom {
			@include webfont-icon($webfont-icon--zoom);

			&:before {
				font-size: 26px;
				color: $graphic-buttons;
			}

			&:hover {
				&:before {
					color: $figure-hover;
				}
			}

			&:active {
				transform: none;
				&::before {
					color: $figure-active;
				}
			}
		}

		&--excel {
			&::before {
				@include svg(100%, 100%, "/images/icons/download_xls.svg");
				content: '';
				width: 45px;
				height: 19px;
			}

			&:hover {
				&:before {
					@include svg(100%, 100%, "/images/icons/download_xls.svg", $figure-hover);
				}
			}

			&:active {
				transform: none;
				&::before {
					@include svg(100%, 100%, "/images/icons/download_xls.svg", $figure-active);
				}
			}
		}
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.button--excel + .button {
			margin-left: 0;
		}
	}

	@include breakpoint(md) {
		.figure-buttons--custom {
			margin-top: 10px;
			float: none;
			width: 100%;
		}

		// возможно перенос\выпилить
		.figure-buttons .button--custom {
			@include font-size(16px);
			text-align: left;
			padding: 15px;
			position: relative;
			width: 100%;
			text-decoration: none;
			background: $cobalt;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
			border-radius: 2px;
			color: $color-white;
			padding-right: 55px;

			display: block;
			&::before {
				content: '';
				width: 20px;
				height: 20px;
				@include svg(100%, 100%, "/images/icons/download-image.svg");
			}

			&:hover,
			&:active,
			&:focus {
				background-color: $navy-blue;
				color: $color-white;
			}

			&:before {
				@include font-size(22px);
				position: absolute;
				font-size: 22px;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
			}

			& + .button {
				margin-top: 10px;
				margin-left: 0;
			}

			&--custom {
				display: block;
			}
		}
	}

	@include breakpoint(sm) {
		.figure-buttons {
			margin-top: 10px;
			float: none;
			width: 100%;
		}

		.figure-buttons .button {
			@include font-size(16px);
			text-align: left;
			padding: 15px;
			position: relative;
			width: 100%;
			text-decoration: none;
			background: $cobalt;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
			border-radius: 2px;
			color: $color-white;
			padding-right: 55px;

			&--excel {
				&::before {
					@include svg(100%, 100%, "/images/icons/download-table.svg");
				}
			}

			&--image {
				display: block;
				&::before {
					@include svg(100%, 100%, "/images/icons/download-image.svg");
				}
			}

			&:hover,
			&:active {
				background-color: $navy-blue;
			}

			&:before {
				@include font-size(22px);
				position: absolute;
				font-size: 22px;
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
			}

			& + .button {
				margin-top: 10px;
				margin-left: 0;
			}
		}
	}
}
