.body--overview {
	.l-layout-wrapper {
		background-color: $color-white;
	}

	.l-layout-wrapper--1 {
		.l-page {
			height: calc(100% - 108px - 50px);
		}

		.l-header-top-line {
			margin-bottom: 0;
		}
	}

	.l-page__nav {
		display: none;
	}

	.col--row {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.col--grow {
		flex-grow: 1;
		flex-basis: 100%;
		width: 100%;
	}

	.row--margin {
		margin-top: -70px;
		margin-bottom: 1rem;
	}

	.l-page__title {
		display: inline-block;
		vertical-align: top;
		margin-right: 5%;
	}

	// .row--fix {
	// 	padding-left: 3%;
	// 	padding-right: 3%;
	// }
	//

	.b-subtitle-bordered {
		position: relative;
		flex-grow: 1;
		width: 100%;
		min-height: 140px;

		color: $regal-blue;
		padding-top: 50px;
		// padding-bottom: 40px;
		border-left: 1px solid currentColor;
		// border-right: 1px solid currentColor;
		margin-bottom: 1rem;

		h5 {
			border-bottom: 4px solid $regal-blue;
			margin: 0;
			padding-left: 10px;
			color: $storm-grey;
			// text-transform: uppercase;
			position: relative;
		}

		&:before {
			content: '';
			position: absolute;
			width: 1px;
			// height: 20px;
			background: $regal-blue;
			left: -1px;
			bottom: 100%;
		}
	}

	.b-subtitle-bordered--1 {
		flex-basis: 60%;
		max-width: 60%;

		&:before {
			height: 20px;
		}
	}

	.b-subtitle-bordered--2 {
		flex-basis: 40%;
		max-width: 40%;

		&:before {
			height: 10px;
		}

		h5 {
			top: -20px;
		}
	}

	.b-subtitle-bordered--3 {
		&:before {
			height: 30px;
		}

	}

	.b-subtitle-bordered--4 {
		&:before {
			height: 20px;
		}

		h5 {
			// padding-left: 90px;
			top: -44px;
		}
	}
	//
	.b-overview {
		&__wrapper {
			position: relative;
		}

		&__content-top {
			position: absolute;
			top: 20px;
			left: 0;
			width: 100%;

			.inset {
				position: absolute;
				top: 0;
				right: -80px;
				color: $color-white;
				background-color: $navy-blue;
				width: 280px;
				padding: 20px 70px 30px 20px;

				figcaption {
					@include font-size(30px);
					line-height: 100%;
					margin-bottom: 1rem;
				}
			}
		}

		&__subtitle {
			@include font-size(16px);
			margin-bottom: 1rem;
			color: $navy-blue;
			font-weight: bold;
		}

		&__content-block {
			&,
			p,
			ul li {
				@include font-size(14px);
			}
		}

		&__list {
			& > li:before {
				top: 5px;
			}
		}

		&__content-mid {
			position: relative;
			top: -90px;
			margin-left: -30px;
			margin-right: -30px;

			// !
			& > .row {
				align-items: flex-start;
			}
		}

		&__img {
			margin-left: -100px;
			margin-right: -100px;
		}

		&__numbers {
			display: flex;
			margin-bottom: 1rem;

			.num {
				color: $navy-blue;
				@include font-size(20px);
				font-weight: bold;
				@include rem(line-height, 42px);

				span {
					@include font-size(42px);
				}
			}

			p {
				display: inline-block;
				color: $storm-grey;
				padding-left: 0.5rem;
				@include font-size(12px);
				@include rem(line-height, 14px);
				margin-bottom: 0;
				font-weight: bold;
			}
		}

		&__icons {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;
			padding-left: 15px;
			padding-right: 15px;

			[data-svg] {
				margin-bottom: 1rem;
				max-width: 130px;
			}

			p {
				@include rem(line-height, 14px);
			}
		}

		&__icon {
			flex-basis: 150px;
			padding-left: 15px;
			padding-right: 15px;
		}

		&__txt {
			color: $cobalt;
			display: inline-block;
			width: 100%;
			max-width: 370px;
			// margin-left: 5%;
			// margin-left: 100px;

			& > span {
				font-weight: bold;
			}
		}
	}
	//
	@media (max-width: 1399px) {
		.row--fix {
			padding-left: 3%;
			padding-right: 3%;
		}

		.l-layout-wrapper--1 {
			.l-page {
				height: calc(100% - 108px - 10px);
			}
		}
	}

	@include breakpoint(lg) {
		.b-overview {
			&__txt {
				display: block;
				max-width: calc(100% - 280px);
			}

			&__content-top {
				position: relative;
				top: 0;
				margin-bottom: 2rem;
			}

			&__content-mid {
				top: -70px;
				margin-left: 0;
				margin-right: 0;
			}

			&__numbers {
				.num {
					@include font-size(36px);
					line-height: 100%;

					span {
						@include font-size(72px);
					}
				}

				p {
					@include font-size(16px);
				}
			}
		}
	}

	@include breakpoint(sm) {
		.b-subtitle-bordered {
			&:before {
				content: none;
			}
		}

		.b-overview {
			&__img {
				margin-left: -50px;
				margin-right: -50px;
			}

			&__txt {
				max-width: 100%;
				margin-bottom: 1rem;
			}

			&__content-top {
				.inset {
					position: static;
					width: 100%;
				}
			}

			&__content-mid {
				top: -70px;
				margin-left: 0;
				margin-right: 0;
			}
			//
			&__numbers {
				.num {
					@include font-size(20px);
					@include rem(line-height, 42px);

					span {
						@include font-size(42px);
					}
				}

				p {
					@include font-size(12px);
					@include rem(line-height, 14px);
				}
			}

			&__icons {
				// костыль
				.col-lg-offset-1 {
					margin-left: 0;
				}
			}
		}
	}

	@include breakpoint(xs) {
		.b-overview__content-mid {
			top: -20px;
		}
	}
}
