$custom-links--color: $regal-blue;
$custom-links--color-hover: $navy-blue;
$custom-links--color-active: $jordy-blue;

$custom-links--tooltip-bg: $color-taupe-gray;
$custom-links--tooltip-color: $color-white;

$custom-links--menu-color: $color-white;
$custom-links--menu-color-hover: $color-mountain-mist;
$custom-links--menu-color-active: $color-tapa-gray;
$custom-links--menu-bg: $color-tundora;


.b-custom-links {

	.b-tooltip {
		border-bottom: none;
	}

	&__link {
		position: relative;
		color: $custom-links--color;
		// text-decoration: none;
		font-size: 12px;
		@include font(400, 'Roboto');

		&:focus,
		&:visited {
			color: $custom-links--color;
		}

		&:hover,
		&:focus,
		&:visited:hover {
			color: $custom-links--color-hover;
		}

		&:active,
		&:visited:active {
			color: $custom-links--color-active;
		}
	}

	&__link--static {
		color: $custom-links--color;
		text-decoration: none;
	}
}


.b-custom-links--icons {
	.b-custom-links__link-icon {
		position: relative;
		display: inline-block;
	}
}

.b-custom-links--columns {
	.b-custom-links__list {
		display: inline-block;
		vertical-align: top;
	}
}

.b-custom-links--inline {
	.b-custom-links__item {
		display: inline-block;
		@include rem(padding-left, 14px);
	}
}

.b-custom-links--footer {
	.b-custom-links__link-icon {
		@include rem(top, 3px);

		&:before {
			@include font-size(18px);
		}
	}

	.b-custom-links__link--contacts {
		.b-custom-links__link-icon {
			@include webfont-icon($webfont-icon--phone);
		}

	}

	.b-custom-links__link--apps {
		.b-custom-links__link-icon {
			@include webfont-icon($webfont-icon--file-word);
		}
	}

	.b-custom-links__link--sitemap {
		.b-custom-links__link-icon {
			@include webfont-icon($webfont-icon--tree);
		}
	}

	.b-custom-links__link--goto {
		.b-custom-links__link-icon {
			@include webfont-icon($webfont-icon--external-link);
		}
	}
}

// Стили для ссылок в варианте меню menu-content
.b-custom-links--menu-horizontal-content {
	width: 100%;
	background-color: $custom-links--menu-bg;
	@include rem(padding, 5px 5px 5px 10px);

	.b-custom-links__link {
		position: relative;
		color: $custom-links--menu-color;
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		@include rem(padding-left, 30px);

		&:focus {
			color: $custom-links--menu-color;
		}

		&:hover,
		&:visited:hover {
			color: $custom-links--menu-color-hover;
		}

		&:active,
		&:visited:active {
			color: $custom-links--menu-color-active;
		}

		.b-custom-links__link-text {
			display: inline-block;
			text-decoration: underline;
		}

		.b-custom-links__link-icon {
			position: absolute;
			display: block;
			left: 0;

			&:before {
				@include font-size(18px);
			}
		}
	}

	.b-custom-links__link--static {
		.b-custom-links__link-text {
			color: $custom-links--color;
			text-decoration: none;
		}
	}

	.b-custom-links__link--questions-answers {
		.b-custom-links__link-icon {
			@include rem(top, 6px);
			@include webfont-icon($webfont-icon--clipboard);
		}
	}

	.b-custom-links__link--events {
		.b-custom-links__link-icon {
			top: 0;
			@include webfont-icon($webfont-icon--file-excel);
		}
	}

	.b-custom-links__link--sitemap {
		.b-custom-links__link-icon {
			@include rem(top, -1px);
			@include webfont-icon($webfont-icon--tree);
		}
	}
}

@if ($responsive) {
	@include breakpoint(md) {
		.b-custom-links {
			&__list {
				display: flex;
			}

			&__link {
				font-size: 12px;
			}
			.b-custom-links__item {
				display: block;
				padding-left: 5px;
			}
		}

		.b-custom-links--footer {
			.b-custom-links__link {
				line-height: 1;
			}
		}

		.b-custom-links--menu-horizontal-content {
			.b-custom-links__item {
				@include rem(padding-top, 5px);
			}
		}
	}
}
