$mobile-menu_vertical-accordeon--font-size: 15px;
$mobile-menu_vertical-accordeon--button-width: 30px;               // The width of the button area
$mobile-menu_vertical-accordeon--button-height: 30px;                   // The height of the button area
$mobile-menu_vertical-accordeon--bar-thickness: 1px;                    // The thickness of the button bars
$mobile-menu_vertical-accordeon--bar-space: 1px;                 // The spacing between button bars
$mobile-menu_vertical-accordeon--transistion-duration: 0.3s;     // The transition duration
$mobile-menu_vertical-accordeon--bar-width: 32px;

$mobile-menu_vertical-accordeon--link-color: rgba(255, 255, 255, 0.5);
$mobile-menu_vertical-accordeon--link-color-hover: rgba(255, 255, 255, 0.5);
$mobile-menu_vertical-accordeon--link-color-active: rgba(255, 255, 255, 0.5);
$mobile-menu_vertical-accordeon--controller-color: $base-text-color;
$mobile-menu_vertical-accordeon--controller-color-hover: $color-black;
$mobile-menu_vertical-accordeon--controller-color-active: orange;
$mobile-menu_vertical-accordeon--button-bg: transparent;
$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
$mobile-menu_vertical-accordeon--button-bg-active: transparent;
$mobile-menu_vertical-accordeon--button-color: $color-white;
$mobile-menu_vertical-accordeon--button-color-hover: $color-white;
$mobile-menu_vertical-accordeon--button-color-active: $color-white;

.b-mobile-menu.b-mobile-menu--vertical-accordion {
	@include rem(width, $mobile-menu_vertical-accordeon--button-width);
	@include rem(height, $mobile-menu_vertical-accordeon--button-height);

	.b-mobile-menu__burger {
		display: block;
		z-index: 10;
		position: relative;
		background-color: $mobile-menu_vertical-accordeon--button-bg;
		@include rem(width, $mobile-menu_vertical-accordeon--button-width);
		@include rem(height, $mobile-menu_vertical-accordeon--button-height);

		&:hover,
		&:focus {
			background-color: $mobile-menu_vertical-accordeon--button-bg-hover;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-hover;
			}
		}

		&:active {
			background-color: $mobile-menu_vertical-accordeon--button-bg-active;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-active;
			}
		}

		.b-mobile-menu__burger-ingredients {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: $mobile-menu_vertical-accordeon--bar-thickness;
			width: $mobile-menu_vertical-accordeon--bar-width;
			margin: auto;

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: $mobile-menu_vertical-accordeon--bar-thickness;
				background-color: $mobile-menu_vertical-accordeon--button-color;
				transition-duration: $mobile-menu_vertical-accordeon--transistion-duration, $mobile-menu_vertical-accordeon--transistion-duration;
				transition-delay: $mobile-menu_vertical-accordeon--transistion-duration, 0s;
				will-change: transform;
			}

			&:before {
				top: -10px;
				/* autoprefixer: off */
				transition-property: top, transform;
			}

			&:after {
				bottom: -10px;
				/* autoprefixer: off */
				transition-property: bottom, transform;
			}
		}

		.b-mobile-menu__burger-ingredients-middle {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: $mobile-menu_vertical-accordeon--button-color;
			transition: opacity 0s $mobile-menu_vertical-accordeon--transistion-duration;
			will-change: transform;
		}

		&.is-expand {
			position: fixed;
			z-index: 500;
			background: none;

			.b-mobile-menu__burger-ingredients-middle {
				opacity: 0;
			}

			.b-mobile-menu__burger-ingredients {
				&:before,
				&:after {
					transition-delay: 0s, $mobile-menu_vertical-accordeon--transistion-duration;
				}

				&:before {
					top: 0;
					transform: rotate(45deg);
				}

				&:after {
					bottom: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.b-mobile-menu__panel {
		display: none;
		position: fixed;
		height: calc(100% - 56px);
		width: 100%;
		left: 0;
		top: 56px;
		z-index: 100;
		padding-top: 33px;
		overflow: hidden;
		background: linear-gradient(200.97deg, #133A60 15.35%, #2891BC 100%);

		&::before {
			content: '';
			position: absolute;
			top: -19px;
			left: 0;
			width: 100%;
			height: 19px;
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12);
			z-index: 2;
		}

		&::-webkit-scrollbar {
			background: transparent;
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #555;
			background-color: rgba(0, 0, 0, 0.4);
		}

		.os-scrollbar {
			opacity: 1;
		}
	}

	.b-mobile-menu__item {
		display: block;
		vertical-align: top;
		margin-bottom: 15px;
	}

	.b-mobile-menu__item--search {
		padding: 0 20px;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;

		.search.search--inner .search__input {
			width: 100%;
		}

		.search {
			display: inline-block;
			width: 100%;
		}

		&--menu {
			width: 100%;
		}
	}

	.b-mobile-menu__item--menu {
		width: 100%;
	}

	.b-nav__list {
		.b-nav__list {
			display: none;
		}

		&.is-expand {
			display: block;
		}

		&--lvl1 {
			padding-bottom: 20px;
		}
	}

	.b-nav__item--lvl1 + .b-nav__item--lvl1 {
		margin-top: 1px;
	}

	.b-nav__link {
		display: block;
		padding-right: 25px;
		padding-top: 17px;
		padding-bottom: 17px;
		color: $mobile-menu_vertical-accordeon--link-color;
		font-size: 18px;
		text-decoration: none;
		@include font(700);
		line-height: 1;
		position: relative;

		span {
			line-height: 1.4;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			background-color: $color-white;
			width: 6px;
			height: 100%;
			opacity: 0;
			transform: translateX(-100%);
		}

		&:hover {
			color: $mobile-menu_vertical-accordeon--link-color-hover;
			text-decoration: underline;
		}

		&:active {
			color: $mobile-menu_vertical-accordeon--link-color-active;
		}

		&.current {
			color: $color-white;
			background-color: rgba(84, 164, 197, 0.15);
			&::after {
				opacity: 1;
				transform: translateX(0);
			}
		}

		&--lvl2,
		&--lvl3 {
			display: inline-block;
			@include font(400, 'Roboto');
			font-size: 16px;
			line-height: 17px;
			color: rgba(255, 255, 255, 0.7);
			padding: 10px 45px 10px 15px;

			&.current {
				background-color: transparent;
				&::after {
					display: none;
				}
			}
		}

		&--lvl3 {
			border-left: 1px solid rgba(255, 255, 255, 0.7);
			padding: 7px 45px 7px 15px;
		}
	}

	.b-nav__controller {
		position: absolute;
		@include rem(width, 25px);
		top: 8px;
		bottom: 0;
		right: 8px;

		&:before {
			content: '';
			position: absolute;
			display: block;
			@include rem(width, 10px);
			@include rem(height, 5px);
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			transition: transform 0.2s ease;
			border: 5px solid transparent;
			border-top: 5px solid rgba(255, 255, 255, 0.8);

		}

		&:before {
			transform: rotate(0deg);
		}

		&:hover,
		&:focus {
			&:before,
			&:after {
				border-top-color: $color-white;
			}
		}

		&.is-expand {
			&:before {
				border-bottom: 5px solid rgba(255, 255, 255, 0.8);
				border-top: 0;
			}
		}
	}

	.b-mobile-menu {
		&__panel-item {
			padding: 0 16px;
			&--buttons {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 21px;
			}

			&--menu {
				padding: 0;
			}
		}
	}

	.b-nav__link--lvl1 {
		color: $mobile-menu_vertical-accordeon--link-color;
		padding-left: 15px;

		&:hover {
			color: $mobile-menu_vertical-accordeon--link-color-hover;
		}

		&:active {
			color: $mobile-menu_vertical-accordeon--link-color-active;
		}
	}

	.b-nav__link-wrapper {
		position: relative;
	}

	// .b-nav__link--lvl2 {
	// 	@include rem(padding-left, 30px);
	// }

	// .b-nav__link--lvl3 {
	// 	@include rem(padding-left, 45px);
	// }

	// .b-nav__link--lvl4 {
	// 	@include rem(padding-left, 60px);
	// }

	.b-nav__item--lvl2 > .b-nav__link-wrapper {
		padding-left: 10px;
	}

	.b-nav__item--lvl3 > .b-nav__link-wrapper {
		padding-left: 38px;
	}
	// .b-nav__list--lvl1 {
	// 	margin-right: 18px;
	// }

	.b-nav__list--lvl3 {
		margin: 14px 0px 0;
	}

	.b-nav__link-wrapper {
		padding: 0;
	}
}

@include breakpoint(sm) {
	body.fixed {
		position: fixed;
		height: 100%;
		width: 100%;
	}
}
