.b-buttons-panel--in-column {
	position: relative;

	.b-buttons-panel__trigger {
		display: block;
		background-color: transparent;
		color: $buttons-panel--trigger-color;
		font-size: 0;
		padding: 0;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			display: block;
			@include font-size($buttons-panel--trigger-icon-size);
		}

		&:hover,
		&:focus {
			color: $buttons-panel--trigger-color-hover;
			background-color: transparent;
		}

		&:active,
		&.is-expand {
			color: $buttons-panel--trigger-color-active;
			background-color: transparent;
		}

		&[disabled] {
			color: $buttons-panel--trigger-color;
		}
	}

	.b-buttons-panel__trigger--tools {
		@include webfont-icon($webfont-icon--tools);
	}

	.b-buttons-panel__list {
		position: relative;
		top: 100%;
		right: -14px;
		margin-top: 19px;
		z-index: 20;
		background-color: $tools-bg;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12);
		border-radius: 2px;
	}

	.b-buttons-panel__list2 {
		display: flex;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: -18px;
			right: 18px;
			border: 10px solid transparent;
			border-bottom: 10px solid $color-white;
		}
	}

	.b-buttons-panel__item {
		margin: 0;
		padding: 0;

		&:before {
			content: none;
		}
	}

	//
	.b-buttons-panel__sublist--compare {
		top: 100%;
		text-align: center;
	}

	.b-buttons-panel__sublist-link--compare {
		display: inline-block;
		background-color: $color-white;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12);
	}
	//

	.b-buttons-panel__link {
		white-space: nowrap;
		@include rem(padding, 5px 15px 5px 45px);
		background-color: transparent;
		color: $tools-text;
		font-size: 14px;
		line-height: 15px;
		text-align: center;

		&:before {
			@include rem(left, 25px);
		}

		&:visited {
			color: $tools-text;
		}

		&:hover {
			color: $tools-text-hover;
		}

		&:active {
			color: $tools-text-active;
		}
	}

	.b-buttons-panel__sublist--share,
	.b-buttons-panel__sublist--social {
		@extend %clearfix;
		@include rem(padding, 0 10px);
	}

	.b-buttons-panel__sublist-link--compare {
		text-align: center;
	}

	.b-buttons-panel__sublist:not([data-sublist-slide-type="bottom"]) {
		position: absolute;
		padding: 0;
		top: 0;

		@for $i from 1 through 15 {
			&[data-length="#{$i}"] {
				width: $buttons-panel--sublist-link-width * $i;
			}
		}

		&[data-sublist-slide-type="left"] {
			right: 100%;
		}

		&[data-sublist-slide-type="right"] {
			left: 100%;
		}
	}
}

[lang='ru'] {
	.b-buttons-panel--in-column {
		.b-buttons-panel__sublist--compare {
			top: calc(100% + 10px);
		}
	}
}

.b-buttons-panel--in-column.b-buttons-panel--static {
	background-color: $buttons-panel--list-bg-color;
	@include rem(padding-top, $grid-gutter-width);
	@include rem(padding-bottom, $grid-gutter-width);

	.b-buttons-panel__title {
		display: block;
		@include rem(padding-left, $grid-gutter-width);
		@include rem(padding-bottom, $grid-gutter-width);
		@include font-size($buttons-panel--title-font-size);
		color: $buttons-panel--title-color;
	}

	.b-buttons-panel__link {
		white-space: normal;
	}
}

.b-buttons-panel--in-column.b-buttons-panel--compact {
	.b-buttons-panel__list {
		position: absolute;
		display: none;
	}
}

// Панель с текстом в кнопке тригера
.b-buttons-panel--in-column.b-buttons-panel--button-with-text:not(.b-buttons-panel--download-pdf) {
	@include rem(max-width, 250px);

	.b-buttons-panel__trigger-controller {
		position: relative;
		width: 20px;
		height: 20px;
		margin-left: 14px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: 14px;
			background-color: currentColor;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform .2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 13px;
			transform: rotate(-44deg);
		}

		&:after {
			right: 4px;
			transform: rotate(44deg);
		}
	}

	.b-buttons-panel__trigger {
		display: flex;
		align-items: center;
		width: 100%;
		@include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		&.is-expand .b-buttons-panel__trigger-controller {
			&:before {
				transform: rotate(44deg);
			}

			&:after {
				transform: rotate(-44deg);
			}
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
	}

	.b-buttons-panel__list {
		width: 100%;
	}
}

.b-buttons-panel--in-column.b-buttons-panel--tools {
	.b-buttons-panel__trigger {
		width: 26px;
		height: 26px;
		@include svg(100%, 100%, "/images/icons/tools.svg");

		&::before {
			content: '';
		}

		&:hover {
			@include svg(100%, 100%, "/images/icons/tools-hover.svg");
		}

		&:active {
			@include svg(100%, 100%, "/images/icons/tools-active.svg");
		}
	}

	.b-buttons-panel__list {
		right: -16px;
		margin-top: 14px;
	}

	.b-buttons-panel__list2 {
		flex-wrap: wrap;
		padding: 34px 15px 18px;
		width: 336px;
	}

	.b-buttons-panel__item {
		// width: calc(100%/3);
		margin-bottom: 24px;
		position: relative;
	}

	.b-buttons-panel__link {
		white-space: normal;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 102px;

		&:before {
			height: 48px;
			content: '';
			position: static;
			transform: none;
			margin-bottom: 7px;
			display: block;
		}

		span {
			display: flex;
			align-items: center;
			height: 30px;
			width: 100%;
			justify-content: center;
		}

		&--download {
			span {
				width: 58px;
			}
		}

		&--add,
		&--del {
			display: none;
		}

		&.is-visible {
			display: flex;
		}
	}

	@include breakpoint(sm) {
		.b-buttons-panel__trigger {
			@include svg(100%, 100%, "/images/icons/tools-mobile.svg");

			&::before {
				content: '';
			}

			&:hover {
				@include svg(100%, 100%, "/images/icons/tools-mobile.svg");
			}

			&:hover {
				@include svg(100%, 100%, "/images/icons/tools-mobile.svg");
			}
		}

		.b-buttons-panel__list2 {
			width: 320px;
		}

		.b-buttons-panel__link {
			width: 94px;
			font-size: 12px;
		}
	}
}

// Панель скачать пдф
.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
	@include rem(max-width, 160px);

	.b-buttons-panel__trigger {
		position: relative;
		color: $pdf-link;
		font-size: 16px;
		line-height: 16px;

		&::before {
			width: 13px;
			height: 14px;
			content: '';
			@include svg(100%, 100%, "/images/icons/download-pdf.svg");
		}

		&:hover {
			color: $pdf-link-hover;
			&::before {
				@include svg(100%, 100%, "/images/icons/download-pdf-hover.svg");
			}
		}

		&:hover {
			color: $pdf-link-active;
			&::before {
				@include svg(100%, 100%, "/images/icons/download-pdf-active.svg");
			}
		}
	}

	.b-buttons-panel__trigger--with-text {
		display: flex;
		align-items: center;
		// @include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		.b-buttons-panel__trigger-controller {
			display: none;
		}
	}

	.b-buttons-panel__item {
		display: inline-block;
		width: 50%;

		&:first-child {
			margin-right: 64px;
		}
	}

	.b-buttons-panel__list2 {
		padding: 39px 48px 41px;

		&::before {
			top: -18px;
			right: 18px;
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		&:before {
			content: '';
			position: static;
			transform: none;
			height: 40px;
			margin-bottom: 17px;
		}
	}

	.b-buttons-panel__link--pdf-pages {
		&::before {
			width: 26px;
			@include svg(100%, 100%, "/images/icons/page-by-page.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/page-by-page-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/page-by-page-active.svg");
			}
		}
	}

	.b-buttons-panel__link--pdf-spreads {
		&::before {
			width: 51px;
			@include svg(100%, 100%, "/images/icons/turns.svg");
		}

		&:hover {
			&::before {
				@include svg(100%, 100%, "/images/icons/turns-hover.svg");
			}
		}

		&:active {
			&::before {
				@include svg(100%, 100%, "/images/icons/turns-active.svg");
			}
		}
	}

	@include breakpoint(sm) {
		.b-buttons-panel__trigger {
			color: $hawkes-blue;
			&::before {
				width: 13px;
				height: 14px;
				content: '';
				@include svg(100%, 100%, "/images/icons/download-pdf.svg", $hawkes-blue);
			}
		}

		.b-buttons-panel__list {
			right: auto;
			left: -16px;
		}

		.b-buttons-panel__list2 {
			padding: 39px 41px 41px;
			&::before {
				right: auto;
				left: 13px;
			}
		}
	}
}

@include breakpoint(xs) {
	.b-buttons-panel--in-column {
		.b-buttons-panel__sublist--share {
			width: auto;
			height: auto;
		}

		.b-buttons-panel__sublist-link {
			float: none;
		}
	}
}
