$copyright_base--color: $regal-blue;
$copyright_base--link-color: $regal-blue;
$copyright_base--link-color-hover: $navy-blue;
$copyright_base--link-color-active: $jordy-blue;

.b-copyright {
	color: $copyright_base--color;
	font-size: 12px;

	&__name--link {
		color: $copyright_base--link-color;
		font-size: 12px;

		&:visited {
			color: $copyright_base--link-color;
		}

		&:hover,
		&:focus,
		&:visited:hover {
			color: $copyright_base--link-color-hover;
			text-decoration: underline;
		}

		&:active {
			color: $copyright_base--link-color-active;
		}
	}

	@include breakpoint(md) {
		font-size: 12px;
		&__name--link {
			font-size: 12px;
		}
	}

	@include breakpoint(sm) {
		font-size: 12px;
		&__name--link {
			font-size: 12px;
		}
	}
}
