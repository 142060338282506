$cookie-law--btn-color: $color-ironside-gray;
$cookie-law--btn-color-hover: $color-white;
$cookie-law--btn-bg: $color-celeste;
$cookie-law--btn-bg-hover: $color-tundora;
$cookie-law--btn-bg-active: $color-tundora;

.b-noty--cookie-law {
	z-index: 10;

	.b-noty__buttons {
		display: block;
	}

	.b-cookie-law__btn {
		letter-spacing: 0.0357143em;
		padding: 12px 31px;
		margin-right: 22px;

		&--also {
			padding: 12px 38px;
		}
		// @include rem(width, 156px);
		// @include rem(height, 50px);
		// color: $cookie-law--btn-color;
		// background-color: $cookie-law--btn-bg;

		// &:hover {
		// 	color: $cookie-law--btn-color-hover;
		// 	background-color: $cookie-law--btn-bg-hover;
		// }

		// &:active {
		// 	color: $cookie-law--btn-color-hover;
		// 	background-color: $cookie-law--btn-bg-active;
		// }
	}
}
