blockquote {
	position: relative;
	padding: 0px 16px 30px 80px;
	margin-bottom: 28px;

	.text {
		position: relative;
		color: $quote-text;
		padding-bottom: 23px;
		margin-bottom: 21px;
		line-height: 25px;
		@include webfont-icon($webfont-icon--quote);

		&.color-green {
			color: $mantis;
			&:before {
				color: $mantis;
			}

			&::after {
				background-color: $mantis;
			}
		}

		&.color-yellow {
			color: $my-sin;
			&:before {
				color: $my-sin;
			}

			&::after {
				background-color: $my-sin;
			}
		}

		&:before {
			position: absolute;
			font-size: 41px;
			top: 0;
			left: -80px;
			color: $quote-text
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: $quote-text;
			height: 1px;
			width: 100%;
			max-width: 256px;
		}
	}

	.person {
		margin-top: 17px;

		&__name,
		&__position,
		&__company {
			display: block;
			font-size: 18px;
			color: $quote-text;
			@include font(700, 'Roboto');
		}

		&__position {
			display: block;
			font-size: 16px;
		}

		&__company {
			display: block;
			font-size: 16px;
		}

		&.color-green {
			.person__name,
			.person__position,
			.person__company {
				color: $mantis;
			}
		}

		&.color-yellow {
			.person__name,
			.person__position,
			.person__company {
				color: $my-sin;
			}
		}
	}
}

.quote--photo {
	display: flex;
	padding: 0;

	.quote__photo {
		flex-shrink: 0;
		width: 30%;

		img {
			display: block;
		}
	}

	.quote__content {
		position: relative;
		padding: 30px 16px 30px 80px;
	}
}

@include breakpoint(sm) {
	blockquote {
		padding: 64px 16px 24px 40px;
		// padding: 50px 16px 24px 40px;

		.text {
			&:before {
				top: -50px;
				left: -40px;
			}
		}
	}

	.quote--photo {
		display: block;
		padding: 24px 16px 24px 40px;

		.quote__photo {
			width: 95%;
			margin-bottom: 25px;
		}

		.quote__content {
			position: relative;
			padding: 0;
		}
	}
}
