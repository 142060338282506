.bar {
	shape-rendering: crispEdges;
}

.chart__legend {
	margin-top: -6px;
	display: table;
}

.legend__item {
	display: table-row;
	padding-left: 5px;
	margin-top: 5px;
	border-bottom: black solid 1px;
	width: 100%;
}

.legend__pict {
	display: table-cell;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 0;
}

.legend__text {
	margin-top: 0;
	// float: left;
	display: table-cell;

	&:not(.legend__value) {
		text-align: left;
	}
}

.legend__text + .legend__text {
	border-left: silver solid 1px;
}

.legend__value {
	display: table-cell;
	text-align: right;
	width: 71px;
	padding-right: 10px;
}

.chart__line--gpn {
	stroke: silver;
	stroke-width: 1px;
	stroke-linecap: butt;
	stroke-linejoin: miter;
	shape-rendering: crispEdges;
}
