$label-height: 30px;
$extendable-input-width: 500px;
$extendable-button-width: 30px;
$extendable-button-height: 30px;

.b-search {
	&.b-search--inner {
		position: relative;
		display: inline-block;
		width: 100%;
		@include rem(height, $label-height);
		margin-bottom: 19px;

		.b-search__label {
			position: absolute;
			color: $hawkes-blue;
			margin: auto;
			top: 0;
			bottom: 0;
			height: 20px;
			left: 35px;
			cursor: text;
		}

		.b-search__input {
			display: block;
			width: 100%;
			padding-left: 35px;
			border-radius: 0;
			-webkit-appearance: none;
			color: $hawkes-blue;
			background: transparent;
			border: 0;
			font-size: 16px;
		}

		.b-search__message {
			z-index: 1;
		}

		.b-search__button {
			display: block;
			position: absolute;
			top: 7px;
			height: 100%;
			background-color: transparent;
			color: $hawkes-blue;
			overflow: hidden;
			@include rem(width, 25px);
			@include svg(100%, 100%, "/images/icons/search.svg", $hawkes-blue);
			line-height: 1;
			padding: 0;

			&:hover,
			&:focus {
				color: $search--btn-icon-hover;
			}

			&:active {
				color: $search--btn-icon-active;
			}
		}
	}

	.b-search__message {
		background-color: transparent;
		font-size: 12px;
		line-height: 16px;
		color: $red-orange;

		&::before {
			@include svg(100%, 100%, "/images/icons/error.svg");
			right: -17px;
			left: auto;
			content: '';
			width: 18px;
			height: 18px;
			border: 0;
			top: 3px;
		}
	}

	.b-search__result {
		@include rem(top, $label-height);
	}
}
