$btn--padding-vertical: 0.667em; // отступы по сверху и снизу кнопок
$btn--padding-horizontal: 0.567em; // отступы по краям кнопок

button {
	border: 0;

	&:focus {
		outline: 0;
	}
}

%button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1;
	color: $btn--color;
	border: 0;
	background-color: $btn--bg;
	cursor: pointer;
	transition: transform 0.2s ease, color 0.2s ease, background-color 0.2s ease;
	font-size: 16px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	border-radius: 2px;
	text-transform: uppercase;
	padding: 12px 39px;
	@include font(500, 'Roboto');

	&:hover,
	&:focus {
		background-color: $btn--bg-hover;
		color: $btn--color-hover;
	}

	&:focus {
		outline: 0;
	}

	&:active {
		background-color: $btn--bg-active;
		color: $btn--color-active;
		box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12);
	}
	&:visited {
		background-color: rgba(0, 88, 156, 0.8);
	}
}

button[disabled="disabled"],
button:disabled {
	@extend .btn--disabled;
}

.btn {
	@extend %button;
	// padding: $btn--padding-vertical $btn--padding-horizontal;
	text-decoration: none;

	&__text {
		position: relative;
		line-height: 1;
		z-index: 1;
	}
}

input[type="submit"] {
	@extend %button;
}

.btn--disabled {
	color: $btn--color;
	background-color: $btn--bg;
	opacity: 0.3;
	cursor: default;

	&:hover {
		color: $btn--color;
		background-color: $btn--bg;
	}
}

.btn .webfont-icon {
	margin: 0 0.4em;
}
