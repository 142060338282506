%text--body,
.text--body {
	@include font-size($base-font-size);
	@include font(400);
	font-style: normal;
}

%arial,
.arial {
	font-family: Arial, Helvetica, sans-serif;
}

%caption {
	@include font-size(16px);
	font-weight: bold;
	color: $base-text-color;
	border-bottom: 2px solid $base-text-color;

	.caption__units,
	.b-caption__units {
		font-weight: normal;
	}
}

p {
	margin-bottom: 20px;
	line-height: 25px;
	font-size: 18px;
	color: $nero;
	@include font(300);
}
