.b-search-result {
	&__item {
		padding-bottom: 21px;
		border-bottom: 1px solid $search-result-border;
		// margin-bottom: 21px;
		margin-bottom: 42px;
		margin-top: -14px;
	}

	&__title {
		@include font(700, 'Roboto');
		font-size: 22px;
		line-height: 31px;
		color: $search-result-link;
		margin-bottom: 23px;
	}

	&__link {
		font-size: 22px;
		line-height: 31px;
		color: $search-result-link;
		text-decoration: none;

		&:hover,
		&:active,
		&:visited {
			color: $search-result-link-hover;
		}
	}

	&__text {
		@include font(300, 'Roboto');
		font-size: 18px;
		line-height: 25px;
		max-width: 976px;
	}
	
	&__loading-trigger {
		width: 100%;
		
		&.is-finidhed {
			display: none;
		}
		
		&.is-loading {
			height: 40px;
		}
	}

	@include breakpoint(sm) {
		&__title {
			font-size: 18px;
			line-height: 25px;
		}
	
		&__link {
			font-size: 18px;
			line-height: 25px;
		}
	
		&__text {
			font-size: 16px;
			line-height: 20px;
		}
	}
}
