//text
$base-text-color: $color-ironside-gray;

//errors
$error-text-color: #c7191a;
$error-form-bg: #fea8a6;

//links
$link-default-color: $cobalt;
$link-hover-color: $navy-blue;
$link-active-color: $jordy-blue;
$link-visited-color: $picton-blue;

//buttons
$btn--bg: $cobalt;
$btn--bg-hover: $navy-blue;
$btn--bg-active: $jordy-blue;
$btn--color: $color-white;
$btn--color-hover: $color-white;
$btn--color-active: $color-white;


// lead
$lead-border: $jordy-blue;
$lead-bg: $hawkes-blue;
$lead-text: $regal-blue;

// graphic
$graphic-title: $storm-dust;
$graphic-caption: $navy-blue;
$graphic-buttons: $regal-blue;

// table
$table-figcaption: $storm-dust;
$table-head: $regal-blue;
$table-bg-head: rgba(230, 231, 232, 0.8);
$table-border: $storm-grey;
$table-border-head: $navy-blue;
$table-bg-hover: rgba(122, 185, 229, 0.5);
$table-bg-active: $navy-blue;
$table-bold-text: #0080C8;

// figure-button:
$figure-hover: $navy-blue;
$figure-active: $jordy-blue;


// accordion
$accordion-button: $storm-dust;
$accordion-button-hover: $regal-blue;

// tab
$tab-bg-active: $hawkes-blue;

// qoute
$quote-text: $navy-blue;

// list
$list-dot: $navy-blue;

// useful link
$useful-title: $cobalt;
$useful-link: $storm-dust;
$useful-info: $useful-link;
$useful-info-big: $regal-blue;
$useful-link-hover: $navy-blue;
$useful-link-active: $jordy-blue;

// key-indicator
$key-indicator-value: $navy-blue;
$key-indicator-border: $storm-dust;
$key-indicator-list: $storm-dust;
$key-indicator-text: $storm-dust;


// download-pdf
$pdf-link: $regal-blue;
$pdf-link-hover: $navy-blue;
$pdf-link-active: $jordy-blue;


// tools-panel
$tools-bg: $color-white;
$tools-text: $regal-blue;
$tools-text-hover: $navy-blue;
$tools-text-active: $jordy-blue;

// search-result
$search-result-text: $nero;
$search-result-link: $regal-blue;
$search-result-link-hover: $navy-blue;
$search-result-border: rgba(19, 58, 96, 0.2);

// 404
$p404-text: $regal-blue;

// history
$history-text: $nero;
$history-link: $cobalt;
$history-date: $regal-blue;

// my-report
$my-report-text: $regal-blue;
$my-report-link: $cobalt;
$my-report-border-title: $navy-blue;
$my-report-border-item: $mischka;
$my-report-button: $jordy-blue;

// sitemap
$sitemap-link: $cobalt;
$sitemap-link-hover: $navy-blue;
$sitemap-link-active: $jordy-blue;
$sitemap-border: $jordy-blue;

// form
$form-border: $navy-blue;
$forn-border-focus: $cobalt;
$form-label: rgba(98, 98, 97, 0.7);
$form-footnote: $nero;
$form-important: $navy-blue;

// download-center
$download-center-text: $cobalt;
$download-center-border-bottom: $mischka;
$download-center-border: $cobalt;
$download-center-border-hover: $storm-dust;
$download-center-bg-hover: $solitude-grey;
$download-center-bg-active: $hawkes-blue;
