.b-404 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 280px);

	&__wrapper {
		max-width: 622px;
		margin: 98px auto 0;
		padding-right: 63px;
	}

	&__image {
		margin-bottom: 46px;
	}

	&__text {
		color: $p404-text;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		max-width: 570px;
		max-width: 433px;
    	margin: 0 auto 27px;
	}

	&__link-wrap {
		color: $p404-text;
		font-size: 18px;
		line-height: 26px;
		text-align: center;

		a {
			color: $p404-text;
			font-size: 18px;
			line-height: 26px;

			&:visited {
				color: $p404-text;
				&:hover,
				&:focus {
					color: $link-hover-color;
				}

				&:active {
					color: $link-active-color;
				}
			}
		}
	}

	@include breakpoint(md) {
		height: calc(100vh - 234px);

		&__wrapper {
			padding: 0;
			margin: 0 auto;
		}
	}

	@include breakpoint(sm) {
		height: calc(100vh - 293px);

		&__wrapper {
			padding: 0;
			margin: 0 auto;
		}
	}
}