$excel--btn-color: $color-ironside-gray;
$excel--btn-color-hover: $color-black;
$excel--btn-color-active: $color-carrot-orange;

.button--excel {
	color: $excel--btn-color;
	display: inline-block;
	background-color: transparent;
	@include webfont-icon($webfont-icon--file-excel);
	font-size: 0;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

	&:before {
		font-size: 20px;
	}

	&:hover,
	&:focus {
		color: $excel--btn-color-hover;
	}

	&:active {
		color: $excel--btn-color-active;
	}
}

.button--show-only-in-mobile {
	display: none;
}

@if ($responsive) {
	@include breakpoint(sm) {
		.button--show-only-in-mobile {
			display: initial;
		}
	}
}

.download-arrow {
	position: fixed;
	z-index: 999;
	display: none;
	height: 150px;
	width: 150px;
	opacity: 0;
	@include svg(100%, 100%, '/images/icons/download-arrow.svg');
	background-repeat: no-repeat;
	background-position-x: 0;
	background-position-y: 0;
}

.download-arrow--ie8 {
	top: 10px;
	left: 20px;
	filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0) progid:DXImageTransform.Microsoft.AlphaImageLoader(src="/images/icons/download-arrow.svg'", sizingMethod="scale") progid:DXImageTransform.Microsoft.matrix(M11=1, M12=1.2246063538223773e-16, M21=-1.2246063538223773e-16, M22=-1, SizingMethod="auto expand");
}

.download-arrow--ie9 {
	bottom: 50px;
	left: 67%;
}

.download-arrow--chrome {
	bottom: 30px;
	left: 20px;
}

.download-arrow--safari {
	top: 0px;
	right: 100px;
	transform: rotateX(180deg) rotateY(180deg);
}

.download-arrow--firefox {
	top: 0px;
	right: 65px;
	transform: rotateX(180deg) rotateY(180deg);
}
