@mixin pseudo($width, $height, $content: "") {
	content: $content;
	display: block;
	position: absolute;
	width: $width;
	height: $height;
}

@mixin pseudo-rem($width, $height, $content: "") {
	content: $content;
	display: block;
	position: absolute;
	@include rem(width, $width);
	@include rem(height, $height);
}

@mixin svg-background-image($svg, $color: null, $colorType: "color") {
	// postcss-inline-svg
	// @if $color {
	// 	@if $colorType == 'color' {
	// 		background-image: svg-load($svg, fill=#{$color}, stroke=#{$color});
	// 	} @else if $color == 'fill' {
	// 		background-image: svg-load($svg, fill=#{$color});
	// 	} @else if $color == 'stroke' {
	// 		background-image: svg-load($svg, stroke=#{$color});
	// 	} @else {
	// 		background-image: svg-load($svg);
	// 	}
	// } @else {
	// 	background-image: svg-load($svg);
	// }

	@if $color {
		background-image: svg("#{$imagesFolder}#{$svg}", "[#{$colorType}]: #{$color}");
	} @else {
		background-image: svg("#{$imagesFolder}#{$svg}");
	}

	background-repeat: no-repeat;
	background-position: 50%;
}

//$colorType: What to colorify - color (fill + stroke), fill, stroke
@mixin svg($width, $height, $svg, $color: null, $colorType: "color") {
	@include svg-background-image($svg, $color, $colorType);
	background-size: $width $height;
}

@mixin svg-rem($width, $height, $svg, $color: null, $colorType: "color") {
	@include svg-background-image($svg, $color, $colorType);
	@include rem(background-size, $width $height);
}

@mixin pseudo-svg($width, $height, $svg, $color: null, $colorType: null) {
	@include pseudo($width, $height);
	@include svg($width, $height, $svg, $color, $colorType);
}

@mixin pseudo-svg-rem($width, $height, $svg, $color: null, $colorType: null) {
	@include pseudo($width, $height);
	@include svg-rem($width, $height, $svg, $color, $colorType);
}

@mixin svg-color($width, $height, $svg, $color) {
	@include svg($width, $height, $svg, $color);
}

@mixin svg-fill($width, $height, $svg, $color) {
	@include svg($width, $height, $svg, $color, "fill");
}

@mixin svg-stroke($width, $height, $svg, $color) {
	@include svg($width, $height, $svg, $color, "stroke");
}
