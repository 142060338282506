.b-lang--single {
	.b-lang__item {
		display: block;
		text-decoration: none;
		font-size: 0;
		// @extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&--ru,
		&--en {
			width: 26px;
			height: 26px;
		}

		&--ru {
			@include svg(100%, 100%, "/images/icons/language-ru.svg");

			&:hover,
			&:focus {
				@include svg(100%, 100%, "/images/icons/language-ru-hover.svg");
			}

			&:active {
				@include svg(100%, 100%, "/images/icons/language-ru-active.svg");
			}
		}

		&--en {
			@include svg(100%, 100%, "/images/icons/language-en.svg");

			&:hover,
			&:focus {
				@include svg(100%, 100%, "/images/icons/language-en-hover.svg");
			}

			&:active {
				@include svg(100%, 100%, "/images/icons/language-en-active.svg");
			}
		}
		@include breakpoint(sm) {
			&--ru {
				@include svg(100%, 100%, "/images/icons/language-ru.svg", $hawkes-blue);
	
				&:hover,
				&:focus {
					@include svg(100%, 100%, "/images/icons/language-ru-hover.svg", $hawkes-blue);
				}
	
				&:active {
					@include svg(100%, 100%, "/images/icons/language-ru-active.svg", $hawkes-blue);
				}
			}
	
			&--en {
				@include svg(100%, 100%, "/images/icons/language-en.svg", $hawkes-blue);
	
				&:hover,
				&:focus {
					@include svg(100%, 100%, "/images/icons/language-en-hover.svg", $hawkes-blue);
				}
	
				&:active {
					@include svg(100%, 100%, "/images/icons/language-en-active.svg", $hawkes-blue);
				}
			}
		}
	}
}