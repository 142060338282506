.table-chart {
	width: 400px;
	height: 200px;
}

.tooltipster-table-chart {
	$background-color: white;

	.tooltipster-box {
		border: 1px solid gray;
		margin: 5px;
		background-color: $background-color;
	}

	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: $background-color;
		top: 4px;
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: $background-color;
		left: -4px;
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: $background-color;
		left: 4px;
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: $background-color;
		top: -4px;
	}
}
