$accordion--controls-height: 40px;
// $accordion--button-bg: $color-celeste;
$accordion--button-bg-hover: $color-ironside-gray;
$accordion--button-bg-active: $hawkes-blue;
$accordion--button-text-color: $storm-dust;
$accordion--button-text-color-hover: $regal-blue;
$accordion--button-text-color-active: $regal-blue;
$accordion--article-close-hover: orange;
$accordion--control-icon-bg: $color-navy-blue;
$accordion--control-icon-color: $color-white;
$accordion--control-item-bg: $color-celeste;
$accordion--control-item-bg-hover: $color-ironside-gray;
$accordion--control-item-bg-active: $color-mine-shaft;
$accordion--control-text-color: $base-text-color;
$accordion--control-text-color-hover: $color-white;
$accordion--control-text-color-active: $color-white;
$accordion--article-close-bg: $base-text-color;
$accordion--article-close-bg-hover: $color-navy-blue;
$accordion--article-close-bg-active: $color-prussian-blue;
$accordion--button-font-size: 18px;

%control-icon {
	content: '';
	display: block;
	position: absolute;
	background-color: $accordion--control-icon-color;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	@include rem(width, 20px);
	@include rem(height, 2px);
}

.b-accordion {
	margin-bottom: 82px;

	&--download {
		margin-bottom: 0;
	}
	&__section {
		@include rem(margin-bottom, 1px);
	}
	&__header {
		&--download {
			margin-top: -1px;
			border: 1px solid $cobalt;
			&:hover {
				border-color: $download-center-border-hover;
				background-color: $download-center-bg-hover;
				.b-checkbox__label {
					border-color: $navy-blue;
				}
			}
	
			&:active {
				border-color: $cobalt;
				background-color: $download-center-bg-active;
			}
		}
	}

	&__button {
		display: block;
		position: relative;
		padding: 15px 32px 13px 17px;
		text-decoration: none;
		color: $accordion--button-text-color;
		border-top: 1px solid $mischka;
		@include font-size($accordion--button-font-size);
		cursor: pointer;
		transition: all .2s ease-in-out;

		span {
			position: absolute;
			top:0;
			left: 0;
			width: 100%;
			border-top: 2px solid transparent;
		}

		&:visited {
			color: $accordion--button-text-color;
		}
		&:focus {
			color: $accordion--button-text-color;
		}

		&:hover,
		&:focus {
			color: $accordion--button-text-color-hover;
			border-top-color: $regal-blue;

			span {
				border-top-color: $regal-blue;
			}

			&:before,
			&:after {
				background-color: $accordion--button-text-color-hover;
			}

			&:visited {
				color: $accordion--button-text-color-hover;
			}
		}

		&.is-expand,
		&:active {
			border-top: 1px solid $regal-blue;
			color: $accordion--button-text-color-active;
			background-color: $accordion--button-bg-active;

			span {
				border-top-color: $regal-blue;
			}

			&:before {
			}

			&:after {
			}

			&:visited {
				color: $accordion--button-text-color-active;
				background-color: $accordion--button-bg-active;
			}
		}

		&.is-expand {
			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			@include rem(width, 1px);
			@include rem(height, 15px);
			background-color: $regal-blue;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform 0.2s ease;
		}

		&:before {
			right: 21px;
			transform: rotate(0);

		}

		&:after {
			right: 21px;
			transform: rotate(90deg);
		}

		&--download {
			font-size: 18px;
			line-height: 25px;
			text-decoration: underline;
			color: $download-center-text;
			width: 100%;
			padding: 8px 32px 11px 38px;

			&:visited {
				color: $download-center-text;
			}
			&:focus {
				color: $download-center-text;
			}
	
			&:hover,
			&:focus {
				color: $download-center-text;
				&:visited {
					color: $download-center-text;
				}
			}

			&.is-expand,
			&:active {
				color: $download-center-text;
				border-top: 0;
				&:visited {
					color: $download-center-text;
				}
			}
		}
	}

	&__article {
		position: relative;
		margin-top: 0;
		padding: 18px 25px 9px 40px;
		display: none;

		p {
			color: $nero;
			@include font(300, 'Roboto');
			max-width: 896px;
		}

		&.is-expand {
			display: block;

			.b-accordion__article-close {
				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}

	&__article-close {
		position: absolute;
		display: block;
		@include rem(width, 32px);
		@include rem(height, 32px);
		@include rem(right, 10px);
		background: transparent;
		bottom: 0px;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			@include rem(width, 3px);
			@include rem(height, 15px);
			background-color: $accordion--button-text-color;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform 0.2s ease;
			border-radius: 5px;
		}

		&:before {
			@include rem(right, 19px);
			transform: rotate(-45deg);

		}

		&:after {
			@include rem(right, 10px);
			transform: rotate(45deg);
		}

		&:hover,
		&:focus {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-hover;
			}

			&:after {
				background-color: $accordion--article-close-bg-hover;
			}
		}

		&:active {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-active;
			}

			&:after {
				background-color: $accordion--article-close-bg-active;
			}
		}

		&.is-hidden {
			display: none;
		}
	}

	&__control {
		@include rem(padding-top, 10px);
	}

	&__control-item {
		display: inline-block;
		@include rem(height, $accordion--controls-height);
		@include rem(line-height, $accordion--controls-height);
		@include rem(margin-bottom, 10px);
		// background-color: $accordion--button-bg;
		color: $accordion--control-text-color;
		padding: 0;
		cursor: pointer;

		&:first-child {
			@include rem(margin-right, 10px);
		}

		&:hover {
			background-color: $accordion--control-item-bg-hover;
			color: $accordion--control-text-color-hover;
		}

		&:active {
			background-color: $accordion--control-item-bg-active;
			color: $accordion--control-text-color-active;
		}
	}

	&__control-icon {
		display: block;
		position: relative;
		height: 100%;
		@include rem(width, $accordion--controls-height);
		float: left;
		background-color: $accordion--control-icon-bg;
	}

	&__control-text {
		@include rem(padding-left, 15px);
		@include rem(padding-right, 15px);
	}

	&__control-item--expand {
		.b-accordion__control-icon {
			&:before,
			&:after {
				@extend %control-icon;
			}

			&:before {
				transform: rotate(0deg);
			}

			&:after {
				transform: rotate(90deg);
			}
		}
	}

	&__control-item--collapse {
		.b-accordion__control-icon:before {
			@extend %control-icon;
		}
	}
}
