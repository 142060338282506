.b-statements {
	&__wrapper {
		&:before {
			content: "";
			height: 15px;
			width: 100%;
			position: absolute;
			bottom: -10px;
			background-color: #F9B234;
		}
		position: relative;
		display: flex;
		@include breakpoint(sm) {
			flex-direction: column;
			&--reverse {
				flex-direction: column-reverse;
			}
		}

		.b-statements__image {
			width: 1800px;
			@include breakpoint(sm) {
				width: 100%;
				margin-bottom: 30px;
				&--chairman {
					margin-bottom: 0px;
				}
			}

		}

		.b-statements__text {
			p {
				margin-bottom: 0px;
				@include font-size(16px);
				color: #0080C8;
			}
			strong {
				color: #0080C8;
			}

			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-left: 10px;
			padding-bottom: 10px;

		.b-statements__quote {
			position: relative;
			@include webfont-icon($webfont-icon--quote);
				&:before {
					@include breakpoint(sm) {
						top: -46px;
					}
					position: absolute;
					right: 0;
					top: -50px;
					font-size: 41px;
					color: #0080C8;
				}
			}
		}
	}
}

.statements-header {
	height: 50px;
	width: 150%;
	background-color: #B1D1EF;
	padding: 6px;
}

@media (min-width: 799px) and (max-width: 1142px) {
	.b-statements__wrapper {
		flex-direction: column;
		&--reverse {
			flex-direction: column-reverse;
			margin-bottom: 20px;
		}

		.b-statements__image {
			width: 700px;
			margin-bottom: 20px;
			&--chairman {
				margin-bottom: 0px;
			}
		}

		.b-statements__text {

		}
	}
}

.browser-ie {
	.b-statements__image {
		flex-grow: 0;
		flex-shrink:0;
		flex-basis: 30%;
		width: 1800px;

	}

	.b-statements__text {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;
	}
}
