.lead {
	background-color: $lead-bg;
	position: relative;
	color: $lead-text;
	margin: 0 0 64px 16px;
	padding: 42px 40px 24px 24px;

	&:before {
		content: '';
		border: 1px solid $lead-border;
		border-top-width: 3px;
		position: absolute;
		top: 17px;
		left: -16px;
		width: 100%;
		height: 100%;
		z-index: 0;
	}
}