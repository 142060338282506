/* slider */
@keyframes hide {
    0% {
        opacity: 1;
        height: 30px;
    }
    80% {
        opacity: 0;
        height: 30px;
    }
    99% {
        height: 30px;
        opacity: 0;
    }
    100% {
        height: 0;
    }
}
@media all {
    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
        background: #7AB9E5;
    }

    .ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
        color: #1c94c4;
    }

    .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
        background: #7AB9E5;
    }

    .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
        background: #5A99C5;
    }

    .ui-widget :active {
        outline: none;
    }

    .ui-slider {
        position: relative;
        text-align: left;
    }

    .ui-slider .ui-slider-handle {
        position: absolute;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        cursor: default;
        transition: left .2s;
        z-index: 5;
        outline: none;
        -webkit-appearance: none;
    }

    // .ui-slider .ui-slider-handle:before {
    //     content: '';
    //     position: absolute;
    //     top: -12px;
    //     right: -5px;
    //     left: -5px;
    //     bottom: -4px;
    //     background: url('../img/arrow.svg') center center no-repeat;
    // }

    // .ui-slider .ui-slider-handle:after {
    //     content: '';
    //     position: absolute;
    //     width: 32px;
    //     height: 30px;
    //     top: 100%;
    //     margin: 15px 0 0 -16px;
    //     left: 50%;
    //     background: url('../img/hand.svg') center center no-repeat;
    // }

    .ui-slider-handle._moved:after {
        animation: hide .4s linear 0s forwards;
    }

    .ui-slider .ui-slider-range {
        position: absolute;
        z-index: 1;
        font-size: .7em;
        display: block;
    }

    .ui-slider-horizontal {
        height: 36px;
        position: relative;
    }

    .ui-slider-horizontal:before {
        content: '';
        position: absolute;
        top: 50%;
        height: 2px;
        margin-top: -1px;
        right: 0;
        left: 0;
        background: #b4b7bb;
    }

    .ui-slider-horizontal .ui-slider-handle {
        top: 0;
        margin-left: -18px;
    }

    .ui-slider-horizontal .ui-slider-range {
        top: 0;
        height: 100%;
    }

    .ui-slider-horizontal .ui-slider-range-min {
        left: 0;
    }

    .ui-slider-horizontal .ui-slider-range-max {
        right: 0;
    }

    .ui-slider-range-min {
        position: relative;
        transition: width .2s;
        z-index: 1;
    }

    .ui-slider-dots {
        position: absolute;
        height: 4px;
        top: 50%;
        left: 0;
        right: 0;
        margin: -2px -2px 0 -2px;
        display: flex;
        justify-content: space-between;
        z-index: 2;
    }

    .ui-slider-dots i {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: relative;
    }

    .ui-slider-dots i:before {
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        cursor: pointer;
        border-radius: 50%;
    }

    .ui-slider-dots i:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #133a60;
        margin: -2px 0 0 -2px;
        transition: transform .2s;
        cursor: pointer;
    }

    .ui-slider-dots i:hover:after {
        transform: scale(3);
    }

    .ui-slider-range-min:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        height: 2px;
        background: #7AB9E5;
        margin-top: -1px;
    }
}

.nn__slider {
    margin-bottom: 50px;
}

.nn__map {
    position: relative;
    margin-bottom: 50px;
}

.nn__map:before {
    content: '';
    float: left;
    width: 0;
    padding-top: 80.765%;
}

.nn__map:after {
    content: '';
    clear: both;
    display: block;
}

// .nn__image {
//     position: absolute;
//     z-index: 0;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }

.nn__func {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

[id*="nnMap_"] {
    display: none;
}

.nn__func a [fill="#00589C"],
.nn__func a [style*="#00589C"] {
    transition: fill .2s;
}

.nn__func a:hover [fill="#00589C"],
.nn__func a:hover [style*="#00589C"] {
    fill: #fbb040 !important;
}

[data-tooltip] {
    cursor: help;
}

.nn__tooltip {
    position: absolute;
    background: #ffffff;
    display: none;
    padding: 20px;
    max-width: 200px;
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.24)) drop-shadow(0 0 4px rgba(0, 0, 0, 0.12));
    border-radius: 2px;
    z-index: 1;
    margin-top: 30px;
    transform: translateX(-50%);
    margin-left: 8px;
}
.nn__tooltip:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    border: 10px solid transparent;
    border-top-width: 0;
    border-bottom-color: #fff;
    margin-left: -10px;
}