$zoom--overlay-bg-color: #212121;
$zoom--box-bg-color: $color-white;

$zoom--btn-color: $color-ironside-gray;
$zoom--btn-color-hover: $color-black;
$zoom--btn-color-active: $color-carrot-orange;

$zoom--close-btn-color: $zoom--btn-color;
$zoom--close-btn-color-hover: $zoom--btn-color-hover;
$zoom--close-btn-color-active: $zoom--btn-color-active;

.button--zoom {
	display: inline-block;
	background-color: transparent;
	@include webfont-icon($webfont-icon--zoom-in);
	font-size: 0;
	color: $zoom--btn-color;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

	&:before {
		font-size: 20px;
	}

	&:hover,
	&:focus {
		color: $zoom--btn-color-hover;
	}

	&:active {
		color: $zoom--btn-color-active;
	}
}

#cboxClose {
	position: absolute;
	display: block;
	top: 25px;
	right: 30px;
	width: 15px;
	height: 15px;
	background: none;
	text-indent: 0;
	@include webfont-icon($webfont-icon--close_2);
	font-size: 0;
	transition: background-color 0.2s ease, transform 0.2s ease;
	color: $zoom--close-btn-color;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

	&:before {
		font-size: 15px;
	}

	&:hover,
	&:focus {
		color: $zoom--close-btn-color-hover;
	}

	&:active {
		color: $zoom--close-btn-color-active;
	}
}

#cboxOverlay {
	background: $zoom--overlay-bg-color;
	opacity: 0.6;
	filter: alpha(opacity = 60);
}

#cboxContent {
	margin-top: 0;
}

#cboxLoadedContent {
	border: none;
	background-color: $zoom--box-bg-color;
	padding: 20px 30px 26px 20px;

	figcaption {
		text-align: left;
		font-size: 20px;
	}

	.caption__units,
	.b-caption__units {
		font-size: 20px;
	}

	.graphic {
		text-align: center;
	}

	.b-table {
		figcaption {
			padding-right: 70px;
		}

		.figure-buttons {
			position: absolute;
			right: 65px;
			margin-top: 0;
			bottom: 4px;
		}
	}
}

@include breakpoint(sm) {
	#cboxLoadedContent {
		.b-table .figure-buttons {
			display: none;
		}
	}
}
