.b-pagenav--buttons {
	display: flex;
	height: auto;
	position: fixed;
	bottom: 0;
	right: 0;
	width: calc(80% - 15px);
	z-index: 1;
	background-color: #F9FBFE;
	justify-content: space-between;
	padding: 0 65px;
	max-width: 1519px;

	&.is-fixed {
		position: absolute;
		width: 100%;
		bottom: 77px;
		right: 0;
	}

	.b-pagenav {

		&__wrapper {
			width: calc(100%/2 - 30px);
		}

		&__link {
			position: relative;
			display: flex;
			// @include rem(width, $pagenav--link-width);
			width: 30px;
			height: 34px;
			transition: background-color 0.2s ease;
			text-decoration: none;
			flex-direction: column;
			justify-content: center;

			&--prev {
				&:before {
					position: absolute;
					top: 9px;
					left: 9px;
					content: '';
					width: 15px;
					height: 16px;
					@include svg(100%, 100%, "/images/icons/left.svg");
				}

				&:hover,
				&:focus,
				&.is-touch {
					&:before {
						@include svg(100%, 100%, "/images/icons/left-hover.svg");
					}
				}

				&:active {
					&--prev {
						&:before {
							@include svg(100%, 100%, "/images/icons/left-active.svg");
						}
					}
				}
			}

			&--next {
				align-items: flex-end;
				&:before {
					position: absolute;
					top: 9px;
					right: 9px;
					content: '';
					width: 15px;
					height: 16px;
					@include svg(100%, 100%, "/images/icons/right.svg");
				}

				&:hover,
				&:focus,
				&.is-touch {
					&:before {
						@include svg(100%, 100%, "/images/icons/right-hover.svg");
					}
				}

				&:active {
					&--prev {
						&:before {
							@include svg(100%, 100%, "/images/icons/right-active.svg");
						}
					}
				}
			}


			&:hover,
			&:focus,
			&.is-touch {
				background-color: transparent;
				color: $pagenav--icon-color-hover;

				.b-pagenav__title {
					right: 0;
					opacity: 1;
				}
			}

			&:active {
				color: $pagenav--icon-color-active;
				background-color: transparent;
			}
		}

		&__scrollup {
			width: 30px;
		}

		&__link--hide-fixed {
			opacity: 0;
		}

		&__link--hide {
			display: none;
		}

		&__link--disabled,
		&__scrollup--disabled {
			pointer-events: none;
			cursor: default;



			&:hover,
			&:focus {
				background-color: $pagenav--btn-bg;

				.b-pagenav__title {
					left: -9999px;
					opacity: 0;
				}
			}
		}



		&__scrollup {
			@extend .b-pagenav__link;
			cursor: pointer;
			position: relative;
			background-color: transparent;
			&:before {
				position: absolute;
				top: 9px;
				left: 9px;
				content: '';
				width: 15px;
				height: 16px;
				@include svg(100%, 100%, "/images/icons/up.svg");
			}

			&:hover,
			&:focus,
			&.is-touch {
				background-color: transparent;
				&:before {
					@include svg(100%, 100%, "/images/icons/up-hover.svg");
				}
			}

			&:active {
				background-color: transparent;
				&:before {
					@include svg(100%, 100%, "/images/icons/up-active.svg");
				}

				.b-pagenav__title {
					color: $jordy-blue;
				}
			}
		}

		&__title {
			display: block;
			// position: absolute;
			right: 9999px;
			top: 7px;
			// min-width: 160px;
			opacity: 0;
			transition: opacity 0.2s ease;
			z-index: 10;
			font-size: 14px;
			color: $navy-blue;
			white-space: nowrap;
    		pointer-events: none;

			.b-pagenav-help {
				display: none;
			}

			&--prev {
				padding-left: 30px;
			}

			&--next {
				padding-right: 30px;
			}
		}
	}
}

.b-pagenav__link--disabled.b-pagenav__link--prev {
	&:before {
		@include svg(100%, 100%, "/images/icons/left-disable.svg");
	}
}

.b-pagenav__link--disabled.b-pagenav__link--next {
	&:before {
		@include svg(100%, 100%, "/images/icons/right-disable.svg");
	}
}

.b-pagenav--buttons .b-pagenav__scrollup--disabled {
	&:before {
		@include svg(100%, 100%, "/images/icons/up-disable.svg");
	}
}

@media (max-width: 1399px) {
// @include breakpoint(md) {
	.b-pagenav--buttons {
		width: calc(94% + 12px);
		padding: 0 18px;

		&.is-fixed {
			bottom: 59px;
		}
	}
}

@include breakpoint(md) {
	.b-pagenav--buttons {
		width: calc(92% + 12px);
	}
}

@include breakpoint(sm) {
	.b-pagenav--buttons {
		width: 100%;
		padding: 0 11px;

		&.is-fixed {
			bottom: 122px;
		}

		.b-pagenav {
			&__title {
				display: none;
			}
		}
	}
}

@media (min-width: 1921px) {
	.b-pagenav--buttons:not(.is-fixed) {
		left: 50%;
		transform: translateX(-37%);
	}
}
