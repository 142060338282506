// Длительность анимаций
$link-animation-duration: 0.35s;
// Цвет обводки
$link--border-color: $color-black;
// Цвет обводки по наведению
$link--border-color-hover: $color-dark-gray;
// Цвет фона ссылки
$link--background-color: $color-carrot-orange;
// Цвет текста ссылки
$link--text-color: $color-mountain-mist;
// Цвет текста ссылки при наведении курсора
$link--text-color-hover: $color-black;

@if ($contrast) {
	$link--border-color: $color-white;
	$link--border-color-hover: $color-black;
	$link--background-color: $color-carrot-orange;
	$link--text-color: $color-white;
	$link--text-color-hover: $color-carrot-orange;
}

.link-animate {
	position: relative;
	display: inline-block;
	@include rem(margin, 15px 25px);
	outline: none;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 400;
	text-shadow: 0 0 1px rgba(255,255,255,0.3);
	@include font-size(14px);

	&:hover,
	&:focus {
		outline: none;
	}
}

/* Effect 1: Brackets */
.link-animate--fade-brackets {
	color: $link--text-color;

	&:before,
	&:after {
		display: inline-block;
		opacity: 0;
		transition: transform $link-animation-duration, opacity $link-animation-duration;
	}

	&:before {
		margin-right: 10px;
		content: '[';
		transform: translateX(20px);
	}

	&:after {
		margin-left: 10px;
		content: ']';
		transform: translateX(-20px);
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;

		&:before,
		&:after {
			opacity: 1;
			transform: translateX(0px);
		}
	}
}

/* Effect 2: 3D rolling links, idea from http://hakim.se/thoughts/rolling-links */

.link-animate--rolling {
	line-height: 44px;
	perspective: 1000px;

	span {
		position: relative;
		display: inline-block;
		padding: 0 14px;
		background: $link--background-color;
		transition: transform $link-animation-duration;
		transform-origin: 50% 0;
		transform-style: preserve-3d;
		color: $color-white;

		&:before {
			position: absolute;
			text-align: center;
			top: 100%;
			left: 0;
			width: 100%;
			height: 100%;
			background: $link--background-color;
			content: attr(data-hover);
			transition: background $link-animation-duration;
			transform: rotateX(-90deg);
			transform-origin: 50% 0;
		}
	}

	&:hover,
	&:focus {
		span {
			transform: rotateX(90deg) translateY(-22px);

			&:before {
				background: $link--background-color;
			}
		}
	}
}

/* Effect 3: bottom line slides/fades in */

.link-animate--fade-in-underline {
	padding: 8px 0;
	color: $link--text-color;

	&:after {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 4px;
		background: $link--border-color;
		content: '';
		opacity: 0;
		transition: opacity $link-animation-duration, transform $link-animation-duration;
		transform: translateY(10px);
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;
		&:after {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}

/* Effect 4: bottom border enlarge */

.link-animate--fade-out-underline {
	padding: 0 0 10px;
	color: $link--text-color;

	&:after {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 1px;
		background: $link--border-color;
		content: '';
		opacity: 0;
		transition: height $link-animation-duration, opacity $link-animation-duration, transform $link-animation-duration;
		transform: translateY(-10px);
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;
		&:after {
			height: 5px;
			opacity: 1;
			transform: translateY(0px);
		}
	}
}

/* Effect 5: same word slide in */

.link-animate--slide-in {
	overflow: hidden;
	padding: 0 4px;
	height: 1em;
	color: $link--text-color;

	span {
		position: relative;
		display: inline-block;
		transition: transform $link-animation-duration;

		&:before {
			position: absolute;
			top: 100%;
			content: attr(data-hover);
			font-weight: 700;
			transform: translate3d(0,0,0);
		}
	}

	&:hover,
	&:focus  {
		color: $link--text-color-hover;
		span {
			transform: translateY(-100%);
		}
	}
}

/* Effect 6: same word slide in and border bottom */

.link-animate--overline-to-bottom {
	margin: 0 10px;
	padding: 10px 20px;
	color: $link--text-color;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: $link--border-color;
		content: '';
		transition: top $link-animation-duration;
	}

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		width: 2px;
		height: 2px;
		background: $link--border-color;
		content: '';
		transition: height $link-animation-duration;
	}

	&:hover {
		color: $link--text-color-hover;
		&:before {
			top: 100%;
			opacity: 1;
		}

		&:after {
			height: 100%;
		}
	}
}

/* Effect 7: second border slides up */

.link-animate--underline-to-top {
	padding: 12px 10px 10px;
	color: $link--text-color;
	text-shadow: none;
	font-weight: 700;

	&:before,
	&:after {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 3px;
		background: $link--border-color;
		content: '';
		transition: transform $link-animation-duration;
		transform: scale(0.85);
	}

	&:after {
		opacity: 0;
		transition: top $link-animation-duration, opacity $link-animation-duration, transform $link-animation-duration;
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;
		&:after {
			top: 0%;
			opacity: 1;
			transform: scale(1);
		}

		&:before {
			transform: scale(1);
		}
	}
}


/* Effect 8: border slight translate */

.link-animate--border-translate {
	padding: 10px 20px;
	color: $link--text-color;

	&:before,
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 3px solid $link--border-color;
		content: '';
		transition: transform $link-animation-duration, opacity $link-animation-duration;
	}

	&:after {
		border-color: $link--border-color-hover;
		opacity: 0;
		transform: translateY(-7px) translateX(6px);
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;

		&:before {
			opacity: 0;
			transform: translateY(5px) translateX(-5px);
		}

		&:after {
			opacity: 1;
			transform: translateY(0px) translateX(0px);
		}
	}
}

/* Effect 9: second text and borders */

.link-animate--second-text {
	margin: 0 20px;
	padding: 18px 20px;
	color: $link--text-color;

	&:after,
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background: $link--border-color;
		content: '';
		opacity: 0.2;
		transition: opacity $link-animation-duration, height $link-animation-duration;
	}

	&:after {
		top: 100%;
		opacity: 0;
		transition: transform $link-animation-duration, opacity $link-animation-duration;
		transform: translateY(-10px);
	}

	span:first-child {
		z-index: 2;
		display: block;
		font-weight: 300;
	}

	span:last-child {
		z-index: 1;
		display: block;
		padding: 8px 0 0 0;
		color: $link--text-color;
		text-shadow: none;
		text-transform: none;
		font-style: italic;
		font-size: 0.75em;
		font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
		opacity: 0;
		transition: transform $link-animation-duration, opacity $link-animation-duration;
		transform: translateY(-100%);
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;

		&:before {
			height: 6px;
		}

		&:before,
		&:after {
			opacity: 1;
			transform: translateY(0px);
		}

		span:last-child {
			opacity: 1;
			transform: translateY(0%);
		}
	}
}

/* Effect 10: reveal, push out */

.link-animate--push-out {
	overflow: hidden;
	margin: 0 15px;
	color: $link--text-color;

	span {
		display: block;
		padding: 10px 20px;
		background: $link--background-color;
		transition: transform $link-animation-duration;
		color: $color-white;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		padding: 10px 20px;
		width: 100%;
		height: 100%;
		background: $color-white;
		color: $color-black;
		content: attr(data-hover);
		transition: transform $link-animation-duration;
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;

		span {
			transform: translateX(100%);
		}

		&:before {
			transform: translateX(0%);
		}
	}
}

/* Effect 11: text fill based on Lea Verou's animation http://dabblet.com/gist/6046779 */

.link-animate--text-fill {
	padding: 10px 0;
	border-top: 2px solid $link--border-color;
	color: $link--text-color;
	text-shadow: none;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		padding: 10px 0;
		max-width: 0;
		border-bottom: 2px solid $color-navy-blue;
		color: $color-navy-blue;
		content: attr(data-hover);
		transition: max-width 0.5s;
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;

		&:before {
			max-width: 100%;
		}
	}
}

/* Effect 12: circle */

.link-animate--circle {
	color: $link--text-color;

	&:before,
	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100px;
		height: 100px;
		border: 2px solid rgba(0,0,0,0.1);
		border-radius: 50%;
		content: '';
		opacity: 0;
		transition: transform $link-animation-duration, opacity $link-animation-duration;
		transform: translateX(-50%) translateY(-50%) scale(0.2);
	}

	&:after {
		width: 90px;
		height: 90px;
		border-width: 6px;
		transform: translateX(-50%) translateY(-50%) scale(0.8);
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;

		&:before,
		&:after {
			opacity: 1;
			transform: translateX(-50%) translateY(-50%) scale(1);
		}
	}
}

/* Effect 13: three circles */

.link-animate--underline-points {
	transition: color $link-animation-duration;
	color: $link--text-color;

	&:before {
		position: absolute;
		top: 100%;
		left: 50%;
		color: transparent;
		content: '•';
		text-shadow: 0 0 transparent;
		font-size: 1.2em;
		transition: text-shadow $link-animation-duration, color $link-animation-duration;
		transform: translateX(-50%);
		pointer-events: none;
	}

	&:hover,
	&:focus {
		color: $color-carrot-orange;

		&:before {
			color: $link--border-color;
			text-shadow: 10px 0 black, -10px 0 black;
		}
	}
}

/* Effect 14: border switch */

.link-animate--border-switch {
	padding: 0 20px;
	height: 45px;
	line-height: 45px;
	color: $link--text-color;

	&:after,
	&:before {
		position: absolute;
		width: 45px;
		height: 2px;
		background: $link--border-color;
		content: '';
		opacity: 0.2;
		transition: all $link-animation-duration;
		pointer-events: none;
	}

	&:before {
		top: 0;
		left: 0;
		transform: rotate(90deg);
		transform-origin: 0 0;
	}

	&:after {
		right: 0;
		bottom: 0;
		transform: rotate(90deg);
		transform-origin: 100% 0;
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;

		&:before,
		&:after {
			opacity: 1;
		}

		&:before {
			left: 50%;
			transform: rotate(0deg) translateX(-50%);
		}

		&:after {
			right: 50%;
			transform: rotate(0deg) translateX(50%);
		}
	}
}

/* Effect 15: scale down, reveal */

.link-animate--scale-down {
	color: rgba(0,0,0,0.2);
	font-weight: 700;
	text-shadow: none;

	&:before {
		color: $color-white;
		content: attr(data-hover);
		position: absolute;
		transition: transform $link-animation-duration, opacity $link-animation-duration;
	}

	&:hover,
	&:focus {
		&:before {
			transform: scale(0.9);
			opacity: 0;
		}
	}
}

/* Effect 16: fall down */

.link-animate--fall-down {
	color: $link--text-color;
	text-shadow: 0 0 1px rgba(111,134,134,0.3);

	&:before {
		color: black;
		content: attr(data-hover);
		position: absolute;
		opacity: 0;
		text-shadow: 0 0 1px rgba(255,255,255,0.3);
		transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
		transition: transform $link-animation-duration, opacity $link-animation-duration;
		pointer-events: none;
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;

		&:before {
			transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
			opacity: 1;
		}
	}
}

/* Effect 17: move up fade out, push border */

.link-animate--move-up {
	color: $color-navy-blue;
	text-shadow: none;
	padding: 10px 0;

	&:before {
		color: $link--text-color;
		text-shadow: 0 0 1px rgba(255,255,255,0.3);
		content: attr(data-hover);
		position: absolute;
		transition: transform $link-animation-duration, opacity $link-animation-duration;
		pointer-events: none;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background: $link--text-color;
		opacity: 0;
		transform: translateY(5px);
		transition: transform $link-animation-duration, opacity $link-animation-duration;
		pointer-events: none;
	}

	&:hover,
	&:focus {
		&:before {
			opacity: 0;
			transform: translateY(-2px);
		}

		&:after {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}

/* Effect 18: cross */

.link-animate--cross {
	padding: 0 5px;
	color: $link--text-color;
	font-weight: 700;
	transition: color $link-animation-duration;

	&:before,
	&:after {
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		height: 2px;
		margin-top: -1px;
		background: $color-carrot-orange;
		content: '';
		z-index: -1;
		transition: transform $link-animation-duration, opacity $link-animation-duration;
		pointer-events: none;
	}

	&:before {
		transform: translateY(-20px);
	}

	&:after {
		transform: translateY(20px);
	}

	&:hover,
	&:focus {
		color: $color-white;

		&:before,
		&:after {
			opacity: 0.7;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

/* Effect 19: 3D side */

.link-animate--rotate-horizontal {
	line-height: 2em;
	margin: 15px;
	perspective: 800px;
	width: 200px;
	text-align: center;

	span {
		position: relative;
		display: inline-block;
		width: 100%;
		padding: 0 14px;
		background: $link--background-color;
		transition: transform $link-animation-duration, background $link-animation-duration;
		transform-style: preserve-3d;
		transform-origin: 50% 50% -100px;
		color: $color-white;

		&:before {
			position: absolute;
			top: 0;
			left: 100%;
			width: 100%;
			height: 100%;
			background: $link--background-color;
			content: attr(data-hover);
			transition: background $link-animation-duration;
			transform: rotateY(90deg);
			transform-origin: 0 50%;
			pointer-events: none;
		}
	}

	&:hover,
	&:focus {
		span {
			background: $link--background-color;
			transform: rotateY(-90deg);

			&:before {
				background: $link--background-color;
			}
		}
	}
}

/* Effect 20: 3D side */

.link-animate--paging {
	line-height: 2em;
	perspective: 800px;

	span {
		text-align: center;
		position: relative;
		display: inline-block;
		padding: 3px 15px 0;
		background: $link--background-color;
		box-shadow: inset 0 3px #2f4351;
		transition: background 0.6s;
		transform-origin: 50% 0;
		transform-style: preserve-3d;
		transform-origin: 0% 50%;
		color: $color-white;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $link--text-color;
			color: #2f4351;
			content: attr(data-hover);
			transform: rotateX(270deg);
			transition: transform 0.6s;
			transform-origin: 0 0;
			pointer-events: none;
		}
	}

	&:hover,
	&:focus {
		span {
			background: $link--background-color;

			&:before {
				transform: rotateX(10deg);
			}
		}
	}
}

/* Effect 21: borders slight translate */

.link-animate--borders-contraction {
	padding: 10px;
	font-weight: 700;
	text-shadow: none;
	transition: color $link-animation-duration;
	color: $link--text-color;

	&:before,
	&:after {
		position: absolute;
		left: 0;
		width: 100%;
		height: 2px;
		background: $link--border-color;
		content: '';
		opacity: 0;
		transition: opacity $link-animation-duration, transform $link-animation-duration;
		transform: translateY(-10px);
	}

	&:before {
		top: 0;
		transform: translateY(-10px);
	}

	&:after {
		bottom: 0;
		transform: translateY(10px);
	}

	&:hover,
	&:focus {
		color: $link--text-color-hover;;

		&:before,
		&:after {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}

