.graphic {
	position: relative;
	margin-bottom: 37px;
	&__img {
		@extend %collapse--top;
	}

	figcaption {
		@include font-size(20px);
		@include font(700);
		color: $graphic-title;
		margin-bottom: $grid-gutter-width;
		margin-bottom: 33px;

		.caption__units {
			@include font(400, 'Roboto');
			font-style: italic;
			color: $graphic-caption;
		}
	}

	&__info {
		font-size: 14px;
		color: $color-black;
		@include font(300);
		display: inline-block;

		span {
			@include font(500);
		}
	}
}


.graphic__tools {
	@extend %clearfix;
	@extend %collapse--top;
	position: relative;
	border-top: 1px solid;

	.button + .button {
		margin-right: 1px;
	}
}
