.b-indicator {
	display: block;
	background-color: $color-white;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
	border-radius: 3px;
	padding: 26px 20px;
	margin-bottom: 64px;

	&--note {
		margin-bottom: 14px;
	}

	&--raiting {
		padding-top: 20px;
	}

	&--line {
		border-top: 2px solid #465D7C;
	}

	&--noflex & {
		&__main {
			display: block;
		}
	}
	// custom
	&--no-plate {
		background-color: transparent;
		box-shadow: none;
	}

	&__main {
		display: flex;
		align-items: flex-end;
		margin-bottom: 5px;
	}

	&__value {
		display: inline-block;
		@include font(700, 'Roboto');
		font-size: 50px;
		line-height: 1;
		color: $key-indicator-value;
		margin-right: 5px;
	}

	&__value--big {
		@include font-size(45px);
	}

	&__value--small {
		@include font-size(39px);
	}

	&__value--arrow {
		@include webfont-icon($webfont-icon--up);

		&:before {
			vertical-align: top;
			font-size: 0.5em;
		}
	}

	&__value--arrow-up {}

	&__value--arrow-down {
		&:before {
			transform: rotate(180deg);
		}
	}

	&__value--print {
		display: none !important;
	}

	&__unit {
		display: inline-block;
		@include font(700, 'Roboto');
		font-size: 20px;
		line-height: 1;
		color: $key-indicator-value;
		margin-bottom: 7px;
	}

	&__unit--big {
		@include font-size(30px);
	}

	&__unit--small {
		@include font-size(18px);
		display: block;
		// font-weight: 400;
	}

	&__text {
		display: block;
		font-size: 16px;
		@include font(500, 'Roboto');
		line-height: 23px;
		color: $key-indicator-text;

		&--blue {
			font-size: 16px;
			line-height: 23px;
			color: $key-indicator-value;
			margin-bottom: 21px;
		}

		&--heading {
			margin-bottom: 10px;
		}
	}

	&__text--big {
		@include font-size(18px);
		font-weight: 700;
	}

	&__text--small {
		@include font-size(12px);
	}

	&__value,
	&__unit,
	&__text {
		& + & {
			@extend %collapse--top;
		}
	}

	&__heading {
		font-size: 18px;
		line-height: 25px;
		color: $key-indicator-text;
		@include font(700, 'Roboto');
		position: relative;
		margin-bottom: 10px;

		.note__marker {
			position: relative;
			top: -8px;
			right: -5px;
			left: auto;
			display: inline-flex;
		}
	}

	&__list-title {
		@include font(300, 'Roboto');
		color: $key-indicator-text;
		font-size: 16px;
		line-height: 23px;
		margin-bottom: 10px;
		border-top: 1px solid $key-indicator-border;
		margin-top: 10px;
		padding-top: 13px;
	}

	ul {
		margin: 0 !important;
	}

	li {
		color: $key-indicator-text;
		font-size: 16px;
		line-height: 23px;
		@include font(500, 'Roboto');
		padding: 0 !important;
		margin: 0 !important;

		&::before {
			display: none;
		}

		span {
			@include font(300, 'Roboto');
		}
	}

	&__rating {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #19365C;
		color: #1887C9;
		@include font(700, 'Roboto');
		width: 100%;
		padding-bottom: 0;
		line-height: 16px;
		align-items: flex-end;
	}

	&__name {
		font-size: 18px;
		width: 97px;
	}

	&__raiting-number {
		font-size: 35px;
		margin-right: 5px;
		line-height: 31px;
	}

	&__forecast {
		font-size: 14px;
		color: $color-white;
		background-color: #19365C;
		min-width: 160px;
		display: inline-block;
		text-align: center;
		@include font(400, 'Roboto');

		span {
			font-weight: 400 !important;
		}
	}

	li.b-indicator__item {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		margin-bottom: 10px !important;
		// justify-content: flex-end;
	}
}
