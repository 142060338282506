$button-size: 30px;
$analysis-bg-color: $hawkes-blue;
$analysis-btn-bg-hover: #444444;
$analysis-icon-color: #33ffff;
$analysis-icon-color-hover: $color-white;
$analysis-button-select: $hawkes-blue;
$analysis-button-text: $storm-dust;
$analysis-button-text-select: $regal-blue;
$analysis-accordion-item: $storm-dust;
$analysis-accordion-item-hover: $regal-blue;
$analysis-accordion-item-active-bg: $solitude;
$analysis-accordion-item-border: $mischka;

.l-analysis__content {
	max-width: 960px;
}

.analysis__presets-list {
	display: inline-block;
	margin-top: 12px;
}

.analysis__preset {
	padding: 5px 6px;
	margin: 0px 1px 0 1px;
	background-color: $analysis-button-select;
	color: $analysis-button-text-select;
	font-size: 14px;
	line-height: 22px;

	&:hover {
		color: $analysis-button-text-select;
	}

	&:not(.is-selected) {
		background-color: transparent;
		color: $analysis-button-text;
		&:hover {
			color: $analysis-button-text-select;
		}
	}
}

.analysis__main {
	display: table;
	table-layout: fixed;
	width: 100%;
}

.analysis__chart-section {
	display: table-cell;
	vertical-align: top;
	padding-left: 63px;
	transform: translateY(-36px);
}

.analysis__chart-section,
.analysis__items-list {
	float: none !important;
}

.analysis__items-list {
	width: 256px;
	display: table-cell;
	vertical-align: top;
	border-top: 2px solid $mischka;
	padding-top: 2px;

	.items-group__title {
		@include font-size(10px);
	}

	.items-group__item {
		text-indent: 0;
		font-size: 16px;
		color: $analysis-accordion-item;
		border-bottom: 1px solid $analysis-accordion-item-border;
		padding: 7px 0 10px 11px;
		

		&.is-even:not(.is-selected):not(:hover) {
			// background-color: #ececec;
		}
		
		&:hover {
			color: $analysis-accordion-item-hover;
		}
		
		&.is-even {
			color: $analysis-accordion-item-hover;
			background-color: $analysis-accordion-item-active-bg;

			&:hover {
				background-color: $analysis-accordion-item-active-bg;

			}
		}
		
		&.subgroup {
			@include rem(padding-left, 30px);
		}
	}
	
	.b-accordion {

		&__section {
			margin-bottom: 0;
		}

		&__button {
			font-size: 16px;
			border: 0;
			border-bottom: 1px solid $mischka;
			position: relative;
			padding: 8px 32px 6px 12px;

			&::before {
				right: 14px;
				top: 4px;
			}

			&::after {
				top: 4px;
				right: 14px;
			}

			&.is-expand,
			&:active {
				border-top: 0;
				border-bottom: 2px solid $regal-blue;
			}
		}

		&__article {
			padding: 5px 0 0 20px;
		}
	}
	
	.b-accordion__article {
		background: transparent;
	}
}

.analysis__table {
	@include font-size(13px);

	th {
		background-color: rgba(230, 231, 232, 0.8);
		font-size: 12px;
		color: $regal-blue;
		@include font(700, 'Roboto');
		padding: 7px 9px 5px;
		border-bottom: 2px solid #133A60;
		vertical-align: bottom;
		position: relative;
		&::after {
			content: '';
			height: 2px;
			width: 100%;
			background-color: #133A60;
			position: absolute;
			bottom: -2px;
			left: 0;
		}

		&:nth-child(2) {
			width: 153px;
		}
	}

	td {
		padding: 11px 5px;

		&:nth-child(2) {
			width: 153px;
		}
	}
}

.analysis__years-list {
	color: $regal-blue;
	font-size: 12px;
	@include font(700, 'Roboto');
	position: relative;
	display: inline-block;
	margin-left: 9px;
	margin-top: 27px;

	span {
		margin-right: 52px;
		display: inline-block;
		vertical-align: bottom;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 5px;
		left: 90px;
		height: 1px;
		width: calc(100% - 60px);
		background-color: $regal-blue;
	}

	.years-list__year {
		padding: 1px 5px;
		@include font(700, 'Roboto');
		width: 37px;
		position: relative;
		margin: 0 10px 12px;

		&::before {
			content: '';
			position: absolute;
			bottom: -8px;
			left: 50%;
			margin: 0 0 0 1px;
			width: 3px;
			height: 3px;
			background-color: $color-white;
			z-index: 2;
			border-radius: 100%;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin: 0 0 0 -1px;
			border-radius: 100%;
			width: 7px;
			height: 7px;
			background-color: $regal-blue;
			z-index: 1;
		}

		&.is-selected {
			background-color: $analysis-bg-color;
		}

		&:hover {
			@include font(400, 'Roboto');
			color: $storm-dust;
			background-color: transparent;

			&::after {
				background-color: $storm-dust;
			}
		}
	}
}

.analysis__chart-area {
	// margin-top: 50px;
}

.analysis__buttons {
	// white-space: nowrap;
	display: inline-block;
	margin: 0;
	margin-top: -20px;
	$button-padding: 6px;
	$button-image-size: $button-size - $button-padding;

	.analysis__button {
		@include rem(width, $button-size);
		@include rem(height, $button-size);
		@include rem(padding, 1px);
		background-color: transparent;
		display: inline-block;
		color: $color-tapa-gray;

		&:hover {
			background-color: transparent;
			color: $color-gray;
		}

		&:before {
			@include font-size(25px);
		}
	}

	.analysis__button--line {
		min-width: 13px !important;
		width: 13px;
		height: 19px;
		@include svg(100%, 100%, "/images/icons/graphic.svg");

		&:hover {
			@include svg(100%, 100%, "/images/icons/graphic.svg", #0080C8);
		}

		&:hover {
			@include svg(100%, 100%, "/images/icons/graphic.svg", #7AB9E5);
		}
	}

	.analysis__button--histogram {
		min-width: 13px !important;
		width: 13px;
		height: 19px;
		@include webfont-icon($webfont-icon--histogram);

		&:before {
			color: #133A60;
			font-size: 14px;
		}

		&:hover {
			color: #0080C8;
		}

		&:active {
			color: #7AB9E5;
		}
	}

	.analysis__button--excel {
		width: 44px;
		height: 19px;
		@include svg(100%, 100%, "/images/icons/download_xls.svg");
		margin-left: 19px;

		&:hover {
			@include svg(100%, 100%, "/images/icons/download_xls.svg", #0080C8);
		}

		&:active {
			@include svg(100%, 100%, "/images/icons/download_xls.svg", #7AB9E5);
		}
	}
}

.item {
	&__row {
		color: $nero;
		font-size: 12px;
		line-height: 13px;
		@include font(300, 'Roboto');
		border-bottom: 1px solid #BCBEC0;

		&:hover {
			color: $cobalt;
		}

		.is-hidden {
			color: $storm-dust;
		}
	}

	&__tile {
		width: 11px !important;
		height: 11px !important;

		.is-hidden & {
			width: 7px !important;
			height: 7px !important;
		}
	}
}

.analysis__chart {
	margin-bottom: 14px;
}


.c3-tooltip td.name {
	max-width: 700px;
}

.tick {
	font-size: 12px;
	line-height: 13px;
	color: #00304B;
	@include font(300);
}

.c3-axis-y .tick {
	color: #004077;
}

.c3-axis-y-label {
	font-size: 12px;
	line-height: 13px;
	color: #133A60;
	@include font(300);
}

.c3-tooltip-container {
	border: 1px solid #BCBEC0;
	box-sizing: border-box;
	box-shadow: 0px 0px 7px rgba(35, 31, 32, 0.3);
	padding: 8px 12px;
	background-color: $color-white;
	max-width: 306px;
}

.c3-tooltip {
	box-shadow: none !important;
	tr {
		border: 0 !important;

		&:last-child {
			td {
				border-bottom: 0 !important;
			}
		}
	}

	th {
		color: $nero !important;
		font-size: 12px !important;
		@include font(700, 'Roboto');
		background-color: transparent !important;
		border-bottom: 2px solid #00304B;
		padding: 0px 0 1px !important;

		&:first-child {
			text-align: left;
		}

		&:last-child {
			text-align: right;
		}
	}

	td {
		border: 0 !important;
		border-bottom: 1px solid #BCBEC0 !important;
		font-size: 11px !important;
		line-height: 13px !important;
		color: $nero !important;
		padding: 3px 0 !important;
	}

	td>span {
		width: 11px !important;
		height: 11px !important;
	}
}

@include breakpoint(md) {
	.analysis__main {
		display: block;
	}
	
	.analysis__items-list {
		display: block;
		width: 100%;
	}
	
	.analysis__chart-section {
		display: block;
		width: 100%;
		padding: 0;
	}
}

@include breakpoint(xs) {
	.analysis__table {
		@include rem(font-size, 8px)
	}
	
	.analysis__years-list {
		@include rem(font-size, 12px);
	}
	
	.c3-tooltip-container {
		left: 50% !important;
		transform: translateX(-50%);
		width: 320px;
	}
}
