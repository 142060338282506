
.b-nav--left.b-nav--vertical-accordeon {
	overflow: hidden;
	height: 100%;

	.b-nav__list {
		.b-nav__list {
			display: none;
		}

		&.is-expand {
			display: block;
		}
	}

	.b-nav__link {
		position: relative;
		color: rgba(255, 255, 255, 0.5);
		font-size: 18px;
		@include font(700, 'Roboto');
		text-decoration: none;
		line-height: 1;
		display: block;
		padding: 16px 45px 16px 32px;

		span {
			line-height: 1.4;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			background-color: $color-white;
			width: 6px;
			height: 100%;
			opacity: 0;
			transform: translateX(-100%);
		}

		&:hover,
		&:focus {
			color: $color-white;

			.icon-left-nav {
				&:before {
					border-top-color: $color-white;

				}
			}

			&.is-expand .icon-left-nav {
				&:before {
					border-bottom-color: $color-white;
					border-top: 0;
				}
			}
		}

		&.current {
			color: $color-white;
			background-color: rgba(84, 164, 197, 0.15);

			&::after {
				opacity: 1;
				transform: translateX(0);
			}
		}

		&--lvl2,
		&--lvl3 {
			display: inline-block;
			@include font(400, 'Roboto');
			font-size: 16px;
			line-height: 17px;
			color: rgba(255, 255, 255, 0.7);
			padding: 20px 45px 16px 15px;

			&::after {
				display: none;
			}

			&.current {
				background-color: transparent;
			}
		}

		&--lvl3 {
			border-left: 1px solid rgba(255, 255, 255, 0.7);
			padding: 7px 45px 17px 15px;
		}
	}

	.has-submenu {
		.icon-left-nav {
			position: absolute;
			@include rem(width, 25px);
			top: 7px;
			bottom: 0;
			right: 29px;

			&:before {
				content: '';
				position: absolute;
				display: block;
				@include rem(width, 10px);
				@include rem(height, 5px);
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				transition: transform 0.2s ease;
				border: 5px solid transparent;
				border-top: 5px solid rgba(255, 255, 255, 0.8);

			}

			&:before {
				transform: rotate(0deg);
			}

			&:hover,
			&:focus {
				&:before,
				&:after {
					border-top-color: $color-white;
				}
			}
		}

		&.is-expand {
			.icon-left-nav {
				top: 0px;

				&:before {
					border-bottom: 5px solid rgba(255, 255, 255, 0.8);
					border-top: 0;
				}
			}
		}
	}

	.has-submenu.b-nav__link--lvl2 {
		.icon-left-nav {
			right: 17px;
		}
	}

	// .b-nav__item--lvl1 > .b-nav__link-wrapper {
	// 	@include rem(padding-left, 25px);
	// }

	.b-nav__item--lvl2 > .b-nav__link-wrapper {
		@include rem(padding-left, 45px);
	}

	.b-nav__item--lvl3 > .b-nav__link-wrapper {
		padding-left: 75px;
	}

	.b-nav__list--lvl1 {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.b-nav__list--lvl3 {
		margin: 14px 0px 0;
	}

	.b-nav__link-wrapper {
		padding: 0;
	}

	@media (max-width: 1399px) {
		.b-nav__list--lvl1 {
			padding-bottom: 100px;
		}
	}

	@include breakpoint(md) {
		.b-nav__link {
			font-size: 16px;

			&--lvl2,
			&--lvl3 {
				font-size: 14px;
			}
		}
	}
}
