$title--text-color: $regal-blue;
$title--link-color: $regal-blue;
$title--link-color--hover: $regal-blue;
$title--link-color--active: $regal-blue;
$title--link-color--visited: $regal-blue;

.b-report-title {
	@include font-size(20px);
	color: $color-white;

	&__link {
		@include font-size(20px);
		color: $title--link-color;
		text-decoration: none;

		&:visited {
			color: $title--link-color--visited;
			text-decoration: none;
		}

		&:hover,
		&:focus {
			color: $title--link-color--hover;
			text-decoration: none;
		}

		&:active {
			color: $title--link-color--active;
			text-decoration: none;
		}
	}

	@include breakpoint(sm) {
		font-size: 14px;
		color: $color-white;
		&__link {
			font-size: 14px;
			line-height: 24px;
			color: $color-white;

			&:visited {
				color: $color-white;
				text-decoration: none;
			}
	
			&:hover,
			&:focus {
				color: $color-white;
				text-decoration: none;
			}
	
			&:active {
				color: $color-white;
				text-decoration: none;
			}
		}
	}
}
