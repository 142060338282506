.b-history {
	display: none;

	&__date {
		font-size: 18px;
		line-height: 26px;
		color: $history-date;
		margin-bottom: 24px;
		@include font(400, 'Roboto');
	}

	ul.b-history__list {
		margin-bottom: 42px;
	}

	&__item {
		padding: 0 !important;
		display: flex;
		margin-bottom: 11px;
		&::before {
			display: none;
		}
	}

	&__link {
		display: block;
		max-width: 421px;
		&:hover {
			text-decoration: none;
		}
	}

	&__time {
		display: table-cell;
		@include font(300, 'Roboto');
		font-size: 18px;
		line-height: 26px;
		color: $history-text;
		padding-right: 37px;
	}

	&__btn {
		padding: 12px 20px;
		margin-bottom: 31px;
		letter-spacing: 0.0357143em;
	}
}

.l-history__mockup {
	margin-bottom: 4px;
}

.b-history-mockup {
	width: 100%;

	&__inner {
		display: inline-flex;
		max-width: 450px;
		align-items: center;
	}

	&__icon {
		@include rem(min-width, 70px);
		@include rem(margin-right, 10px);

		& > * {
			@include rem(font-size, 70px);
		}
	}

	&__content {}
	&__message {
		color: $history-text;
		@include font(300, 'Roboto');
		font-size: 18px;
		line-height: 26px;
		margin-bottom: 9px;
	}
	&__link {
		color: $history-link;
		font-size: 18px;
		line-height: 26px;
		text-decoration-line: underline;
	}
}

// .b-history-empty {
// 	&:not(.preloader) {
// 		@extend %clearfix;
// 	}
//
// 	&__message {
// 		margin: 0;
// 	}
//
// 	&__image {
// 		float: left;
// 		width: 61px;
// 		height: 85px;
// 	}
//
// 	&__content {
// 		float: left;
// 		@include rem(margin-top, 20px);
// 		@include rem(margin-left, 25px);
// 	}
//
// 	&__link {
// 		display: inline-block;
// 		@extend %disable-visited;
// 		@include rem(margin-top, 25px);
// 	}
// }
