$tabs--button-bg: $color-dark-gray;
$tabs--button-bg-active: $hawkes-blue;
// $tabs--button-bg-hover: $color-taupe-gray;
$tabs--button-text-color: $storm-dust;
$tabs--button-text-color-hover: $regal-blue;
$tabs--body-bg: $color-white;

.b-tabs {

	&__nav {
		border-bottom: 3px solid $regal-blue;
	}
	&__button {
		@extend %disable-visited;
		@extend %collapse--top;
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: center;
		align-items: center;
		padding: 14px 5px;
		position: relative;
		text-decoration: none;
		color: $tabs--button-text-color;
		text-align: center;
		height: 100%;
		@include font-size(18px);
		@include font(400, 'Roboto');

		transition: all .2s ease-in-out;

		&:visited {
			color: $tabs--button-text-color;
		}

		&:focus {
			color: $tabs--button-text-color;
			background-color: transparent;

			&:visited {
				color: $tabs--button-text-color;
				background-color: transparent;
			}
		}

		&:hover {
			color: $tabs--button-text-color-hover;
			background-color: transparent;

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: transparent;
			}
		}

		&.is-expand,
		&.is-expand:focus {
			color: $tabs--button-text-color-hover;
			background-color: $tabs--button-bg-active;

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-active;

			}
		}
	}

	&__nav-item {
		border-left: 1px solid $color-white;
		min-width: 195px;

		&:first-child {
			border-left: none;
		}
	}

	&__body {
		@extend %collapse--top;
		// background-color: $tabs--body-bg;
		width: 100%;
	}

	&__article {
		@extend %collapse--top;
		position: absolute;
		left: -99999px;
		top: -99999px;
		@include rem(padding, 26px 40px 16px 40px);

		p {
			@include font(300, 'Roboto');
			color: $nero;
		}
		

		&.is-expand {
			position: relative;
			left: 0;
			top: 0;
		}
	}
}
