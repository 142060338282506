.header-1 {
	@include font-size(36px);
	line-height: 51px;
	margin: 0 0 21px;
	text-transform: uppercase;
	color: $cobalt;
	@include font(700, 'Roboto');

	// responsive
	@include breakpoint(sm) {
		@include font-size(32px);
		@include rem(line-height, 36px);
	}

	@include breakpoint(xs) {
		@include font-size(28px);
		line-height: 100%;
	}
}

h1 {
	@extend .header-1;
}

.header-2 {
	@include font-size(28px);
	line-height: 39px;
	color: $regal-blue;
	margin: 0 0 33px;
	@include font(700, 'Roboto');

	@if ($responsive) {
		@include breakpoint(sm) {
			line-height: 1;
		}
	}
}

h2 {
	@extend .header-2;
}

.header-3 {
	@include font-size(22px);
	line-height: 31px;
	color: $navy-blue;
	margin: 0 0 25px;
	text-transform: uppercase;
	@include font(700, 'Roboto');
}

h3 {
	@extend .header-3;
}

.header-4 {
	@include font-size(22px);
	line-height: 31px;
	color: $regal-blue;
	margin: 0 0 20px;
	@include font(700, 'Roboto');
}

h4 {
	@extend .header-4;
}

.header-5 {
	@include font(400, 'Roboto');
	@include font-size(18px);
	line-height: 25px;
	color: $regal-blue;
	// background-color: $regal-blue;
	margin: 0 0 15px;
	// margin-left: -55px;
	// padding-left: 55px;
}

h5 {
	@extend .header-5;
}

.header-6 {
	@include font-size(18px);
	line-height: 25px;
	@include font(500, 'Roboto');
	margin: 0 0 15px;
	color: $color-black;
}

h6 {
	@extend .header-6;
}

.header-icon {
	display: flex;
	align-items: center;
	margin-bottom: 0.83em;

	&__icon {
		margin-right: 12px;
	}

	&__title {
		margin: 0;
	}
}

.title {

	&--page {
		background: $color-white;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
		border-radius: 2px;
		// display: inline-flex;
		// min-width: 134px;
		display: flex;
		max-width: 150px;
		padding: 9px 19px 9px 5px;
		align-items: center;
		margin: 13px 0 30px;
	}

	&__text {
		font-size: 20px;
		line-height: 28px;
		background-color: $regal-blue;
		color: $color-white;
		@include font(700, 'Roboto');
		padding: 6px 0 6px 72px;
		margin-left: -72px;

	}

	&__icon-text {
		position: relative;
		padding-left: 15px;
		margin-top: -7px;
		&::after {
			content: '';
			position: absolute;
			left: 5px;
			bottom: -8px;
			background-color: #CAD9F1;
			width: calc(100% + 3px);
			height: 6px;
		}

		&::before {
			content: '';
			border: 5px solid transparent;
			border-bottom: 5px solid #CAD9F1;
			position: absolute;
			left: 0;
			bottom: -8px;
			z-index: 1;
		}
	}

	&__icon {
		font-size: 18px;
		line-height: 25px;
		color: $regal-blue;
		@include font(700, 'Roboto');
		display: inline-flex;
		align-items: center;
		margin-bottom: 30px;
	}

	&__svg {
		display: inline-block;
		width: 60px;
		height: 55px;
		padding-bottom: 55px !important;
		margin-right: 11px;
	}

	&__text-page {
		font-size: 14px;
		line-height: 15px;
		color: $regal-blue;
		max-width: 72px;
		@include font(400, 'Roboto');
		text-transform: none;

		&--analysis {
			// max-width: 112px;
		}
		&--report {
			max-width: 65px;
		}
	}

	&__icon-page {
		width: 52px;
		height: 43px;
		padding: 0 !important;
	}

	&--sitemap {
		padding-left: 14px;
		// margin: 13px 0 25px;
	}

	&--sitemap & {
		&__text-page {
			margin-left: 11px;
		}

		&__icon-page {
			width: 48px;
		}
	}

	&--download {
		padding: 9px 0px 9px 5px;
	}

	&--download & {
		&__icon-page {
			width: 61px;
    		height: 44px;
		}
	}
}
