.b-logo {
	display: block;
	width: 47px;

	&__image {
		width: 100%;
		height: 100%;
	}
}

.logo-svg {
	width: 100%;
	height: auto;
}
