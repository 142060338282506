.browser-ie-11 {
	@keyframes opacity {
		from {opacity: 0.99;}
		to {opacity: 1;}
	}

	svg {
		animation: opacity 2000s infinite;
	}

	#analysis__chart svg {
		animation: none;
	}
}



.button--image {
	display: none;
	@extend %collapse--top;
	background-color: transparent;
	@include webfont-icon($webfont-icon--camera);
	color: $base-text-color;
	font-size: 0;

	&:before {
		@include font-size(18px);
	}

	&:hover,
	&:focus {
		color: $color-taupe-gray;
		background-color: transparent;
	}

	&:active {
		color: $color-carrot-orange;
		background-color: transparent;
	}
}

@include breakpoint(sm) {
	.graphic[data-download-on-mobile],
	.chart[data-download-on-mobile] {
		[data-svg] {
			display: none;
		}

		.button--image {
			display: block;
		}

		.figure-buttons {
			margin-bottom: 10px;
		}
	}

	[data-svg][data-download-on-mobile] {
		padding: 0 !important;

		svg {
			display: none !important;
		}

		.button--image {
			display: block;
		}
	}
}
