$captcha_base--icon-color: $regal-blue;
$captcha_base--icon-color-hover: $regal-blue;
$captcha_base--icon-color-active: $regal-blue;
$captcha_base--border-color: #aaaaaa;

.b-captcha {
	&__reload-btn {
		background-color: transparent;
		color: $captcha_base--icon-color;
		padding: 0;
		margin-right: 15px;
		transition: transform 0.5s ease;
		@include webfont-icon($webfont-icon--reload);

		&:before {
			display: block;
			@include font-size(20px);
		}

		&:hover,
		&:focus {
			color: $captcha_base--icon-color-hover;
			transform: rotate(360deg);
		}

		&:active {
			color: $captcha_base--icon-color-active;
		}
	}

	&__data {
		display: flex;
		align-items: center;
		margin-top: 6px;
	}

	&__image {
		border: 1px solid $captcha_base--border-color;
	}
}
